// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { OrderType } from "./types";

export function useOrder(_id?: string) {
  async function getOrder(_id?: string): Promise<OrderType> {
    await timeout(500);
    const response = await api.get<OrderType>(`orders/order/${_id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Order", _id],
    queryFn: () => getOrder(_id),
    enabled: !!_id,
  });
}
