// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { OrderRegistrationType, OrderType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: OrderRegistrationType;
  notificationID: string | number;
}

export function useRegisterOrderMutation(
  onSuccessMutation?: (data: OrderType, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function registerOrder(params: paramsType): Promise<OrderType> {
    const response = await api.post<OrderType>("orders/register", params.data);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: registerOrder,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Orders"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
