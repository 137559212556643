import { ExtensionType } from "../../features/extensions/types";

interface Props {
  extension: ExtensionType;
  onSelect: (extension: ExtensionType) => void;
}

function ExtensionCard({ extension, onSelect }: Props) {
  return (
    <div
      className="flex flex-1 flex-row gap-x-2 py-2 px-4 hover:cursor-pointer hover:bg-red-50 transition-all"
      onClick={() => onSelect(extension)}
    >
      <div className="w-[75%] flex flex-col">
        <div className="flex flex-row items-center">
          <p className="text-[#EA002A] font-[700] mb-1   mr-2">
            {extension?.idExtension}
          </p>
          <p className="font-[700] mb-1 truncate flex-1">{extension?.name}</p>
        </div>
        <p className="mb-1 truncate text-[#717171]">
          Barcode: {extension?.barcode}
        </p>
      </div>
      <div className="flex flex-1 items-end flex-col justify-center">
        <i className="bi bi-chevron-right text-[#EA002A] text-lg"></i>
      </div>
    </div>
  );
}

export default ExtensionCard;
