// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ProductRegistrationType, ProductType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: ProductRegistrationType;
  notificationID: string | number;
}

export function useRegisterProductMutation(
  onSuccessMutation?: (data: ProductType, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function registerProduct(params: paramsType): Promise<ProductType> {
    const response = await api.post<ProductType>(
      "products/register",
      params.data
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: registerProduct,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Products"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
