// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import BackorderCard from "../cards/BackorderCard";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";

// Custom types imports
import { BackorderType } from "../../features/backorders/types";

interface Props {
  backorders: UseQueryResult<BackorderType[], unknown>;
  onSelect: (backorder: BackorderType) => void;
}

function BackordersList({ backorders, onSelect }: Props) {
  if (backorders.isLoading) {
    return <Loading text="Cargando backorders..." />;
  }

  if (backorders.isError || !backorders.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar backorders" />
        </div>
      </div>
    );
  }

  if (backorders.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay backorders" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de backorders</span>
        <div className="w-[30%] text-end">
          <b>{backorders.data.length}</b>
        </div>
      </div>
      <div className="divide-y">
        {backorders.data.map((backorder) => (
          <BackorderCard
            backorder={backorder}
            key={backorder._id}
            onSelect={(backorder) => onSelect(backorder)}
          />
        ))}
      </div>
    </div>
  );
}

export default BackordersList;
