//Custom components imports
import CreateSaleConditionForm from "./CreateSaleConditionForm";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom Hooks imports
import { SaleConditionRegistrationType } from "../../../features/saleConditions/types";

//Third helpers imports
import { toast } from "react-toastify";

// Custom hooks imports
import { useRegisterSaleConditionMutation } from "../../../features/saleConditions/useRegisterSaleConditionMutation";

interface Props {
  visible: boolean;
  onClose: () => void;
}

function DrawerCreateSaleCondition({ visible, onClose }: Props) {
  const methods = useForm<SaleConditionRegistrationType>({
    defaultValues: {
      name: "",
      idSaleCondition: "",
      levelCollection: undefined,
    },
  });

  const registerOperationCenterMutation = useRegisterSaleConditionMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Condición de venta creada.`,
        closeOnClick: true,
        closeButton: true,
      });
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salió mal al intentar crear condición de venta.",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const closeDrawer = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Creando condición de venta...");
    registerOperationCenterMutation.mutate({
      notificationID,
      data,
    });
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        visible ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="gap-x-4 w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="text-xl bi bi-arrow-left"></i>
              </button>{" "}
              <b className="font-medium text-base truncate">
                Crear Condición de venta
              </b>
              <button
                className="ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                type="submit"
              >
                Agregar
              </button>
            </div>
            <CreateSaleConditionForm />
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default DrawerCreateSaleCondition;
