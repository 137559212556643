// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { SaleConditionIsRemovableType } from "./types";

export function useSaleConditionIsRemovable(_id?: string) {
  async function getSaleConditionIsRemovable(
    _id?: string
  ): Promise<SaleConditionIsRemovableType> {
    await timeout(500);
    const response = await api.get<SaleConditionIsRemovableType>(
      `saleConditions/saleCondition/isRemovable/${_id}`
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["SaleConditionIsRemovable", _id],
    queryFn: () => getSaleConditionIsRemovable(_id),
    refetchOnWindowFocus: false,
    enabled: !!_id,
  });
}
