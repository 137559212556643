import { useState } from "react";
import CustomLabel from "./CustomLabel";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface Props {
  placeholder: string;
  id: string;
  required: boolean;
  label: string;
  name: string;
  validate?: any;
  classNameLabel?: string;
  classNameInput?: string;
}

function CustomPasswordField({
  placeholder,
  id,
  required,
  label,
  name,
  validate = {},
  classNameLabel,
  classNameInput,
}: Props) {
  const [showPassword, setPassword] = useState(false);
  const {
    register,
    formState: { errors, isSubmitted },
    getFieldState,
  } = useFormContext();
  return (
    <CustomLabel
      label={label}
      id={id}
      required={required}
      className={`w-full ${classNameLabel || ""}`}
    >
      <div className="relative w-full flex items-center">
        <input
          autoComplete="off"
          autoCorrect="off"
          type={showPassword ? "text" : "password"}
          className={`input-base ${
            getFieldState(name).error ? "input-error" : ""
          } ${!getFieldState(name).error && isSubmitted ? "input-valid" : ""} ${
            classNameInput || ""
          }`}
          id={id}
          placeholder={placeholder}
          {...register(name, {
            required: "Este campo es requerido",
            validate,
          })}
        />
        <button
          type="button"
          className="w-[30px] h-[30px] text-white bg-black rounded-full absolute z-10 right-[13px]"
          onClick={() => setPassword(!showPassword)}
        >
          {showPassword ? (
            <i className="text-[16px] bi bi-eye-slash-fill"></i>
          ) : (
            <i className="text-[16px] bi bi-eye-fill"></i>
          )}
        </button>
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-red-600">{message}</p>}
      />
    </CustomLabel>
  );
}

export default CustomPasswordField;
