// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom types imports
import { ExtensionType } from "../../../features/extensions/types";

// Hooks & Components (react-hook-form) imports
import { useFormContext } from "react-hook-form";

function EditExtensionForm() {
  const methods = useFormContext<ExtensionType>();

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-5 gap-x-4 gap-y-2">
      <CustomLabel
        label="ID Extension"
        id="idExtension"
        required={true}
        className="text-sm col-span-2"
        name="idExtension"
      >
        <input
        type="number"
          placeholder="ID Extension"
          className={`input-base ${
            methods.formState.errors.idExtension ? "input-error" : ""
          } ${
            !methods.formState.errors.idExtension &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("idExtension", {
            required: "Este campo es requerido",
            valueAsNumber: true
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Codigo de barra"
        id="barcode"
        required={true}
        className="text-sm col-span-3"
        name="barcode"
      >
        <input
          placeholder="Codigo de barra"
          className={`input-base ${
            methods.formState.errors.barcode ? "input-error" : ""
          } ${
            !methods.formState.errors.barcode && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("barcode", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombre"
        id="name"
        required={true}
        className="text-sm col-span-5"
        name="name"
      >
        <input
          placeholder="Nombre"
          className={`input-base ${
            methods.formState.errors.name ? "input-error" : ""
          } ${
            !methods.formState.errors.name && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("name", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>
    </div>
  );
}

export default EditExtensionForm;
