// React imports
import { ReactElement } from "react";

// State Redux imports
import { useAppSelector } from "../../app/hook";

interface Prop {
  children: ReactElement;
  roles?: number[];
}

function PrivateRoute({ children, roles }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);

  if (!currentUser.isAuthenticated) {
    window.location.replace("/ingresar");
    return null;
  }

  if (!!currentUser.user && roles && !roles.includes(currentUser.user.role)) {
    window.location.replace(`/${currentUser.user?._id}/pedidos`);
    return null;
  }

  return children;
}

export default PrivateRoute;
