function HeaderGridBranches() {
  return (
    <div className="hidden md:grid bg-gray-100 text-gray-700 divide-x border-t w-full h-[40px] grid-cols-[50px_1fr_1fr_3fr_2fr_2fr_2fr_2fr_40px]">
      <div className="px-2 flex items-center">
        <b className="text-sm">ID</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Backorder</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">CV</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Sucursal y Vendedor</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Departamento</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Ciudad</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Barrio</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Dirección</b>
      </div>
      <div className="px-2 flex items-center"></div>
    </div>
  );
}
export default HeaderGridBranches;
