// Types imports
import { BranchType } from "../../../features/branches/types";

interface Props {
  branch: BranchType;
  onSelect: (branch: BranchType) => void;
}

function RowBranch({ branch, onSelect }: Props) {
  return (
    <div className="divide-x border-t w-full h-[50px] grid grid-cols-[50px_1fr_1fr_3fr_2fr_2fr_2fr_2fr_40px]">
      <div className="px-2 flex items-center text-red-primary overflow-hidden">
        <b className="text-sm truncate">{branch.idBranch}</b>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <p className="text-sm truncate">{branch.backorder.idBackorder}</p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <p className="text-sm truncate">{branch.saleCondition.idSaleCondition}</p>
      </div>
      <div className="px-2 flex justify-center items-start flex-col overflow-hidden">
        <p className="text-sm font-bold truncate w-full">{branch.description}</p>
        <p className="text-xs truncate">{branch.seller?.name}</p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <p className="text-sm truncate">{branch.dpto}</p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <p className="text-sm truncate">{branch.city}</p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <p className="text-sm truncate">{branch.neighborhood}</p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <p className="text-sm truncate">{branch.adress}</p>
      </div>
      <div
        className="px-2 flex items-center justify-center cursor-pointer overflow-hidden"
        onClick={() => onSelect(branch)}
      >
        <i className="text-red-primary text-base bi bi-pencil-square"></i>
      </div>
    </div>
  );
}
export default RowBranch;
