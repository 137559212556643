export interface UserRegistrationType {
  email: string;
  name: string;
  idCard: string;
  role: number;
  seller: string | null;
  allCustomers?: boolean;
  password: string;
  confirmPassword: string;
}

export interface UserType {
  _id: string;
  email: string;
  name: string;
  idCard: string;
  role: number;
  seller?: any;
  allCustomers?: boolean;
}

export interface FiltersUserType {
  role?: string;
  search?: string;
}

export interface UpdatePasswordType {
  password: string;
  confirmPassword: string;
}

export enum ROLES {
  Seller = 0,
  CorporateEmployee = 1,
  CorporateAdmin = 2,
}
