// Custom types import
import { SimplifiedItems } from "./features/items/types";
import { WarehouseType } from "./features/warehouses/types";
import { ItemType } from "./features/items/types";
import { ProductType } from "./features/products/types";
import { OrderType } from "./features/orders/types";
import { RAABType } from "./features/rraab/types";

// Consts imports
import { MaximumNumberBeforeCompactNotation } from "./app/consts";

import { toast } from "react-toastify";

export const isUpperCase = (string: string) => /^[A-Z]*$/.test(string);
export const isLowerCase = (string: string) => /^[a-z]*$/.test(string);
export const isNumber = (character: string) => /^\d$/.test(character);

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getQuantityWarehouse = (
  simplifiedItems: SimplifiedItems[],
  idWarehouse: string
) => {
  const initialValue = 0;
  let quantities = simplifiedItems
    .filter((simplifiedItem) => simplifiedItem.idWarehouse === idWarehouse)
    .map((simplifiedItem) => simplifiedItem.quantity);
  if (quantities.length === 0) return "";
  const accumulationQuantities = quantities.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  return accumulationQuantities;
};

export const getQuantitiesExtension = (
  simplifiedItems: SimplifiedItems[],
  idExtension: string | undefined
) => {
  const initialValue = 0;
  const quantities = simplifiedItems
    .filter((simplifiedItem) => simplifiedItem.idExtension === idExtension)
    .map((simplifiedItem) => simplifiedItem.quantity);
  const accumulationQuantities = quantities.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  return accumulationQuantities;
};

export const getQuantitySimplifiedItem = (
  simplifiedItems: SimplifiedItems[],
  idExtension: string | undefined,
  idWarehouse: string
) => {
  const itemMatch = simplifiedItems.find(
    (simplifiedItem) =>
      simplifiedItem.idExtension === idExtension &&
      simplifiedItem.idWarehouse === idWarehouse
  );

  return itemMatch?.quantity;
};

export const getQuantitiesItems = (items: ItemType[]) => {
  const initialValue = 0;
  const quantities = items.map((item) => item.quantity);
  const accumulationQuantities = quantities.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  return accumulationQuantities;
};

export const getQuantities = (simplifiedItems: SimplifiedItems[]) => {
  const initialValue = 0;
  const quantities = simplifiedItems.map(
    (simplifiedItem) => simplifiedItem.quantity
  );
  const accumulationQuantities = quantities.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  return accumulationQuantities;
};

export const getTotals = (
  price: number = 0,
  quantity: number = 0,
  taxRate: number = 0
) => {
  const total = price * quantity;
  const percentageTaxRateToDecimal = taxRate / 100;
  const factor = percentageTaxRateToDecimal + 1;
  const subtotal = total / factor;
  const iva = total - subtotal;
  return {
    total,
    subtotal,
    iva,
  };
};

export const getTotalsItems = (items: ItemType[]) => {
  const initialValue = 0;
  const totals = items.map((item) => item.total);
  const subtotals = items.map((item) => item.subtotal);
  const ivas = items.map((item) => item.iva);

  const accumulationTotals = totals.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  const accumulationSubTotals = subtotals.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  const accumulationIvas = ivas.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );

  return {
    total: accumulationTotals,
    subtotal: accumulationSubTotals,
    iva: accumulationIvas,
  };
};

export const fromSimplifiedItemsToItems = (
  product: ProductType,
  simplifiedItems: SimplifiedItems[],
  warehouses: WarehouseType[]
): ItemType[] => {
  const items: ItemType[] = simplifiedItems.map((item) => {
    const extensionMatch = product.extensions.find(
      (extension) => extension._id === item.idExtension
    );

    const warehouseMatch = warehouses.find(
      (warehouse) => warehouse.idWarehouse === item.idWarehouse
    );

    const { total, subtotal, iva } = getTotals(
      product.price,
      item.quantity,
      product.taxRate
    );

    const cloneProduct: any = { ...product };
    delete cloneProduct.extensions;

    const data: any = {
      quantity: item.quantity,
      extension: { ...extensionMatch, product: cloneProduct },
      warehouse: warehouseMatch,
      price: product.price,
      iva: iva,
      subtotal: subtotal,
      total: total,
    };

    return data;
  });
  return items;
};

export const fromItemsToSimplifiedItems = (
  items: ItemType[] = []
): SimplifiedItems[] => {
  const simplifiedItems: SimplifiedItems[] = items.map((item) => ({
    quantity: item.quantity,
    idExtension: item.extension._id,
    idWarehouse: item.warehouse.idWarehouse,
  }));

  return simplifiedItems;
};

export const findProduct = (
  products: ProductType[] = [],
  search: string = ""
): ProductType | undefined => {
  const product = products.find((product) => {
    if (product.codProduct === search) {
      return product;
    }

    if (product.barcode === search) {
      return product;
    }

    const matchProductByBarcodeOrIdExtension = product.extensions.find(
      (extension) =>
        extension.barcode === search ||
        extension.idExtension.toString() === search
    );

    if (matchProductByBarcodeOrIdExtension) {
      return product;
    }

    return false;
  });

  return product;
};

export const setQuantityAutomaticDistribution = (
  setSimplifiedItems: React.Dispatch<React.SetStateAction<SimplifiedItems[]>>,
  value: number,
  product: ProductType,
  raab: RAABType,
  idExtension: string
) => {
  const rest = value % Number(product.unitPackaging);

  setSimplifiedItems((currentState) =>
    currentState.filter((item) => item.idExtension !== idExtension)
  );

  if (!value) return;

  if (!raab.inBatchLoadWarehouse || value < Number(product.unitPackaging)) {
    return setSimplifiedItems((currentState) => {
      const newValue = [
        ...currentState,
        {
          quantity: value,
          idWarehouse: raab.unitLoadWarehouse,
          idExtension,
        },
      ];
      return newValue;
    });
  }

  if (rest === 0) {
    return setSimplifiedItems((currentState) => {
      const newValue = [
        ...currentState,
        {
          quantity: value,
          idWarehouse: raab.inBatchLoadWarehouse as string,
          idExtension,
        },
      ];
      return newValue;
    });
  }

  setSimplifiedItems((currentState) => {
    const newValue = [
      ...currentState,
      {
        quantity: rest,
        idWarehouse: raab.unitLoadWarehouse,
        idExtension,
      },
      {
        quantity: value - rest,
        idWarehouse: raab.inBatchLoadWarehouse as string,
        idExtension,
      },
    ];
    return newValue;
  });
};

export const setQuantity = (
  simplifiedItems: SimplifiedItems[],
  setSimplifiedItems: React.Dispatch<React.SetStateAction<SimplifiedItems[]>>,
  value: number,
  idExtension: string,
  idWarehouse: string
) => {
  const currentQuantity = simplifiedItems.find(
    (item) =>
      item.idWarehouse === idWarehouse && item.idExtension === idExtension
  );

  if (!value && currentQuantity) {
    return setSimplifiedItems((currentState) =>
      currentState.filter(
        (item) =>
          !(
            item.idWarehouse === idWarehouse && item.idExtension === idExtension
          )
      )
    );
  }

  if (!!value && !currentQuantity) {
    return setSimplifiedItems((currentState) => {
      const newValue = [
        ...currentState,
        {
          quantity: value,
          idWarehouse,
          idExtension,
        },
      ];
      return newValue;
    });
  }

  if (!!value && currentQuantity) {
    return setSimplifiedItems((currentState) => {
      const newValue = currentState.map((item) => {
        if (
          item.idExtension === idExtension &&
          item.idWarehouse === idWarehouse
        ) {
          return { ...item, quantity: value };
        } else {
          return item;
        }
      });
      return newValue || [];
    });
  }
};

export const truncate = (
  fullStr: string,
  strLen: number = 8,
  separator: string = "...",
  frontChars: number = 3,
  backChars: number = 4
) => {
  if (fullStr.length <= strLen) return fullStr;

  return (
    fullStr.substring(0, frontChars) +
    separator +
    fullStr.substring(fullStr.length - backChars)
  );
};

export const formatPrice = (price: number) => {
  const format = Intl.NumberFormat("es-CO", {
    notation:
      price > MaximumNumberBeforeCompactNotation ? "compact" : "standard",
    currency: "COP",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(price);
  return `$ ${format} COP`;
};

export const formatNumber = (number: number) => {
  const format = Intl.NumberFormat("es-CO", {
    notation:
      number > MaximumNumberBeforeCompactNotation ? "compact" : "standard",
    maximumFractionDigits: 1,
  }).format(number);
  return format;
};

export const getSales = (orders: OrderType[]): number => {
  const initialValue = 0;
  const totals = orders.map((order) => order.total);
  const sales = totals.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );

  return sales;
};

export const sortItemsAlphabetically = (items: ItemType[]) => {
  const orderedItems = items.sort((a, b) => {
    const termA =
      a.extension.product.description +
      a.extension.name +
      a.warehouse.idWarehouse;
    const termB =
      b.extension.product.description +
      b.extension.name +
      b.warehouse.idWarehouse;
    if (termB < termA) return 1;
    if (termB > termA) return -1;
    return 0;
  });
  return orderedItems;
};

export const downloadReport = (data: any, title: string) => {
  const date = new Date().toLocaleDateString("es-CO", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${title} ${date}.csv`;
  link.click();
  window.URL.revokeObjectURL(link.href);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const validateFile = (
  file: File,
  onSuccess?: () => void,
  onError?: () => void
) => {
  if (file.type !== "text/csv") {
    onError?.();
    return toast.error("Archivo con formato invalido");
  }

  const filesize = (file.size / 1024 / 1024).toFixed(4);
  if (Number(filesize) > 5) {
    onError?.();
    return toast.error("Archivo con peso mayor a 5 MB");
  }

  onSuccess?.();
};

export const getFilesFromDataTransfer = (
  e: React.DragEvent<HTMLDivElement>
): File[] => {
  if (e.dataTransfer.items) {
    const items = e.dataTransfer.items as any;
    const itemsFile = [...items].filter((item) => item.kind === "file");

    const files = itemsFile.map((item) => item.getAsFile());
    return [...files].filter((file) => file !== null) as File[];
  } else {
    return [...(e.dataTransfer.files as any)];
  }
};

export function pad(num: string | number, size: number) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString("es-CO", {
    year: "numeric",
    month: "2-digit",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};
