// React imports
import React, { useState, useEffect } from "react";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../../components/shared/ContentWithToggle";
import DataProductForm from "../../../components/forms/products/DataProductForm";
import DropdownActions from "../../../components/DropdownActions";
import ListExtensions from "../../../components/forms/products/ListExtensions";
import DrawerCreateExtension from "../../../components/forms/products/DrawerCreateExtension";
import DrawerEditExtension from "../../../components/forms/products/DrawerEditExtension";

// Custom queries imports
import { useRegisterProductMutation } from "../../../features/products/useRegisterProductMutation";

// Custom types imports
import { ProductType } from "../../../features/products/types";
import { ExtensionType } from "../../../features/extensions/types";
import { Action } from "../../../components/DropdownActions/types";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom helpers import
import { confirmChange } from "../../../features/auth/helpers";

function CreateProduct() {
  const submitButtonRef = React.useRef<any>(null);
  const [extensions, setExtensions] = useState<ExtensionType[]>([]);
  const [openDrawerCreateExtension, setOpenDrawerCreateExtension] =
    useState<boolean>(false);

  const [openDrawerEditExtension, setOpenDrawerEditExtension] =
    useState<boolean>(false);

  const [selectedExtension, setSelectedExtension] = useState<
    ExtensionType | undefined
  >(undefined);

  useEffect(() => {
    if (openDrawerEditExtension === false) {
      setSelectedExtension(undefined);
    }
  }, [openDrawerEditExtension, setSelectedExtension]);

  const [selectedExtensionIndex, setSelectedExtensionIndex] = useState<
    number | undefined
  >(undefined);

  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<ProductType>({
    defaultValues: {
      barcode: "",
      codProduct: "",
      description: "",
      taxRate: 0,
      price: 0,
      unitPackaging: "",
      unitInventory: "",
      extensions: [],
    },
  });

  const registerProductMutation = useRegisterProductMutation(
    (data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Registro exitoso del producto #${data.codProduct}.`,
        closeOnClick: true,
        closeButton: true,
      });
      navigate(`/${currentUser?.user?._id}/productos/producto/${data._id}`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar crear el producto",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmitProduct = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Creando producto...");
    registerProductMutation.mutate({
      data: { ...data, extensions },
      notificationID,
    });
  });

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-arrow-repeat text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Agregar extension",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setOpenDrawerCreateExtension(true),
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser.user?._id}/productos`);
          }
        );
      },
    },
  ];

  return (
    <div className="divide-y w-full sm:max-w-[689px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Crear Producto"
        onBackButton={() => navigate(`/${currentUser?.user?._id}/productos`)}
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <FormProvider {...methods}>
          <form onSubmit={onSubmitProduct}>
            <DataProductForm />
            <ListExtensions
              extensions={extensions}
              onSelect={(extension, index) => {
                setOpenDrawerEditExtension(true);
                setSelectedExtensionIndex(index);
                setSelectedExtension(extension);
              }}
            />
            <input type="submit" className="hidden" ref={submitButtonRef} />
          </form>
        </FormProvider>
        {!!selectedExtension && (
          <DrawerEditExtension
            open={openDrawerEditExtension}
            setOpen={setOpenDrawerEditExtension}
            extension={selectedExtension}
            onDelete={() =>
              setExtensions((currentExtensions) =>
                currentExtensions.filter(
                  (_extension, index) => index !== selectedExtensionIndex
                )
              )
            }
            onUpdate={(data) =>
              setExtensions((currentExtensions) =>
                currentExtensions.map((extension, index) =>
                  index === selectedExtensionIndex ? data : extension
                )
              )
            }
          />
        )}
        <DrawerCreateExtension
          open={openDrawerCreateExtension}
          setOpen={setOpenDrawerCreateExtension}
          onAdd={(extension) =>
            setExtensions((currentExtension) => [
              ...currentExtension,
              extension,
            ])
          }
        />
      </ContentWithToggle>
    </div>
  );
}
export default CreateProduct;
