// Custom components imports
import EditExtensionForm from "./EditExtensionForm";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom types imports
import {
  ExtensionRegistrationType,
  ExtensionType,
} from "../../../features/extensions/types";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAdd: (data: ExtensionType) => void;
}

function DrawerCreateExtension({ open, setOpen, onAdd }: Props) {
  const methods = useForm<ExtensionRegistrationType>({
    defaultValues: {
      name: "",
      idExtension: undefined,
      barcode: "",
    },
  });

  const closeDrawer = () => {
    methods.reset();
    setOpen(false);
  };

  const onSubmit = methods.handleSubmit((data) => {
    onAdd(data);
    closeDrawer();
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="mr-4 text-xl bi bi-arrow-left"></i>
              </button>{" "}
              <b className="font-medium text-lg">Agregar Extension</b>
              <button
                className="ml-auto button-primary inline-block w-[160px] text-sm"
                type="submit"
              >
                Agregar
              </button>
            </div>
            <EditExtensionForm />
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default DrawerCreateExtension;
