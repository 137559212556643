import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface Props {
  label: string;
  children: ReactNode;
  id: string;
  required: boolean;
  className?: string;
  name?: string;
}
function CustomLabel({
  label,
  children,
  id,
  required,
  className,
  name,
}: Props) {
  const methods = useFormContext();
  return (
    <label className={`mb-[8px] ${className || ""}`} htmlFor={id}>
      <p className="text-xs text-black">
        {label} <span className="text-red-primary">{required ? " *" : ""}</span>
      </p>
      {children}
      {name && (
        <ErrorMessage
          errors={methods.formState.errors}
          name={name}
          render={({ message }) => (
            <p className="mt-2 text-red-600">{message}</p>
          )}
        />
      )}
    </label>
  );
}

export default CustomLabel;
