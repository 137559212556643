import { WarehouseType } from "../../features/warehouses/types";

interface Props {
  warehouse: WarehouseType;
  onSelect: (warehouse: WarehouseType) => void;
}

function WarehouseCard({ warehouse, onSelect }: Props) {
  return (
    <div
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-2"
      onClick={() => onSelect(warehouse)}
    >
      <div className="col-span-1 flex flex-col">
        <p className="truncate">
          <b className="text-red-primary">{warehouse.idWarehouse}</b>{" "}
          {warehouse.name}
        </p>
      </div>
      <div className="flex flex-1 items-end flex-col justify-center">
        <i className="bi bi-chevron-right text-[#EA002A] text-lg"></i>
      </div>
    </div>
  );
}

export default WarehouseCard;
