// Custom helpers imports
import { isNumber, isUpperCase, isLowerCase } from "../../helpers";

// Third helpers imports
import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert";

// Custom types imports
import { ROLES } from "../users/types";

// Consts
export const DEVICE_ID = "DEVICE_ID";
export const USER_KEY = "User";

export const roleDescription = (role: number) => {
  if (role === ROLES.Seller) return "Vendedor";
  if (role === ROLES.CorporateEmployee) return "Empleado Administrativo";
  if (role === ROLES.CorporateAdmin) return "Supervisor Administrativo";
  return "Sin rol definido";
};

export const validateMinLength = (
  value: string,
  minLength: number,
  message?: string
) => {
  return (
    value.length >= minLength ||
    message ||
    `Se requiere un minimo de ${minLength} caracteres`
  );
};

export const validateContainsNumber = (value: string, message?: string) => {
  let foundNumber = Array.from(value).find((character) => isNumber(character));
  return (
    !!foundNumber ||
    message ||
    "Se requiere que almenos un caracter sea un número"
  );
};

export const validateContainsUppercase = (value: string, message?: string) => {
  let foundUppercase = Array.from(value).find((character) =>
    isUpperCase(character)
  );
  return (
    !!foundUppercase ||
    message ||
    "Se requiere que almenos un caracter sea una mayuscula"
  );
};

export const validateContainsLowercase = (value: string, message?: string) => {
  let foundLowercase = Array.from(value).find((character) =>
    isLowerCase(character)
  );
  return (
    !!foundLowercase ||
    message ||
    "Se requiere que almenos un caracter sea una minuscula"
  );
};

export const validatePasswordConfirmation = (
  password: string,
  passwordConfirmation: string,
  message?: string
) => {
  return (
    password === passwordConfirmation ||
    message ||
    "Las contraseñas no coinciden"
  );
};

export const generateDeviceID = () => {
  let deviceID = localStorage.getItem(DEVICE_ID);
  if (!!deviceID) return deviceID;
  localStorage.setItem(DEVICE_ID, uuidv4());
  return localStorage.getItem(DEVICE_ID);
};


export const confirmChange = (
  title: string,
  message: string,
  action: () => void
) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: "Cancelar",
        className: "button-secondary",
      },
      {
        label: "Aceptar",
        className: "button-primary",
        onClick: () => action(),
      },
    ],
  });
};
