import { NavLink } from "react-router-dom";

interface Props {
  to: string;
  text: string;
  disabled?: boolean;
  soon?: boolean;
  inMaintenance?: boolean;
  onClick?: () => void | undefined;
}

function LinkWithoutSubmenu({
  to,
  text,
  disabled,
  inMaintenance,
  soon,
  onClick,
}: Props) {
  return (
    <>
      <NavLink
        to={to}
        className={({ isActive }) =>
          `cursor-pointer hover:bg-red-100 flex justify-between w-full px-2 py-4 ${
            disabled ? "opacity-50 pointer-events-none" : ""
          } ${isActive ? "cursor-auto hover:!bg-red-primary bg-red-primary text-white" : ""}`
        }
        onClick={onClick}
      >
        {text}
        {soon && (
          <div className="font-medium border border-red-primary text-[11px] text-red-primary flex justify-center items-center px-2 py-1 rounded-full">
            Proximamente
          </div>
        )}
        {inMaintenance && (
          <div className="font-medium border border-red-primary text-[11px] text-red-primary flex justify-center items-center px-2 py-1 rounded-full">
            En Mantenimiento
          </div>
        )}
        <i className="bi bi-chevron-right"></i>
      </NavLink>
    </>
  );
}

export default LinkWithoutSubmenu;
