// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import HeaderGridBranches from "./HeaderGridBranches";
import RowBranch from "./RowBranch";
import BranchCard from "../../cards/BranchCard";
import EmptyContent from "../../shared/EmptyContent";

// Custom helpers imports
import { formatNumber } from "../../../helpers";

//Custom types imports
import { BranchType } from "../../../features/branches/types";

interface Props {
  branches: BranchType[];
  onSelect: (branch: BranchType, index: number) => void;
}

function ListBanches({ branches, onSelect }: Props) {
  return (
    <ContentWithToggle title="Sucursales" isToogle={false}>
      {branches.length === 0 ? (
        <div className="w-full border-t">
          <div className="w-full">
            <EmptyContent text="No hay Sucursales" />
          </div>
        </div>
      ) : (
        <>
          <div className="border-t w-full px-4 py-3 flex justify-between items-center border-b">
            <span className="text-gray-600">Número de sucursales</span>
            <div className="w-[30%] text-end">
              <b>{formatNumber(branches.length)}</b>
            </div>
          </div>
          <HeaderGridBranches />
          <div className="w-full block md:hidden divide-y">
            {branches.map((branch, index) => (
              <BranchCard
                key={branch._id}
                branch={branch}
                onSelect={(branch) => onSelect(branch, index)}
              />
            ))}
          </div>
          <div className="w-full hidden md:block">
            {branches.map((branch, index) => (
              <RowBranch
                branch={branch}
                key={branch._id}
                onSelect={(branch) => onSelect(branch, index)}
              />
            ))}
          </div>
        </>
      )}
    </ContentWithToggle>
  );
}

export default ListBanches;
