import { CustomerType } from "../../features/customers/types";

interface Props {
  customer: CustomerType;
  onSelect: (customer: CustomerType) => void;
}

function CustomerCard({ customer, onSelect }: Props) {
  return (
    <div
      className="flex flex-1 flex-row gap-x-2 py-2 px-4 hover:cursor-pointer hover:bg-red-50 transition-all"
      onClick={() => onSelect(customer)}
    >
      <div className="w-[75%] flex flex-col">
        <div className="flex flex-row items-center">
          <p className="text-[#EA002A] font-[700] mb-1   mr-2">
            {customer?.idClient}
          </p>
          <p className="font-[700] mb-1 truncate flex-1">{customer?.name}</p>
        </div>
        <div className="flex flex-row items-center mb-1 gap-x-1">
          <p className="mb-1 truncate text-[#717171]">
            NIT: {Intl.NumberFormat("es-CO").format(Number(customer.nit))}
          </p>
          <div className="px-3 py-[2px] mr-2 rounded-lg border border-[#D1D1D1] bg-[#F8F8F8]">
            <span className="text-sm">
              {customer.branches?.length > 1
                ? `${customer.branches?.length} sucursales`
                : `${customer.branches?.length} sucursal`}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-1 items-end flex-col justify-center">
        <i className="bi bi-chevron-right text-[#EA002A] text-lg"></i>
      </div>
    </div>
  );
}

export default CustomerCard;
