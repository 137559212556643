// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { UserType } from "./types";

interface ParamsType {
  search?: string;
}

export function useUsers(params?: ParamsType) {
  async function getUsers(): Promise<UserType[]> {
    await timeout(500);
    const response = await api.get<UserType[]>("users/all", { ...params });

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Users", params],
    queryFn: () => getUsers(),
  });
}
