// Custom types imports
import { WarehouseType } from "../../features/warehouses/types";

interface Props {
  warehouses: WarehouseType[];
  idWarehouse: string;
  setIdWarehouse: React.Dispatch<React.SetStateAction<string>>;
}

export default function WarehousePills({
  warehouses,
  idWarehouse,
  setIdWarehouse,
}: Props) {
  return (
    <div className="flex w-full max-w-full mb-4 overflow-x-scroll no-scrollbar">
      <button
        type="button"
        onClick={() => setIdWarehouse("")}
        className={`py-1 px-5 mr-3 rounded-md ${
          idWarehouse === ""
            ? "bg-red-primary text-white"
            : "bg-gray-third text-black"
        }`}
      >
        Todas
      </button>
      {warehouses?.map((warehouse) => (
        <button
          type="button"
          key={warehouse._id}
          onClick={() => setIdWarehouse(warehouse.idWarehouse)}
          className={`py-1 px-5 mr-3 rounded-md ${
            warehouse.idWarehouse === idWarehouse
              ? "bg-red-primary text-white"
              : "bg-gray-third text-black"
          }`}
        >
          {warehouse.idWarehouse}
        </button>
      ))}
    </div>
  );
}
