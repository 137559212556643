//Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Components (react-currency-input-field) imports
import CurrencyInput from "react-currency-input-field";

// Custom types imports
import { ProductType } from "../../../features/products/types";

// Custom (react-hook-form) imports
import { useFormContext } from "react-hook-form";

function DataProductForm() {
  const methods = useFormContext<ProductType>();

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-2 border-b">
      <CustomLabel
        label="Codigo"
        id="codProduct"
        required={true}
        className="text-sm sm:col-span-1"
        name="codProduct"
      >
        <input
          placeholder="Codigo"
          className={`input-base ${
            methods.formState.errors.codProduct ? "input-error" : ""
          } ${
            !methods.formState.errors.codProduct &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("codProduct", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>
      <CustomLabel
        label="Nombre"
        id="description"
        required={true}
        className="text-sm sm:col-span-2 md:col-span-3"
        name="description"
      >
        <input
          placeholder="Nombre"
          className={`input-base ${
            methods.formState.errors.description ? "input-error" : ""
          } ${
            !methods.formState.errors.description &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("description", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>
      <CustomLabel
        label="Tasa Impositiva"
        id="taxRate"
        required={true}
        className="text-sm sm:col-span-1"
        name="taxRate"
      >
        <input
          type="number"
          placeholder="Tasa Impositiva"
          className={`input-base ${
            methods.formState.errors.taxRate ? "input-error" : ""
          } ${
            !methods.formState.errors.taxRate && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("taxRate", {
            required: "Este campo es requerido",
            valueAsNumber: true,
            min: { value: 0, message: "Valor minimo es 0" },
            max: { value: 100, message: "Valor maximo es 100" },
          })}
        />
      </CustomLabel>
      <CustomLabel
        label="Precio Unitario"
        id="factura"
        required={true}
        className="text-sm sm:col-span-1"
        name="price"
      >
        <CurrencyInput
          decimalSeparator=","
          groupSeparator="."
          placeholder="Precio Unitario"
          decimalsLimit={0}
          allowNegativeValue={false}
          allowDecimals={false}
          prefix="$ "
          value={methods.watch("price")}
          onValueChange={(value) =>
            methods.setValue("price", Number(value) || 0)
          }
          className={`input-base ${
            methods.formState.errors.price ? "input-error" : ""
          } ${
            !methods.formState.errors.price && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
        />
      </CustomLabel>
      <CustomLabel
        label="Unidad de empaque"
        id="unitPackaging"
        required={true}
        className="text-sm sm:col-span-1"
        name="unitPackaging"
      >
        <input
          placeholder="Unidad de empaque"
          type="number"
          className={`input-base ${
            methods.formState.errors.unitPackaging ? "input-error" : ""
          } ${
            !methods.formState.errors.unitPackaging &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("unitPackaging", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>
      <CustomLabel
        label="Unidad de inventario"
        id="unitInventory"
        required={true}
        className="text-sm sm:col-span-1"
        name="unitInventory"
      >
        <input
          placeholder="Unidad de empaque"
          className={`input-base ${
            methods.formState.errors.unitInventory ? "input-error" : ""
          } ${
            !methods.formState.errors.unitInventory &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("unitInventory", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>
    </div>
  );
}
export default DataProductForm;
