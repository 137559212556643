// React imports
import React from "react";

// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

// Custom components
import ContentWithToggle from "../../../components/shared/ContentWithToggle";
import CreateUserForm from "../../../components/forms/users/CreateUserForm";
import DropdownActions from "../../../components/DropdownActions";

// Hooks Redux imports
import { useAppSelector } from "../../../app/hook";

// Custom types imports
import { UserRegistrationType } from "../../../features/users/types";
import { Action } from "../../../components/DropdownActions/types";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Third components & helpers imports
import { toast } from "react-toastify";

// Hooks & components (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom Hooks imports
import { useRegisterUserMutation } from "../../../features/users/useRegisterUserMutation";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

function CreateUser() {
  const submitButtonRef = React.useRef<any>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.user);
  const methods = useForm<UserRegistrationType>({
    defaultValues: {
      email: "",
      name: "",
      idCard: "",
      role: undefined,
      seller: "",
      password: "",
      confirmPassword: "",
      allCustomers: false,
    },
  });

  const registerUserMutation = useRegisterUserMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Usuario creado`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Users"]);
      navigate(`/${currentUser?._id}/usuarios/usuario/${data._id}/general`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar crear el usuario",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Creando usuario...");
    registerUserMutation.mutate({
      notificationID,
      data,
    });
  });

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () =>
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser?._id}/usuarios`);
          }
        ),
    },
  ];

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <ContentWithToggle
            title="Crear Usuario"
            onBackButton={() => navigate(`/${currentUser?._id}/usuarios`)}
            isToogle={false}
            actionButton={<DropdownActions actions={ACTIONS} />}
          >
            <CreateUserForm />
            <input type="submit" className="hidden" ref={submitButtonRef} />
          </ContentWithToggle>
        </form>
      </FormProvider>
    </div>
  );
}
export default CreateUser;
