// Components imports
import { ReactNode, useState } from "react";

interface Props {
  children?: ReactNode;
  title: string;
  onBackButton?: () => void;
  isToogle?: boolean;
  actionButton?: ReactNode;
  defaultOpenValue?: boolean;
}

function ContentWithToggle({
  children,
  title,
  onBackButton,
  isToogle,
  actionButton,
  defaultOpenValue,
}: Props) {
  const [isOpen, setIsOpen] = useState(
    defaultOpenValue === undefined ? true : defaultOpenValue
  );
  return (
    <div className="w-full">
      <div
        className={`relative w-full flex justify-between items-center p-4 border-b ${
          isOpen ? "shadow-md" : "shadow-none"
        }`}
      >
        <div className="flex items-center overflow-hidden">
          {!!onBackButton && (
            <button type="button" onClick={onBackButton}>
              <i className="mr-4 text-xl bi bi-arrow-left"></i>
            </button>
          )}
          <h3 className="font-medium sm:text-lg truncate">{title}</h3>
        </div>
        <div className="flex items-center ml-2">
          {!!actionButton && actionButton}
          {!!isToogle && (
            <button
              className="flex justify-center items-center"
              type="button"
              onClick={() => setIsOpen(!isOpen)}
            >
              <i
                className={`text-xl bi ${
                  isOpen ? "bi-chevron-up" : "bi-chevron-down"
                }`}
              ></i>
            </button>
          )}
        </div>
      </div>
      <div className={`w-full transition-all ${isOpen ? "block" : "hidden"}`}>
        {children}
      </div>
    </div>
  );
}
export default ContentWithToggle;
