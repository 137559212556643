import { SellerType } from "../../features/sellers/types";

interface Props {
  seller: SellerType;
  onSelect: (seller: SellerType) => void;
}

function SellerCard({ seller, onSelect }: Props) {
  return (
    <div
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-4"
      onClick={() => onSelect(seller)}
    >
      <div className="col-span-3 flex flex-col items-start">
        <p className="w-full  sm:text-sm truncate">
          <b className="text-red-primary">#{seller.idSeller}</b> {seller.name}
        </p>
        <div className="flex items-center gap-x-1">
          <p className="text-gray-500">
            CC {Intl.NumberFormat("es-CO").format(Number(seller.idCard))}
          </p>
          <div className="px-3 py-[2px] mr-2 rounded-lg border border-[#D1D1D1] bg-[#F8F8F8]">
            <span className="text-sm">
              CO #{seller.idOperationCenter}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-1 items-end flex-col justify-center">
        <i className="bi bi-chevron-right text-[#EA002A] text-lg"></i>
      </div>
    </div>
  );
}

export default SellerCard;
