// Types imports
import { ExtensionType } from "../../../features/extensions/types";

interface Props {
  extension: ExtensionType;
  onSelect: (extension: ExtensionType) => void;
}

function RowExtension({ extension, onSelect }: Props) {
  return (
    <div className="divide-x border-t w-full h-[50px] grid grid-cols-[1fr_3fr_3fr_1fr]">
      <div className="px-2 flex items-center overflow-hidden">
        <p className="w-full text-sm truncate">{extension.idExtension}</p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <p className="w-full text-sm truncate">
          {extension.name}
        </p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="w-full text-sm truncate">
          {extension.barcode}
        </span>
      </div>
      <div
        className="px-2 flex items-center justify-center cursor-pointer"
        onClick={() => onSelect(extension)}
      >
        <i className="text-red-primary text-lg bi bi-pencil-square"></i>
      </div>
    </div>
  );
}
export default RowExtension;
