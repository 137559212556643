// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { RAABType } from "./types";

interface ParamsType {
  codProduct?: string;
  idOperationCenter?: string;
}

export function useRRAAB(params: ParamsType, enabled = true) {
  async function getRRAAB(): Promise<RAABType[]> {
    await timeout(500);
    const response = await api.get<RAABType[]>("rraab/all", params);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["RRAAB", params],
    queryFn: () => getRRAAB(),
    refetchOnWindowFocus: false,
    enabled,
  });
}
