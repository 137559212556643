// React imports
import React, { useState } from "react";

// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import InfiniteListCustomers from "../../components/infinite_lists/InfiniteListCustomers";
import SearchInput from "../../components/shared/fields/SearchInput";
import DrawerImporter from "../../components/forms/importer/DrawerImporter";
import DropdownActions from "../../components/DropdownActions";
import Loading from "../../components/shared/Loading";
import { useNavigate } from "react-router-dom";

// Custom types imports
import { Action } from "../../components/DropdownActions/types";

// State Redux imports
import { useAppSelector } from "../../app/hook";

// Custom queries imports
import { useOrders } from "../../features/orders/useOrders";
import { useCustomersInfinite } from "../../features/customers/useCustomersInfinite";
import { useImportsMutation } from "../../features/common/useImportsMutation";
import { useExportCustomersMutation } from "../../features/customers/useExportCustomersMutation";

// Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { downloadReport } from "../../helpers";

function Customers() {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const searchRef = React.useRef<any>(null);
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const [isDone, setIsDone] = useState<boolean>(false);

  const [visibleDrawer, setVisibleDrawer] = useState<"import" | undefined>(
    undefined
  );

  const [searchFilter, setSearchFilter] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const customers = useCustomersInfinite({ search: searchFilter });
  const {
    data: orders,
    isLoading: ordersLoading,
    isFetching: ordersFetching,
    refetch: ordersRefetch,
  } = useOrders();

  const areTherePendingOrders = ordersLoading
    ? undefined
    : orders?.find((order) => order.status === "pending")
    ? true
    : false;

  const importCustomersMutation = useImportsMutation(
    (_data) => {
      setErrors(undefined);
      setIsDone(true);
      customers.refetch();
    },
    (error) => {
      setErrors(error.data);
      setIsDone(false);
    }
  );

  const exportCustomersMutation = useExportCustomersMutation(
    (data) => {
      downloadReport(data, "Lista de Clientes ");
      toast.success("Exportación de centros de operación exitosos.");
    },
    (_error) => {
      toast.error("Algo salió mal al intentar exportar centros de operación.");
    }
  );

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => navigate("agregar"),
    },
    {
      label: "Importar",
      hidden: areTherePendingOrders,
      icon: <i className="bi bi-upload text-lg mr-2"></i>,
      onAction: () => setVisibleDrawer("import"),
    },
    {
      label: "Exportar",
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () =>
        exportCustomersMutation.mutate({
          notificationID: "",
          params: { search: searchFilter },
        }),
    },
  ];

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Clientes"
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        {areTherePendingOrders && (
          <div className="w-full flex flex-wrap sm:flex-nowrap sm:justify-center bg-yellow-500  py-4 px-4">
            {ordersFetching ? (
              <Loading text="Revalidando..." />
            ) : (
              <>
                <i className="text-xl bi bi-exclamation-circle-fill mr-4"></i>
                <div className="flex-1">
                  <h3 className="font-bold">
                    No está habilitada la funcionalidad de importación de
                    clientes
                  </h3>

                  <div className="mt-2">
                    <p>
                      Hay pedidos pendientes de revisión, es necesario resolver
                      todas la revisiones:{" "}
                    </p>
                    <ul className="pl-8 list-decimal">
                      {orders?.filter((order)=> order.status === "pending").map((order, index) => (
                        <li key={index}>
                          <a
                            className="text-blue-800 underline hover:font-semibold"
                            href={`/${currentUser.user?._id}/pedidos/pedido/${order._id}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            Pedido #{order.idOrder}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <button
                    type="button"
                    onClick={() => ordersRefetch()}
                    tabIndex={-1}
                    className="mt-2 bg-white text-black rounded-md px-5 py-1 font-bold text-sm"
                  >
                    Reintentar validación
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        <div className="w-full px-4 py-3 flex justify-between items-center">
          <SearchInput
            searchRef={searchRef}
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            onStopTyping={(value) => setSearchFilter(value)}
          />
        </div>
        <InfiniteListCustomers customers={customers} />
        <DrawerImporter
          isLoading={importCustomersMutation.isLoading}
          isDone={isDone}
          title="Importar Clientes"
          visible={visibleDrawer === "import"}
          errors={errors}
          onHideError={(index) =>
            setErrors((state) => state?.filter((_error, i) => i !== index))
          }
          onHideAllErrors={() => setErrors([])}
          onClose={() => {
            setVisibleDrawer(undefined);
            setErrors(undefined);
            setIsDone(false);
            setErrors([]);
          }}
          onImport={(data) => {
            setErrors(undefined);
            setIsDone(false);
            importCustomersMutation.mutate({
              entity: "clients",
              file: data.file,
            });
          }}
        />
      </ContentWithToggle>
    </div>
  );
}

export default Customers;
