// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom types imports
import { SaleConditionType } from "../../../features/saleConditions/types";

// Hooks & Components (react-hook-form) imports
import { useFormContext } from "react-hook-form";

function EditSaleConditionForm() {
  const methods = useFormContext<SaleConditionType>();

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
      <CustomLabel
        label="ID"
        id="idSaleCondition"
        required={true}
        className="text-sm sm:col-span-1"
        name="idSaleCondition"
      >
        <input
          placeholder="ID de Condición de Venta"
          className={`input-base ${
            methods.formState.errors?.idSaleCondition ? "input-error" : ""
          } ${
            !methods.formState.errors?.idSaleCondition &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("idSaleCondition")}
        />
      </CustomLabel>
      <CustomLabel
        label="Nivel de recaudo"
        id="levelCollection"
        required={true}
        className="text-sm sm:col-span-1"
        name="levelCollection"
      >
        <input
          type="number"
          placeholder="Nivel de recaudo"
          className={`input-base ${
            methods.formState.errors?.levelCollection ? "input-error" : ""
          } ${
            !methods.formState.errors?.levelCollection &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("levelCollection", { valueAsNumber: true })}
        />
      </CustomLabel>
      <CustomLabel
        label="Nombre"
        id="name"
        required={true}
        className="text-sm sm:col-span-2"
        name="name"
      >
        <input
          placeholder="Nombre"
          className={`input-base ${
            methods.formState.errors?.name ? "input-error" : ""
          } ${
            !methods.formState.errors?.name && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("name")}
        />
      </CustomLabel>
    </div>
  );
}

export default EditSaleConditionForm;
