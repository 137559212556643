// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import RowProduct from "./RowProduct";
import HeaderGridProducts from "./HeaderGridProducts";
import EndMessageInfiniteList from "../shared/EndMessageInfiniteList";
import LoaderInfiniteList from "../shared/LoaderInfiniteList";
import ProductCard from "../cards/ProductCard";

// Components (react-infinite-scroll-component) imports
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks (react-router-dom) imports
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

// Custom types imports
import { ProductPagination } from "../../features/products/types";

interface Props {
  products: UseInfiniteQueryResult<ProductPagination, unknown>;
}

function InfiniteGridProducts({ products }: Props) {
  const navigate = useNavigate();
  const data = products.data?.pages?.flatMap((page) => page.items) || [];
  if (products.isLoading) {
    return <Loading text="Cargando productos..." />;
  }

  if (products.isError || !products.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar productos" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay productos" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de productos</span>
        <div className="w-[30%] text-end">
          <b>{products.data.pages[0].totalItems}</b>
        </div>
      </div>
      <InfiniteScroll
        dataLength={data.length || 0}
        next={() => products.fetchNextPage()}
        hasMore={!!products.hasNextPage}
        loader={<LoaderInfiniteList />}
        endMessage={<EndMessageInfiniteList message="Carga completada" />}
      >
        <>
          <HeaderGridProducts />
          <div className="divide-y w-full block md:hidden">
            {data.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                onSelect={() => navigate(`producto/${product._id}`)}
              />
            ))}
          </div>
          <div className="divide-y hidden md:block">
            {data.map((product) => (
              <RowProduct
                key={product._id}
                product={product}
                onSelect={() => navigate(`producto/${product._id}`)}
              />
            ))}
          </div>
        </>
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteGridProducts;
