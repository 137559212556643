// Custom components imports
import EditSellerForm from "../../../components/forms/seller/EditSellerForm";
import Loading from "../../../components/shared/Loading";
import ErrorLoading from "../../../components/shared/ErrorLoading";

// Custom queries imports
import { useSeller } from "../../../features/sellers/useSeller";
import { useOperationCenters } from "../../../features/operationCenters/useOperationCenters";
import { useSellerIsRemovable } from "../../../features/sellers/useSellerIsRemovable";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditSeller() {
  const params = useParams();

  const seller = useSeller(params.idSeller);
  const operationCenters = useOperationCenters();
  const {
    data: sellerIsRemovable,
    isLoading: isRemovableLoading,
    isError: isRemovableError,
    isFetching: sellerIsRemovableFetching,
    refetch: sellerIsRemovableRefetch,
  } = useSellerIsRemovable(params.idSeller);

  if (seller.isLoading || isRemovableLoading) {
    return <Loading text="Cargando informacion del vendedor..." />;
  }

  if (seller.isError || !seller.data || isRemovableError) {
    return (
      <ErrorLoading text="¡Hubo un error al cargar informacion del vendedor!" />
    );
  }

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <EditSellerForm
        seller={seller.data}
        sellerIsRemovable={sellerIsRemovable}
        isFetching={sellerIsRemovableFetching}
        refetch={() => sellerIsRemovableRefetch()}
        operationCenters={operationCenters}
      />
    </div>
  );
}

export default EditSeller;
