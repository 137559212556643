import { UserType } from "../../features/users/types";

import { roleDescription } from "../../features/auth/helpers";
import { truncate } from "../../helpers";

interface Props {
  user: UserType;
  onSelect: (user: UserType) => void;
}

function UserCard({ user, onSelect }: Props) {
  return (
    <div
      className="flex flex-1 flex-row gap-x-2 py-2 px-4 hover:cursor-pointer hover:bg-red-50 transition-all"
      onClick={() => onSelect(user)}
    >
      <div className="w-[90%] flex flex-col">
        <div className="flex flex-row items-center">
          <p className="w-25 text-[#EA002A] font-[700] mb-1   mr-2">
            {truncate(user?._id)}
          </p>
          <p className="font-[700] mb-1 truncate flex-1">{user?.name}</p>
        </div>
        <div className="flex items-center gap-x-1">
          <p className="truncate text-[#717171]">
            CC {Intl.NumberFormat("es-CO").format(Number(user?.idCard))}
          </p>
          <div className="px-3 py-[2px] mr-2 rounded-lg border border-[#D1D1D1] bg-[#F8F8F8]">
            <span className="text-sm">{roleDescription(user.role)}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-1 items-end flex-col justify-center">
        <i className="bi bi-chevron-right text-[#EA002A] text-lg"></i>
      </div>
    </div>
  );
}

export default UserCard;
