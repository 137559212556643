// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import HeaderGridRRAAB from "./HeaderGridRRAAB";
import RowRAAB from "./RowRAAB";
import EmptyContent from "../../shared/EmptyContent";
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";

//Custom types imports
import { RAABType } from "../../../features/rraab/types";

interface Props {
  isLoading: boolean;
  isError: boolean;
  rraab: RAABType[] | undefined;
  onSelect: (raab: RAABType, index: number) => void;
}

function ListRRAAB({ isLoading, isError, rraab, onSelect }: Props) {
  return (
    <div className="w-full border-b">
      <ContentWithToggle
        title="Reglas de asignación automática de bodega"
        isToogle={true}
      >
        {isLoading ? (
          <Loading text="Cargando RRAAB..." />
        ) : isError ? (
          <ErrorLoading text="¡Hubo un error al cargar RRAAB" />
        ) : (
          <>
            {rraab?.length === 0 ? (
              <div className="w-full border-t">
                <div className="w-full">
                  <EmptyContent text="No hay reglas" />
                </div>
              </div>
            ) : (
              <>
                <div className="border-t w-full px-4 py-3 flex justify-between items-center border-b">
                  <span className="text-gray-600">Número de reglas</span>
                  <div className="w-[30%] text-end">
                    <b>{rraab?.length}</b>
                  </div>
                </div>
                <HeaderGridRRAAB />
                <div className="w-full block">
                  {rraab?.map((raab, index) => (
                    <RowRAAB
                      raab={raab}
                      key={raab._id}
                      onSelect={(raab) => onSelect(raab, index)}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </ContentWithToggle>
    </div>
  );
}

export default ListRRAAB;
