// Components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Custom components imports
import LoginForm from "../../components/forms/login/LoginForm";

// Custom types imports
import { UserCredentialsType } from "../../features/auth/types";

function Login() {
  const methods = useForm<UserCredentialsType>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  return (
    <div className="flex items-start flex-wrap">
      <FormProvider {...methods}>
        <LoginForm />
      </FormProvider>
    </div>
  );
}

export default Login;
