// Custom components imports
import EditRAABForm from "./EditRAABForm";
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom queries imports
import { useWarehouses } from "../../../features/warehouses/useWarehouses";
import { useRegisterRAABMutation } from "../../../features/rraab/useRegisterRAABMutation";

// (react-toastify) imports
import { toast } from "react-toastify";

// Custom types imports
import { RAABRegistrationType } from "../../../features/rraab/types";
import { ProductType } from "../../../features/products/types";
import { OperationCenterType } from "../../../features/operationCenters/types";

interface Props {
  operationCenters: OperationCenterType[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: ProductType;
  onFinish?: () => void;
}

function DrawerCreateRAAB({
  operationCenters,
  open,
  setOpen,
  product,
  onFinish,
}: Props) {
  const registerRAABMutation = useRegisterRAABMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `RAAB ${variables.data.codProduct}-${variables.data.idOperationCenter} creado`,
        closeOnClick: true,
        closeButton: true,
      });
      onFinish?.();
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data ||
          `Algo salio mal, al intentar crear RAAB ${variables.data.codProduct}-${variables.data.idOperationCenter}`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const methods = useForm<RAABRegistrationType>({
    defaultValues: {
      codProduct: product.codProduct,
      idOperationCenter: "",
      unitLoadWarehouse: "",
      inBatchLoadWarehouse: "",
    },
  });

  const {
    isLoading: warehousesLoading,
    isError: warehousesError,
    data: warehouses,
  } = useWarehouses();

  const isLoading = warehousesLoading;
  const isError = !warehouses || !operationCenters || warehousesError;

  const closeDrawer = () => {
    methods.reset();
    setOpen(false);
  };

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Creando RAAB...");
    registerRAABMutation.mutate({ data, notificationID });
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        {isLoading ? (
          <Loading text="Cargando..." />
        ) : isError ? (
          <ErrorLoading text="¡Hubo un error al cargar RAAB" />
        ) : (
          <>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
                  <button type="button" onClick={() => closeDrawer()}>
                    <i className="mr-4 text-xl bi bi-arrow-left"></i>
                  </button>{" "}
                  <b className="font-medium text-lg">Agregar RAAB</b>
                  <button
                    className="ml-auto button-primary inline-block w-[160px] text-sm"
                    type="submit"
                  >
                    Agregar
                  </button>
                </div>
                <EditRAABForm
                  operationCenters={operationCenters}
                  warehouses={warehouses}
                  action="create"
                />
              </form>
            </FormProvider>
          </>
        )}
      </div>
    </div>
  );
}

export default DrawerCreateRAAB;
