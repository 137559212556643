// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import RowOrder from "./RowOrder";
import HeaderGridOrders from "./HeaderGridOrders";
import EndMessageInfiniteList from "../shared/EndMessageInfiniteList";
import LoaderInfiniteList from "../shared/LoaderInfiniteList";

// Components (react-infinite-scroll-component) imports
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks (react-router-dom) imports
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hook";

// Custom types imports
import { PaginationOrderType } from "../../features/orders/types";

interface Props {
  orders: UseInfiniteQueryResult<PaginationOrderType, unknown>;
}

function InfiniteGridOrders({ orders }: Props) {
  const currentUser = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const data = orders.data?.pages?.flatMap((page) => page.items) || [];
  if (orders.isLoading) {
    return <Loading text="Cargando pedidos..." />;
  }

  if (orders.isError || !orders.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar pedidos" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay pedidos" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de pedidos</span>
        <div className="w-[30%] text-end">
          <b>{orders.data.pages[0].totalItems}</b>
        </div>
      </div>
      <InfiniteScroll
        dataLength={data.length || 0}
        next={() => orders.fetchNextPage()}
        hasMore={!!orders.hasNextPage}
        loader={<LoaderInfiniteList />}
        endMessage={<EndMessageInfiniteList message="Carga completada" />}
      >
        <>
          <HeaderGridOrders />
          <div className="divide-y">
            {data.map((order) => (
              <RowOrder
                key={order._id}
                order={order}
                onSelect={() =>
                  navigate(
                    `/${currentUser.user?._id}/pedidos/pedido/${order._id}`
                  )
                }
              />
            ))}
          </div>
        </>
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteGridOrders;
