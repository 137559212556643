// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  _id: string;
  notificationID: string | number;
}

export function useRemoveSellerMutation(
  onSuccessMutation?: (data: string, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function removeSeller(params: paramsType): Promise<string> {
    const response = await api.delete<string>(`sellers/remove/${params._id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: removeSeller,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Seller", variables._id]);
      queryClient.invalidateQueries(["Sellers"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
