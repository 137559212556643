// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { MunicipalityType } from "./types";

export function useMunicipalities(idDepartament?: string) {
  async function getMunicipalities(): Promise<MunicipalityType[]> {
    await timeout(500);
    const response = await api.get<MunicipalityType[]>(
      "departaments/municipalities",
      {
        idDepartament: idDepartament,
      }
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Municipalities", idDepartament],
    queryFn: () => getMunicipalities(),
    enabled: !!idDepartament,
  });
}
