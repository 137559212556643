// React imports
import { useState } from "react";

// Custom components imports
import SearchInput from "../../components/shared/fields/SearchInput";
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import OperationCentersList from "../../components/list/OperationCentersList";
import DrawerCreateOperationCenter from "../../components/forms/operation_center/DrawerCreateOperationCenter";
import DrawerEditOperationCenter from "../../components/forms/operation_center/DrawerEditOperationCenter";
import DropdownActions from "../../components/DropdownActions";

// Custom queries imports
import { useOperationCenters } from "../../features/operationCenters/useOperationCenters";
import { useExportOperationsCentersMutation } from "../../features/operationCenters/useExportOperationsCentersMutation";

// Custom types imports
import { OperationCenterType } from "../../features/operationCenters/types";
import { Action } from "../../components/DropdownActions/types";

// Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { downloadReport } from "../../helpers";

function OperationsCenter() {
  const [search, setSearch] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [visibleDrawer, setVisibleDrawer] = useState<
    "create" | "edit" | undefined
  >(undefined);

  const [selectedOperationCenter, setSelectedOperationCenter] = useState<
    OperationCenterType | undefined
  >(undefined);

  const operationCenters = useOperationCenters({ search: searchFilter });

  const exportOperationsCentersMutation = useExportOperationsCentersMutation(
    (data) => {
      downloadReport(data, "Lista de centros de operación.");
      toast.success("Exportación de centros de operación exitosos.");
    },
    (_error) => {
      toast.error("Algo salió mal al intentar exportar centros de operación.");
    }
  );

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setVisibleDrawer("create"),
    },
    {
      label: "Exportar",
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () =>
        exportOperationsCentersMutation.mutate({ notificationID: "" }),
    },
  ];

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Centros de Operación"
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <div className="w-full px-4 py-3 flex justify-between items-center">
          <SearchInput
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            onStopTyping={(value) => setSearchFilter(value)}
          />
        </div>
        <OperationCentersList
          onSelect={(warehouse) => {
            setVisibleDrawer("edit");
            setSelectedOperationCenter(warehouse);
          }}
          operationCenters={operationCenters}
        />

        {!!selectedOperationCenter && (
          <DrawerEditOperationCenter
            visible={visibleDrawer === "edit"}
            onClose={() => {
              setVisibleDrawer(undefined);
              setSelectedOperationCenter(undefined);
            }}
            operationCenter={selectedOperationCenter}
          />
        )}

        <DrawerCreateOperationCenter
          visible={visibleDrawer === "create"}
          onClose={() => {
            setVisibleDrawer(undefined);
          }}
        />
      </ContentWithToggle>
    </div>
  );
}

export default OperationsCenter;
