// React Components
import { Dispatch, SetStateAction } from "react";

// Custom componentes imports
import LinkWithoutSubmenu from "../../../LinkWithoutSubmenu";

// Components () imports
import { Link } from "react-router-dom";

// Third helpers imports
import { toast } from "react-toastify";

// Hook & Components Redux imports
import { useAppSelector, useAppDispatch } from "../../../../app/hook";
import {
  logout,
  changeIsLoadingUser,
} from "../../../../features/auth/authSlice";

interface Props {
  inMenu?: boolean;
  setIsOpenMenu?: Dispatch<SetStateAction<boolean>>;
}

function LinksCorporateEmployee({ inMenu, setIsOpenMenu }: Props) {
  const currentUser = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  if (!currentUser.user) return null;

  return (
    <>
      <LinkWithoutSubmenu
        text="Pedidos"
        to={`/${currentUser.user._id}/pedidos`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Productos"
        to={`/${currentUser.user._id}/productos`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Clientes"
        to={`/${currentUser.user._id}/clientes`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Vendedores"
        to={`/${currentUser.user._id}/vendedores`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Bodegas"
        to={`/${currentUser.user._id}/bodegas`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Backorders"
        to={`/${currentUser.user._id}/backorders`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Centros de Operación"
        to={`/${currentUser.user._id}/centros`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Condiciones de Venta"
        to={`/${currentUser.user._id}/condiciones`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Perfil"
        to={`/${currentUser.user._id}/perfil`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <Link
        className="button-secondary p-4 w-full flex justify-between rounded-none"
        to="/ingresar"
        onClick={() => {
          dispatch(changeIsLoadingUser(true));
          dispatch(logout());
          inMenu && !!setIsOpenMenu && setIsOpenMenu(false);
          toast.info("Cierre de sesión satisfactorio");
        }}
      >
        Cerrar Sesión
        <i className="bi bi-chevron-right"></i>
      </Link>
    </>
  );
}
export default LinksCorporateEmployee;
