import { CustomerSubDocumentType } from "../customers/types";
import { OperationCenterType } from "../operationCenters/types";
import { BranchType } from "../branches/types";
import { SellerType } from "../sellers/types";
import { BackorderType } from "../backorders/types";
import { SaleConditionType } from "../saleConditions/types";
import { ItemType } from "../items/types";
import { ProductType } from "../products/types";

// Types imports
export interface FiltersOrderType {
  idSeller?: string;
  idOperationCenter?: string;
  search?: string;
  status?: string;
  dateFrom?: string;
  dateUntil?: string;
  minimumAmount?: string;
  maximumAmount?: string;
}

export type SyncStatusType = "done" | "pending" | "failed";

export enum SyncStatusDescription {
  done = "Sincronización exitosa",
  pending = "Sincronización pendiente",
  failed = "Sincronización fallida",
}
export enum SyncStatusColor {
  done = "#84CC16",
  pending = "#EAB308",
  failed = "#E11D48",
}

export type OrderStatusType = "draft" | "pending" | "inSIESA";

export enum OrderStatusDescription {
  draft = "En Elaboración",
  pending = "En Revisión",
  inSIESA = "Entregado",
}

export enum OrderStatusColor {
  draft = "#929292",
  pending = "#EAB308",
  inSIESA = "#3B82F6",
}

export interface OrderRegistrationType {
  numDocto: string;
  operationCenter: OperationCenterType;
  status: OrderStatusType;
  client: CustomerSubDocumentType;
  branch: BranchType;
  seller: SellerType;
  reference: string;
  backorder: BackorderType;
  saleCondition: SaleConditionType;
  deliverDate: string;
  items: ItemType[];
  iva: number;
  subtotal: number;
  total: number;
  syncStatus?: SyncStatusType;
  note: string;
  localID?: string;
}

export interface OrderType extends OrderRegistrationType {
  _id: string;
  idOrder: number;
  createdAt: string;
  updatedAt: string;
}

export interface PaginationOrderType {
  items: OrderType[];
  totalItems: number;
  currentPage: number;
}

export interface QueryPaginationOrderType extends FiltersOrderType {
  size?: string;
  page?: string;
}

export interface ProductPagination {
  items: ProductType[];
  totalItems: number;
  currentPage: number;
}

export const SIZE = 20;

export interface TotalsOrder {
  totals: number;
  preparations: number;
  pending: number;
  inSIESA: number;
  sales: number;
}

export const orderRegistrationDefault: any = {
  numDocto: "",
  operationCenter: {},
  status: "pending",
  client: {},
  branch: {},
  seller: {},
  reference: "",
  backorder: {},
  saleCondition: {},
  deliverDate: "",
  items: [],
  iva: 0,
  subtotal: 0,
  total: 0,
  note: "",
};
