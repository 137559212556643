// React imports
import { useState, useEffect } from "react";

// Custom componets imports
import LinksCorporateEmployee from "./shared/navigations/links/LinksCorporateEmployee";
import LinksCorporateAdmin from "./shared/navigations/links/LinksCorporateAdmin";

// Hook & Components Redux imports
import { useAppSelector } from "../app/hook";

// Custom types imports
import { ROLES } from "../features/users/types";

function Sidebar() {
  const currentUser = useAppSelector((state) => state.auth);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const toggleShadowOfNavigationTop = () => {};
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) return setIsScrolling(true);
      setIsScrolling(false);
    });
    return () =>
      window.removeEventListener("scroll", toggleShadowOfNavigationTop);
  }, []);

  if (!currentUser.user) return null;

  return (
    <div
      className={`divide-y hidden xl:block fixed top-[65px] h-[calc(100vh-65px)] left-0 z-10 w-full max-w-[200px] bg-white rounded-br-[18px] ${
        isScrolling ? "shadow-custom-lg" : "border"
      }`}
    >
      {[ROLES.CorporateEmployee].includes(currentUser.user.role) && (
        <LinksCorporateEmployee />
      )}

      {[ROLES.CorporateAdmin].includes(currentUser.user.role) && (
        <LinksCorporateAdmin />
      )}
    </div>
  );
}

export default Sidebar;
