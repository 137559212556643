// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import SaleConditionCard from "../cards/SaleConditionCard";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";

// Custom types imports
import { SaleConditionType } from "../../features/saleConditions/types";

interface Props {
  saleConditions: UseQueryResult<SaleConditionType[], unknown>;
  onSelect: (saleConditions: SaleConditionType) => void;
}

function SaleConditionsList({ saleConditions, onSelect }: Props) {
  if (saleConditions.isLoading) {
    return <Loading text="Cargando centros de operación..." />;
  }

  if (saleConditions.isError || !saleConditions.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar condiciones de venta" />
        </div>
      </div>
    );
  }

  if (saleConditions.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay condiciones de venta" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de condiciones de venta</span>
        <div className="w-[30%] text-end">
          <b>{saleConditions.data.length}</b>
        </div>
      </div>
      <div className="divide-y">
        {saleConditions.data.map((saleCondition) => (
          <SaleConditionCard
            saleCondition={saleCondition}
            key={saleCondition._id}
            onSelect={(saleCondition) => onSelect(saleCondition)}
          />
        ))}
      </div>
    </div>
  );
}

export default SaleConditionsList;
