import { create } from "apisauce";
import { API_URI } from "../config";

// Enviroment Variables
const api = create({
  baseURL: API_URI,
  headers: {
    // "Content-Type": "application/json; charset=utf-8",
  },
});

export default api;
