// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom types imports
import { BranchType } from "../../../features/branches/types";

// Hooks & Components (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom queries imports
import { useSaleConditions } from "../../../features/saleConditions/useSaleConditions";
import { useBackorders } from "../../../features/backorders/useBackorders";
import { useSellers } from "../../../features/sellers/useSellers";
import { useMunicipalities } from "../../../features/departaments/useMunicipalities";
import { useDepartaments } from "../../../features/departaments/useDepartaments";

function EditBranchForm() {
  const methods = useFormContext<BranchType>();
  const departament = methods.watch("dpto");
  const saleConditions = useSaleConditions();
  const backorders = useBackorders();
  const sellers = useSellers();
  const departaments = useDepartaments();
  const departamentSelected = departaments.data
    ? departaments.data.find((d) => d.name === departament)
    : undefined;
  const municipalities = useMunicipalities(
    departamentSelected?.idDepartament
      ? String(departamentSelected.idDepartament)
      : undefined
  );

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 md:grid-cols-10 gap-x-4 gap-y-2">
      <CustomLabel
        label="ID"
        id="idBranch"
        required={true}
        className="text-sm sm:col-span-1"
        name="idBranch"
      >
        <input
          placeholder="ID"
          className={`input-base ${
            methods.formState.errors.idBranch ? "input-error" : ""
          } ${
            !methods.formState.errors.idBranch && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("idBranch", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombre"
        id="name"
        required={true}
        className="text-sm sm:col-span-5"
        name="name"
      >
        <input
          placeholder="Nombre"
          className={`input-base ${
            methods.formState.errors.description ? "input-error" : ""
          } ${
            !methods.formState.errors.description &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("description", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Condición de Venta"
        id="saleCondition"
        required={true}
        className="text-sm sm:col-span-4"
        name="saleCondition._id"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled={saleConditions.isLoading || !saleConditions.data}
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.saleCondition ? "input-error" : ""
            } ${
              !methods.formState.errors.saleCondition &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            value={methods.getValues("saleCondition")?._id}
            onChange={(e) => {
              if (saleConditions.data) {
                const saleCondition = saleConditions.data.find(
                  (saleCondition) => saleCondition?._id === e.target.value
                );
                if (saleCondition) {
                  methods.setValue("saleCondition", saleCondition);
                }
              }
            }}
          >
            <option value="">Seleccionar condición de venta</option>
            {saleConditions.data?.map((saleCondition) => (
              <option value={saleCondition?._id} key={saleCondition?._id}>
                {saleCondition.idSaleCondition} {saleCondition.name}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Vendedor"
        id="seller"
        required={true}
        className="text-sm sm:col-span-6"
        name="seller"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled={sellers.isLoading || !sellers.data}
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.seller ? "input-error" : ""
            } ${
              !methods.formState.errors.seller && methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            value={methods.watch("seller")?._id}
            onChange={(e) => {
              if (sellers.data) {
                const seller = sellers.data.find(
                  (seller) => seller?._id === e.target.value
                );
                if (seller) {
                  methods.setValue("seller", seller);
                }
              }
            }}
          >
            <option value="">Seleccionar vendedor</option>
            {sellers.data?.map((seller) => (
              <option value={seller?._id} key={seller?._id}>
                {seller.idSeller} {seller.name}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Backorder"
        id="backorder._id"
        required={true}
        className="text-sm sm:col-span-4"
        name="backorder._id"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled={backorders.isLoading || !backorders.data}
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.backorder ? "input-error" : ""
            } ${
              !methods.formState.errors.backorder &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            value={methods.watch("backorder")?._id}
            onChange={(e) => {
              if (backorders.data) {
                const backorder = backorders.data.find(
                  (backorder) => backorder?._id === e.target.value
                );
                if (backorder) {
                  methods.setValue("backorder", backorder);
                }
              }
            }}
          >
            <option value="">Seleccionar backorder</option>
            {backorders.data?.map((backorder) => (
              <option value={backorder?._id} key={backorder?._id}>
                {backorder.idBackorder} {backorder.name}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Departamento"
        id="departament"
        required={true}
        className="text-sm sm:col-span-3"
        name="departament"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled={departaments.isLoading || !departaments.data}
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.dpto ? "input-error" : ""
            } ${
              !methods.formState.errors.dpto && methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            value={methods.watch("dpto")}
            onChange={(e) => {
              if (departaments.data) {
                const departament = departaments.data.find(
                  (departament) => departament.name === e.target.value
                );
                if (departament) {
                  methods.setValue("dpto", departament.name);
                }
              }
            }}
          >
            <option value="">Seleccionar departamento</option>
            {departaments.data?.map((departament) => (
              <option value={departament.name} key={departament?._id}>
                {departament.name}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Ciudad"
        id="city"
        required={true}
        className="text-sm sm:col-span-3"
        name="city"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled={municipalities.isLoading || !municipalities.data}
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.city ? "input-error" : ""
            } ${
              !methods.formState.errors.city && methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            value={methods.watch("city")}
            onChange={(e) => {
              if (municipalities.data) {
                const city = municipalities.data.find(
                  (city) => city.name === e.target.value
                );
                if (city) {
                  methods.setValue("city", city.name);
                }
              }
            }}
          >
            <option value="">Seleccionar Ciudad</option>
            {municipalities.data?.map((municipaly) => (
              <option value={municipaly.name} key={municipaly?._id}>
                {municipaly.name}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Barrio"
        id="neighborhood"
        required={true}
        className="text-sm sm:col-span-4"
        name="neighborhood"
      >
        <input
          placeholder="Barrio"
          className={`input-base ${
            methods.formState.errors.neighborhood ? "input-error" : ""
          } ${
            !methods.formState.errors.description &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("neighborhood", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Dirección"
        id="adress"
        required={true}
        className="text-sm sm:col-span-10"
        name="adress"
      >
        <input
          placeholder="Dirección"
          className={`input-base ${
            methods.formState.errors.adress ? "input-error" : ""
          } ${
            !methods.formState.errors.adress && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("adress", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>
    </div>
  );
}

export default EditBranchForm;
