// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { UserType } from "./types";

export function useUser(_id?: string) {
  async function getUser(_id?: string): Promise<UserType> {
    await timeout(500);
    const response = await api.get<UserType>(`users/user/${_id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["User", _id],
    queryFn: () => getUser(_id),
    enabled: !!_id,
  });
}
