// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { ProductType } from "./types";

export function useProduct(_id?: string) {
  async function getProduct(_id?: string): Promise<ProductType> {
    await timeout(500);
    const response = await api.get<ProductType>(`products/product/${_id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Product", _id],
    queryFn: () => getProduct(_id),
    enabled: !!_id,
  });
}
