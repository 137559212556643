// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

// Custom components imports
import EditWarehouseForm from "./EditWarehouseForm";
import DeleteWarehouse from "./DeleteWarehouse";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom queries imports
import { useUpdateWarehouseMutation } from "../../../features/warehouses/useUpdateWarehouseMutation";
import { useRemoveWarehouseMutation } from "../../../features/warehouses/useRemoveWarehouseMutation";

//Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Custom types imports
import { WarehouseType } from "../../../features/warehouses/types";

interface Props {
  visible: boolean;
  onClose: () => void;
  warehouse: WarehouseType;
}

function DrawerEditWarehouse({ visible, onClose, warehouse }: Props) {
  const queryClient = useQueryClient();

  const methods = useForm<WarehouseType>({
    defaultValues: warehouse,
  });

  const closeDrawer = () => {
    methods.reset();
    onClose();
  };

  const removeWarehouseMutation = useRemoveWarehouseMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Bodega eliminada`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Warehouses"]);
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar eliminar bodega",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const updateWarehouseMutation = useUpdateWarehouseMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Bodega actualizada`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Warehouses"]);
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar bodega",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Actualizando bodega...");

    updateWarehouseMutation.mutate({
      data: data,
      notificationID,
    });
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        visible ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="mr-4 text-xl bi bi-arrow-left"></i>
              </button>{" "}
              <b className="font-medium text-lg">{warehouse.name}</b>
              <button
                className={`${
                  methods.formState.isDirty
                    ? ""
                    : "opacity-50 pointer-events-none"
                } ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm`}
                type="submit"
              >
                Actualizar
              </button>
            </div>
            <EditWarehouseForm />
          </form>
        </FormProvider>
        <DeleteWarehouse
          onDelete={() => {
            confirmChange(
              "¿Estas seguro de eliminar esta bodega?",
              "Al eliminar esta bodega, el cambio sera permanente",
              () => {
                let notificationID = toast.loading("Eliminando bodega...");
                removeWarehouseMutation.mutate({
                  _id: warehouse._id,
                  notificationID,
                });
              }
            );
          }}
        />
      </div>
    </div>
  );
}

export default DrawerEditWarehouse;
