export default function EmptyCapture() {
  return (
    <div className="w-full flex flex-col items-center mt-4 px-8">
      <i className="bi bi-search text-[58px]"></i>
      <b className="text-lg mt-2 font-bold">No hay producto capturado</b>
      <p className="mt-sm text-center mt-2">
        Si aún no has capturado un producto puedes buscarlo en el campo de
        arriba
      </p>
    </div>
  );
}
