// State Redux imports
import { useAppSelector } from "../../app/hook";

// Custom components imports
import Login from "../../pages/Login";
import Orders from "../../pages/Orders";

export default function SwitchRoute() {
  const currentUser = useAppSelector((state) => state.auth);

  if (currentUser.isAuthenticated) {
    return <Orders />
  }
  return <Login />;
}
