// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { SellerIsRemovableType } from "./types";

export function useSellerIsRemovable(_id?: string) {
  async function getSellerIsRemovable(_id?: string): Promise<SellerIsRemovableType> {
    await timeout(1000);
    const response = await api.get<SellerIsRemovableType>(`sellers/seller/isRemovable/${_id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["SellerIsRemovable", _id],
    queryFn: () => getSellerIsRemovable(_id),
    refetchOnWindowFocus: false,
    enabled: !!_id,
  });
}
