// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { SaleConditionRegistrationType, SaleConditionType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: SaleConditionRegistrationType;
  notificationID: string | number;
}

export function useRegisterSaleConditionMutation(
  onSuccessMutation?: (data: SaleConditionType, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function registerSaleCondition(
    params: paramsType
  ): Promise<SaleConditionType> {
    const response = await api.post<SaleConditionType>(
      "saleConditions/register",
      params.data
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: registerSaleCondition,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["SaleConditions"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
