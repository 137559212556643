// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { UserRegistrationType, UserType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: UserRegistrationType;
  notificationID: string | number;
}

export function useRegisterUserMutation(
  onSuccessMutation?: (data: UserType, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<string>, variables: paramsType) => void
) {
  const queryClient = useQueryClient();

  async function registerUser(params: paramsType): Promise<UserType> {
    const response = await api.post<UserType>(
      "users/register",
      params.data
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: registerUser,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Users"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
