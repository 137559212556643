// React imports
import React, { useState } from "react";

// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import BackordersList from "../../components/list/BackordersList";
import DrawerCreateBackorder from "../../components/forms/backorder/DrawerCreateBackorder";
import DrawerEditBackorder from "../../components/forms/backorder/DrawerEditBackorder";
import DropdownActions from "../../components/DropdownActions";
import SearchInput from "../../components/shared/fields/SearchInput";

// Custom queries imports
import { useBackorders } from "../../features/backorders/useBackorders";
import { useExportBackordersMutation } from "../../features/backorders/useExportBackordersMutation";

// Custom types imports
import { BackorderType } from "../../features/backorders/types";
import { Action } from "../../components/DropdownActions/types";

// Custom helpers imports
import { downloadReport } from "../../helpers";

// (react-toastify) imports
import { toast } from "react-toastify";

function Backorders() {
  const searchRef = React.useRef<any>(null);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [visibleDrawer, setVisibleDrawer] = useState<
    "create" | "edit" | undefined
  >(undefined);

  const [selectedBackorder, setSelectedBackorder] = useState<
    BackorderType | undefined
  >(undefined);

  const backorders = useBackorders({ search: searchFilter });

  const exportBackordersMutation = useExportBackordersMutation(
    (data, variables) => {
      downloadReport(data, "Lista de Backorders ");
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Exportación de backorders exitosa`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Algo salio mal al intentar exportar backorders`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setVisibleDrawer("create"),
    },
    {
      label: "Exportar",
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () => {
        const notificationID = toast.loading("Exportando backorders...");
        exportBackordersMutation.mutate({ notificationID });
      },
    },
  ];

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Backorders"
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <div className="w-full px-4 py-3 flex justify-between items-center">
          <SearchInput
            searchRef={searchRef}
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            onStopTyping={(value) => setSearchFilter(value)}
          />
        </div>
        <BackordersList
          onSelect={(backorder) => {
            setVisibleDrawer("edit");
            setSelectedBackorder(backorder);
          }}
          backorders={backorders}
        />

        {!!selectedBackorder && (
          <DrawerEditBackorder
            visible={visibleDrawer === "edit"}
            onClose={() => {
              setVisibleDrawer(undefined);
              setSelectedBackorder(undefined);
            }}
            backorder={selectedBackorder}
          />
        )}

        <DrawerCreateBackorder
          visible={visibleDrawer === "create"}
          onClose={() => {
            setVisibleDrawer(undefined);
          }}
        />
      </ContentWithToggle>
    </div>
  );
}

export default Backorders;
