// React imports
import { useState } from "react";

// Custom components imports
import SearchInput from "../../components/shared/fields/SearchInput";
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import WarehousesList from "../../components/list/WarehousesList";
import DrawerEditWarehouse from "../../components/forms/warehouse/DrawerEditWarehouse";
import DrawerCreateWarehouse from "../../components/forms/warehouse/DrawerCreateWarehouse";
import DropdownActions from "../../components/DropdownActions";

// Custom queries imports
import { useWarehouses } from "../../features/warehouses/useWarehouses";
import { useExportWarehouseMutation } from "../../features/warehouses/useExportWarehouseMutation";

// Custom types imports
import { WarehouseType } from "../../features/warehouses/types";
import { Action } from "../../components/DropdownActions/types";

// Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { downloadReport } from "../../helpers";

function Warehouses() {
  const [search, setSearch] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [visibleDrawer, setVisibleDrawer] = useState<
    "create" | "edit" | undefined
  >(undefined);

  const [selectedWarehouse, setSelectedWarehouse] = useState<
    WarehouseType | undefined
  >(undefined);

  const warehouses = useWarehouses({ search: searchFilter });

  const exportWarehouseMutation = useExportWarehouseMutation(
    (data, variables) => {
      downloadReport(data, "Lista de Bodegas ");
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Exportación de bodegas exitosa`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Algo salio mal al intentar exportar bodegas`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setVisibleDrawer("create"),
    },
    {
      label: "Exportar",
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () =>
        exportWarehouseMutation.mutate({
          notificationID: "",
        }),
    },
  ];

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Bodegas"
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <div className="w-full px-4 py-3 flex justify-between items-center">
          <SearchInput
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            onStopTyping={(value) => setSearchFilter(value)}
          />
        </div>
        <WarehousesList
          warehouses={warehouses}
          onSelect={(saleCondition) => {
            setVisibleDrawer("edit");
            setSelectedWarehouse(saleCondition);
          }}
        />
        {!!selectedWarehouse && (
          <DrawerEditWarehouse
            visible={visibleDrawer === "edit"}
            onClose={() => {
              setVisibleDrawer(undefined);
              setSelectedWarehouse(undefined);
            }}
            warehouse={selectedWarehouse}
          />
        )}
        <DrawerCreateWarehouse
          visible={visibleDrawer === "create"}
          onClose={() => {
            setVisibleDrawer(undefined);
          }}
        />
      </ContentWithToggle>
    </div>
  );
}

export default Warehouses;
