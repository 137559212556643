function HeaderGridRRAAB() {
  return (
    <div className="hidden md:grid bg-gray-100 text-gray-700 divide-x border-t w-full h-[40px] grid-cols-[2fr_1fr_2fr_2fr_1fr]">
      <div className="px-2 flex items-center">
        <b className="text-sm">Codigo prod</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">CO</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">BD Unidades</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">BD en lote</b>
      </div>
      <div className="px-2 flex items-center"></div>
    </div>
  );
}
export default HeaderGridRRAAB;
