import Loading from "../../shared/Loading";
import { OperationCenterIsRemovableType } from "../../../features/operationCenters/types";
import { useAppSelector } from "../../../app/hook";

interface Props {
  onDelete: () => void;
  operationCenterIsRemovable: OperationCenterIsRemovableType;
  refetch: () => void;
  isFetching: boolean;
}

function DeleteOperationCenter({
  onDelete,
  operationCenterIsRemovable,
  refetch,
  isFetching,
}: Props) {
  const currentUser = useAppSelector((state) => state.auth);
  if (operationCenterIsRemovable.isRemovable) {
    return (
      <div className="w-full flex flex-wrap sm:flex-nowrap sm:justify-center bg-red-primary text-white py-4 px-4">
        <i className="text-lg bi bi-exclamation-circle-fill mr-4"></i>
        <span className="w-[calc(100%-134px)]">
          Puedes eliminar este centro de operación de forma permanente
        </span>
        <button
          type="button"
          onClick={() => onDelete()}
          tabIndex={-1}
          className="mt-2 bg-white text-black rounded-md px-5 py-1 sm:ml-4 sm:mt-0 font-bold"
        >
          Eliminar
        </button>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-wrap sm:flex-nowrap sm:justify-center bg-yellow-500  py-4 px-4">
      {isFetching ? (
        <Loading text="Revalidando..." />
      ) : (
        <>
          <i className="text-xl bi bi-exclamation-circle-fill mr-4"></i>
          <div className="flex-1">
            <h3 className="font-bold">
              Este centro de operación no se puede eliminar ni modificar
            </h3>

            {operationCenterIsRemovable?.sellers &&
              operationCenterIsRemovable?.sellers.length > 0 && (
                <div className="mt-2">
                  <p>Centro de operación asignado a vendedores: </p>
                  <ul className="pl-8 list-decimal">
                    {operationCenterIsRemovable?.sellers.map(
                      (seller, index) => (
                        <li key={index}>
                          <a
                            className="text-blue-800 underline hover:font-semibold"
                            href={`/${currentUser.user?._id}/vendedores/vendedor/${seller._id}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {seller.name} #{seller.idSeller}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}

            {operationCenterIsRemovable?.orders &&
              operationCenterIsRemovable?.orders.length > 0 && (
                <div className="mt-2">
                  <p>Centro de operación asignado a pedidos en revisión: </p>
                  <ul className="pl-8 list-decimal">
                    {operationCenterIsRemovable?.orders.map((order, index) => (
                      <li key={index}>
                        <a
                          className="text-blue-800 underline hover:font-semibold"
                          href={`/${currentUser.user?._id}/pedidos/pedido/${order._id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Pedido #{order.idOrder}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            <button
              type="button"
              onClick={refetch}
              tabIndex={-1}
              className="mt-2 bg-white text-black rounded-md px-5 py-1 font-bold text-sm"
            >
              Reintentar validación
            </button>
          </div>
        </>
      )}
    </div>
  );
}
export default DeleteOperationCenter;
