// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom types imports
import { RAABType } from "../../../features/rraab/types";
import { OperationCenterType } from "../../../features/operationCenters/types";
import { WarehouseType } from "../../../features/warehouses/types";

// Hooks & Components (react-hook-form) imports
import { useFormContext } from "react-hook-form";

interface Props {
  action: "edit" | "create";
  operationCenters: OperationCenterType[];
  warehouses: WarehouseType[];
}

function EditRAABForm({ action, operationCenters, warehouses }: Props) {
  const methods = useFormContext<RAABType>();
  const raab = methods.watch();

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
      <CustomLabel
        label="Codigo"
        id="codProduct"
        required={true}
        className="text-sm sm:col-span-1"
        name="codProduct"
      >
        <input
          placeholder="Codigo"
          className="input-base"
          value={`#${raab.codProduct}`}
          disabled
        />
      </CustomLabel>
      <CustomLabel
        label="Centro de operación"
        id="idOperationCenter"
        required={true}
        className="text-sm sm:col-span-3"
        name="idOperationCenter"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled={action === "edit"}
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.idOperationCenter ? "input-error" : ""
            } ${
              !methods.formState.errors.idOperationCenter &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            value={raab.idOperationCenter}
            onChange={(e) => {
              if (operationCenters) {
                const operationCenter = operationCenters?.find(
                  (operationCenter) =>
                    operationCenter?.idOperationCenter === e.target.value
                );
                if (operationCenter) {
                  methods.setValue(
                    "idOperationCenter",
                    operationCenter.idOperationCenter
                  );
                }
              }
            }}
          >
            <option value="">Seleccionar Centro de Operación</option>
            {operationCenters?.map((operationCenter) => (
              <option
                value={operationCenter?.idOperationCenter}
                key={operationCenter?.idOperationCenter}
              >
                {operationCenter.idOperationCenter} {operationCenter.name}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>
      <CustomLabel
        label="BD unidades"
        id="unitLoadWarehouse"
        required={true}
        className="text-sm sm:col-span-2"
        name="unitLoadWarehouse"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.unitLoadWarehouse ? "input-error" : ""
            } ${
              !methods.formState.errors.unitLoadWarehouse &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            value={raab.unitLoadWarehouse}
            onChange={(e) => {
              if (warehouses) {
                const warehouse = warehouses?.find(
                  (warehouse) => warehouse?.idWarehouse === e.target.value
                );
                if (warehouse) {
                  methods.setValue("unitLoadWarehouse", warehouse.idWarehouse);
                }
              }
            }}
          >
            <option value="">Seleccionar BD unidades</option>
            {warehouses?.map((warehouse) => (
              <option
                value={warehouse?.idWarehouse}
                key={warehouse?.idWarehouse}
              >
                {warehouse.idWarehouse}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>
      <CustomLabel
        label="BD en lote"
        id="inBatchLoadWarehouse"
        required={true}
        className="text-sm sm:col-span-2"
        name="inBatchLoadWarehouse"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.inBatchLoadWarehouse ? "input-error" : ""
            } ${
              !methods.formState.errors.inBatchLoadWarehouse &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            value={raab.inBatchLoadWarehouse}
            onChange={(e) => {
              if (warehouses) {
                const warehouse = warehouses?.find(
                  (warehouse) => warehouse?.idWarehouse === e.target.value
                );
                if (warehouse) {
                  methods.setValue(
                    "inBatchLoadWarehouse",
                    warehouse.idWarehouse
                  );
                }
              }
            }}
          >
            <option value="">Seleccionar BD en lote</option>
            {warehouses?.map((warehouse) => (
              <option
                value={warehouse?.idWarehouse}
                key={warehouse?.idWarehouse}
              >
                {warehouse.idWarehouse}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>
    </div>
  );
}

export default EditRAABForm;
