// Types imports
import { BranchType, BranchRegistrationType } from "../branches/types";
import { SimpleOrderType } from "../common/types";

export interface FiltersClientType {
  search?: string;
  idSeller?: string;
  idBackorder?: string;
  idSaleCondition?: string;
  allCustomers?: string;
}

export interface CustomerRegistrationType {
  idClient: number;
  nit: string;
  name: string;
  branches: BranchRegistrationType[];
}

export interface CustomerUpdateType {
  _id: string;
  idClient: number;
  nit: string;
  name: string;
  branches: BranchRegistrationType[];
}

export interface CustomerType {
  _id: string;
  idClient: number;
  nit: string;
  name: string;
  branches: BranchType[];
}

export interface CustomerSubDocumentType {
  _id: string;
  idClient: number;
  nit: string;
  name: string;
}

export interface QueryPaginationCustomerType extends FiltersClientType {
  size: string;
  page: string;
}

export interface CustomerPagination {
  items: CustomerType[];
  totalItems: number;
  currentPage: number;
}

export const SIZE = 10;

export interface CustomerIsRemovableType {
  isRemovable: boolean;
  orders?: SimpleOrderType[];
}
