// React imports
import React from "react";

// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

// Hooks (react-router-dom) imports
import { useNavigate, useParams } from "react-router-dom";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import DataUserForm from "./DataUserForm";
import DropdownActions from "../../DropdownActions";
import Tabs from "../../shared/Tabs";

// Custom queries imports
import { useUpdateUserMutation } from "../../../features/users/useUpdateUserMutation";
import { useRemoveUserMutation } from "../../../features/users/useRemoveUserMutation";

// Custom types imports
import { UserType } from "../../../features/users/types";
import { Tab } from "../../shared/Tabs";
import { Action } from "../../DropdownActions/types";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";
import { SellerType } from "../../../features/sellers/types";

interface Props {
  user: UserType;
  sellers: SellerType[];
}

function EditUserForm({ user, sellers }: Props) {
  const submitButtonRef = React.useRef<any>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<UserType>({ defaultValues: user });
  const tabs: Tab[] = [
    {
      label: "General",
      url: `/${params.idUser}/usuarios/usuario/${params.id}/general`,
    },
    {
      label: "Actualizar Contraseña",
      url: `/${params.idUser}/usuarios/usuario/${params.id}/updatePassword`,
    },
  ];

  const updateUserMutation = useUpdateUserMutation(
    (_data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Usuario actualizado`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar el usuario",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const removeUserMutation = useRemoveUserMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Usuario eliminado`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Users"]);
      navigate(`/${currentUser?.user?._id}/usuarios`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar eliminar el usuario",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmitStore = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Actualizando usuario...");
    updateUserMutation.mutate({ data, notificationID });
  });

  const ACTIONS: Action[] = [
    {
      label: "Actualizar",
      icon: <i className="bi bi-arrow-repeat text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Eliminar",
      icon: <i className="bi bi-trash3 text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estas seguro de eliminar este usuario?",
          "Al eliminar este usuario, el cambio sera permanente",
          () => {
            let notificationID = toast.loading("Eliminando usuario...");
            removeUserMutation.mutate({
              _id: user._id,
              notificationID,
            });
          }
        );
      },
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () =>
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser.user?._id}/usuarios`);
          }
        ),
    },
  ];

  return (
    <ContentWithToggle
      title={user.name}
      onBackButton={() => navigate(`/${currentUser?.user?._id}/usuarios`)}
      isToogle={false}
      actionButton={<DropdownActions actions={ACTIONS} />}
    >
      <Tabs tabs={tabs} />
      <FormProvider {...methods}>
        <form onSubmit={onSubmitStore}>
          <DataUserForm sellers={sellers} />
          <input type="submit" className="hidden" ref={submitButtonRef} />
        </form>
      </FormProvider>
    </ContentWithToggle>
  );
}
export default EditUserForm;
