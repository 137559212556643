// React imports
import React, { useState, useEffect } from "react";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import DropdownActions from "../../../components/DropdownActions";
import ListItems from "../../../components/forms/order/ListItems";
import DrawerAssignClient from "../../../components/forms/order/DrawerAssignClient";
import DrawerEditItem from "../../../components/forms/order/DrawerEditItem";
import DrawerCreateItem from "../../../components/forms/order/DrawerCreateItem";
import DataOrderForm from "../../../components/forms/order/DataOrderForm";
import ContentWithToggle from "../../../components/shared/ContentWithToggle";

// Custom queries imports
import { useRegisterOrderMutation } from "../../../features/orders/useRegisterOrderMutation";

// Custom types imports
import { OrderRegistrationType } from "../../../features/orders/types";
import { ItemType } from "../../../features/items/types";
import { orderRegistrationDefault } from "../../../features/orders/types";
import { CustomerType } from "../../../features/customers/types";
import { Action } from "../../../components/DropdownActions/types";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom helpers
import { getTotalsItems } from "../../../helpers";
import { confirmChange } from "../../../features/auth/helpers";

function CreateOrder() {
  const submitButtonRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<OrderRegistrationType>({
    defaultValues: orderRegistrationDefault,
  });

  const [items, setItems] = useState<ItemType[]>([]);
  const [openDrawerCreateItem, setOpenDrawerCreateItem] =
    useState<boolean>(false);
  const [openDrawerAssignClient, setOpenDrawerAssignClient] =
    useState<boolean>(false);
  const [openDrawerEditItem, setOpenDrawerEditItem] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ItemType | undefined>(
    undefined
  );

  const registerOrdertMutation = useRegisterOrderMutation(
    (data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Pedido creado`,
        closeOnClick: true,
        closeButton: true,
      });
      navigate(`/${currentUser?.user?._id}/pedidos/pedido/${data._id}`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar crear el pedido",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmitOrder = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Creando pedido...");
    registerOrdertMutation.mutate({
      data: { ...data, items },
      notificationID,
    });
  });

  useEffect(() => {
    if (items.length === 0) {
      methods.watch("iva", 0);
      methods.watch("subtotal", 0);
      methods.watch("total", 0);
    } else {
      const { total, subtotal, iva } = getTotalsItems(items);
      methods.setValue("iva", iva);
      methods.setValue("subtotal", subtotal);
      methods.setValue("total", total);
    }
  }, [items, methods]);

  useEffect(() => {
    if (openDrawerEditItem === false) {
      setSelectedItem(undefined);
    }
  }, [openDrawerEditItem, setSelectedItem]);

  const onAdd = (client: CustomerType) => {
    const firstBranch = client.branches[0];
    methods.setValue("client", {
      _id: client._id,
      idClient: client.idClient,
      nit: client.nit,
      name: client.name,
    });
    methods.setValue("branch", firstBranch);
    methods.setValue("backorder", firstBranch.backorder);
    methods.setValue("saleCondition", firstBranch.saleCondition);
    methods.setValue("seller", firstBranch.seller);
  };

  const onUpdate = (data: ItemType) => {
    setItems((currentItems) => {
      const newItems = currentItems.map((item) => {
        if (
          item.warehouse._id === data.warehouse._id &&
          item.extension._id === data.extension._id
        ) {
          return data;
        }
        return item;
      });
      return newItems;
    });
  };

  const onDelete = () => {
    if (selectedItem) {
      setItems((currentItems) =>
        currentItems.filter(
          (item) =>
            !(
              item.extension._id === selectedItem.extension._id &&
              item.warehouse._id === selectedItem.warehouse._id
            )
        )
      );
    }
  };

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-arrow-repeat text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Agregar Item",
      hidden: !methods.watch("operationCenter")?._id,
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setOpenDrawerCreateItem(true),
    },
    {
      label: "Asignar Cliente",
      icon: <i className="bi bi-person-check text-xl mr-2"></i>,
      onAction: () => setOpenDrawerAssignClient(true),
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser.user?._id}/pedidos`);
          }
        );
      },
    },
  ];

  return (
    <div className="divide-y w-full sm:w-[90%] sm:max-w-[1300px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <FormProvider {...methods}>
        <form onSubmit={onSubmitOrder} className="relative divide-y">
          <ContentWithToggle
            title="Crear Pedido"
            onBackButton={() => navigate(`/${currentUser?.user?._id}/pedidos`)}
            isToogle={false}
            actionButton={<DropdownActions actions={ACTIONS} />}
          >
            <DataOrderForm />
          </ContentWithToggle>
          <ListItems
            onSelect={(item) => {
              setOpenDrawerEditItem(true);
              setSelectedItem(item);
            }}
            items={items}
          />
          <input type="submit" className="hidden" ref={submitButtonRef} />
        </form>
      </FormProvider>

      {!!selectedItem && (
        <DrawerEditItem
          status={methods.watch("status")}
          open={openDrawerEditItem}
          setOpen={setOpenDrawerEditItem}
          item={selectedItem}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      )}
      <DrawerCreateItem
        order={methods.watch()}
        items={items}
        open={openDrawerCreateItem}
        setOpen={setOpenDrawerCreateItem}
        onAdd={(items) => {
          setItems(items);
        }}
      />
      <DrawerAssignClient
        open={openDrawerAssignClient}
        setOpen={setOpenDrawerAssignClient}
        onAdd={onAdd}
      />
    </div>
  );
}
export default CreateOrder;
