// Custom components imports
import DeleteRAAB from "./DeleteRAAB";
import EditRAABForm from "./EditRAABForm";
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";
import { toast } from "react-toastify";

// Custom queries imports
import { useWarehouses } from "../../../features/warehouses/useWarehouses";
import { useUpdateRAABMutation } from "../../../features/rraab/useUpdateRAABMutation";
import { useRemoveRAABMutation } from "../../../features/rraab/useRemoveRAABMutation";

// Custom types imports
import { RAABType } from "../../../features/rraab/types";
import { OperationCenterType } from "../../../features/operationCenters/types";

interface Props {
  operationCenters: OperationCenterType[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  raab: RAABType;
  onFinish?: () => void;
}

function DrawerEditRAAB({
  operationCenters,
  open,
  setOpen,
  raab,
  onFinish,
}: Props) {
  const methods = useForm<RAABType>({
    defaultValues: raab,
  });

  const {
    isLoading: warehousesLoading,
    isError: warehousesError,
    data: warehouses,
  } = useWarehouses();

  const isLoading = warehousesLoading;
  const isError = !warehouses || !operationCenters || warehousesError;

  const closeDrawer = () => {
    methods.reset();
    setOpen(false);
  };

  const updateRAABMutation = useUpdateRAABMutation(
    (_data, variables) => {
      onFinish?.();
      closeDrawer();
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `RAAB ${variables.data.codProduct}-${variables.data.idOperationCenter} actualizado`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data ||
          `Algo salio mal, al intentar actualizar RAAB ${variables.data.codProduct}-${variables.data.idOperationCenter}`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const removeRAABMutation = useRemoveRAABMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `RAAB eliminada`,
        closeOnClick: true,
        closeButton: true,
      });
      onFinish?.();
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data ||
          `Algo salio mal, al intentar eliminar RAAB ${raab.codProduct}-${raab.idOperationCenter}`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Actualizando RAAB...");
    updateRAABMutation.mutate({ data, notificationID });
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        {isLoading ? (
          <Loading text="Cargando..." />
        ) : isError ? (
          <ErrorLoading text="¡Hubo un error al cargar RAAB" />
        ) : (
          <>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
                  <button type="button" onClick={() => closeDrawer()}>
                    <i className="mr-4 text-xl bi bi-arrow-left"></i>
                  </button>{" "}
                  <b className="font-medium text-lg">
                    RAAB #{raab.codProduct}-{raab.idOperationCenter}
                  </b>
                  <button
                    className="ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                    type="submit"
                  >
                    Actualizar
                  </button>
                </div>
                <EditRAABForm
                  operationCenters={operationCenters}
                  warehouses={warehouses}
                  action="edit"
                />
              </form>
            </FormProvider>
            <DeleteRAAB
              onDelete={() => {
                confirmChange(
                  "¿Estas seguro de eliminar esta RAAB?",
                  "Al eliminar esta RAAB, el cambio sera permanente",
                  () => {
                    const notificationID = toast.loading("Eliminando RAAB...");
                    removeRAABMutation.mutate({
                      _id: raab._id,
                      notificationID,
                    });
                  }
                );
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default DrawerEditRAAB;
