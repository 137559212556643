// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { CustomerType } from "./types";

export function useCustomer(_id?: string) {
  async function getCustomer(_id?: string): Promise<CustomerType> {
    await timeout(500);
    const response = await api.get<CustomerType>(`clients/client/${_id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Customer", _id],
    queryFn: () => getCustomer(_id),
    enabled: !!_id,
  });
}
