import { OperationCenterType } from "../../features/operationCenters/types";

interface Props {
  operationCenter: OperationCenterType;
  onSelect: (operationCenter: OperationCenterType) => void;
}

function OperationCenterCard({ operationCenter, onSelect }: Props) {
  return (
    <div
      className="flex flex-1 flex-row gap-x-2 py-4 px-4 hover:cursor-pointer hover:bg-red-50 transition-all"
      onClick={() => onSelect(operationCenter)}
    >
      <div className="w-[75%] flex flex-col">
        <div className="flex flex-row items-center">
          <p className="text-[#EA002A] font-[700]   mr-2">
            {operationCenter?.idOperationCenter}
          </p>
          <p className="font-[700] truncate flex-1">{operationCenter?.name}</p>
        </div>
      </div>
      <div className="flex flex-1 items-end flex-col justify-center">
        <i className="bi bi-chevron-right text-[#EA002A] text-lg"></i>
      </div>
    </div>
  );
}

export default OperationCenterCard;
