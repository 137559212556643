import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { UserWithTokenType } from "./types";
import { USER_KEY } from "./helpers";
import api from "../../app/api";

interface UserStateTypes {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: UserWithTokenType | null;
}

const initialState: UserStateTypes = {
  isLoading: true,
  isAuthenticated: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    changeIsLoadingUser: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    loadUser: (state, action: PayloadAction<UserWithTokenType>) => {
      api.setHeader("Authorization", action.payload.token);
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem(USER_KEY);
      api.deleteHeader("Authorization");
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export const { loadUser, logout, changeIsLoadingUser } = authSlice.actions;
export default authSlice.reducer;
