//Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom types imports
import { CustomerType } from "../../../features/customers/types";

// Custom (react-hook-form) imports
import { useFormContext } from "react-hook-form";

function DataCustomerForm() {
  const methods = useFormContext<CustomerType>();

  return (
    <div className="border-b w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-10 gap-x-4 gap-y-2">
      <CustomLabel
        label="ID Cliente"
        id="idClient"
        required={true}
        className="text-sm sm:col-span-1"
        name="idClient"
      >
        <input
          type="number"
          placeholder="ID Cliente"
          className={`input-base ${
            methods.formState.errors.idClient ? "input-error" : ""
          } ${
            !methods.formState.errors.idClient && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("idClient", {
            required: "Este campo es requerido",
            valueAsNumber: true,
          })}
        />
      </CustomLabel>
      <CustomLabel
        label="NIT"
        id="nit"
        required={true}
        className="text-sm sm:col-span-2"
        name="nit"
      >
        <input
          type="number"
          placeholder="NIT"
          className={`input-base ${
            methods.formState.errors.nit ? "input-error" : ""
          } ${
            !methods.formState.errors.nit && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("nit", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>
      <CustomLabel
        label="Nombre"
        id="name"
        required={true}
        className="text-sm sm:col-span-7"
        name="name"
      >
        <input
          placeholder="Nombre"
          className={`input-base ${
            methods.formState.errors.name ? "input-error" : ""
          } ${
            !methods.formState.errors.name && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("name", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>
    </div>
  );
}
export default DataCustomerForm;
