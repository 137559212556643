// React imports
import React, { useState, useEffect } from "react";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../../components/shared/ContentWithToggle";
import DataCustomerForm from "../../../components/forms/customers/DataCustomerForm";
import ListBanches from "../../../components/forms/customers/ListBanches";
import DrawerCreateBranch from "../../../components/forms/customers/DrawerCreateBranch";
import DrawerEditBranch from "../../../components/forms/customers/DrawerEditBranch";
import DropdownActions from "../../../components/DropdownActions";

// Custom queries imports
import { useRegisterCustomerMutation } from "../../../features/customers/useRegisterCustomerMutation";

// Custom types imports
import { CustomerType } from "../../../features/customers/types";
import { Action } from "../../../components/DropdownActions/types";
import {
  BranchRegistrationType,
  BranchType,
} from "../../../features/branches/types";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

function CreateCustomer() {
  const submitButtonRef = React.useRef<any>(null);
  const [branches, setBranches] = useState<BranchType[]>([]);
  const [openDrawerCreateBranch, setOpenDrawerCreateBranch] =
    useState<boolean>(false);

  const [openDrawerEditBranch, setOpenDrawerEditBranch] =
    useState<boolean>(false);

  const [selectedBranch, setSelectedBranch] = useState<BranchType | undefined>(
    undefined
  );

  const [selectedBranchIndex, setSelectedBranchIndex] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (openDrawerEditBranch === false) {
      setSelectedBranch(undefined);
    }
  }, [openDrawerEditBranch, setSelectedBranch]);

  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<CustomerType>({
    defaultValues: {
      idClient: undefined,
      nit: "",
      name: "",
      branches: [],
    },
  });

  const registerCustomerMutation = useRegisterCustomerMutation(
    (data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Cliente creado`,
        closeOnClick: true,
        closeButton: true,
      });
      navigate(`/${currentUser?.user?._id}/clientes/cliente/${data._id}`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar crear el cliente",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmitCustomer = methods.handleSubmit((data) => {
    if (branches.length === 0) {
      return toast.error("La lista de sucursales no puede estar vacía.");
    }
    const notificationID = toast.loading("Creando cliente...");
    const branchesWithoutSubDocuments: BranchRegistrationType[] = branches.map(
      (branch) => ({
        ...branch,
        backorder: branch.backorder._id,
        saleCondition: branch.saleCondition._id,
        seller: branch.seller._id,
      })
    );
    registerCustomerMutation.mutate({
      data: { ...data, branches: branchesWithoutSubDocuments },
      notificationID,
    });
  });

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Agregar Sucursal",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setOpenDrawerCreateBranch(true),
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser.user?._id}/clientes`);
          }
        );
      },
    },
  ];

  return (
    <div className="divide-y w-[95%] sm:max-w-[1800px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Crear Cliente"
        onBackButton={() => navigate(`/${currentUser?.user?._id}/clientes`)}
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <FormProvider {...methods}>
          <form onSubmit={onSubmitCustomer}>
            <DataCustomerForm />
            <ListBanches
              branches={branches}
              onSelect={(branch, index) => {
                setOpenDrawerEditBranch(true);
                setSelectedBranchIndex(index);
                setSelectedBranch(branch);
              }}
            />
            <input type="submit" className="hidden" ref={submitButtonRef} />
          </form>
        </FormProvider>
        {!!selectedBranch && (
          <DrawerEditBranch
            open={openDrawerEditBranch}
            setOpen={setOpenDrawerEditBranch}
            branch={selectedBranch}
            onDelete={() =>
              setBranches((currentBranches) =>
                currentBranches.filter(
                  (_branch, index) => index !== selectedBranchIndex
                )
              )
            }
            onUpdate={(data) =>
              setBranches((currentBranches) =>
                currentBranches.map((branch, index) =>
                  index === selectedBranchIndex ? data : branch
                )
              )
            }
          />
        )}
        <DrawerCreateBranch
          open={openDrawerCreateBranch}
          setOpen={setOpenDrawerCreateBranch}
          onAdd={(branch) =>
            setBranches((currentBranches) => [...currentBranches, branch])
          }
        />
      </ContentWithToggle>
    </div>
  );
}
export default CreateCustomer;
