// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { UpdateProductType, ProductType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: UpdateProductType;
  notificationID: string | number;
}

export function useUpdateProductMutation(
  onSuccessMutation?: (data: ProductType, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function updateProduct(params: paramsType): Promise<ProductType> {
    const response = await api.put<ProductType>(
      `products/update/${params.data._id}`,
      params.data
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: updateProduct,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Products", variables.data._id]);
      queryClient.invalidateQueries(["Products"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
