// Custom components imports
import DeleteExtension from "./DeleteExtension";
import EditExtensionForm from "./EditExtensionForm";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Custom types imports
import { ExtensionType } from "../../../features/extensions/types";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  extension: ExtensionType;
  onDelete: (idExtension?: number) => void;
  onUpdate: (data: ExtensionType) => void;
}

function DrawerEditExtension({
  open,
  setOpen,
  extension,
  onDelete,
  onUpdate,
}: Props) {
  const methods = useForm<ExtensionType>({
    defaultValues: extension,
  });

  const closeDrawer = () => {
    methods.reset();
    setOpen(false);
  };

  const onSubmit = methods.handleSubmit((data) => {
    onUpdate(data);
    closeDrawer();
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="mr-4 text-xl bi bi-arrow-left"></i>
              </button>{" "}
              <b className="font-medium text-lg">{extension.name}</b>
              <button
                className="ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                type="submit"
              >
                Actualizar
              </button>
            </div>
            <EditExtensionForm />
          </form>
        </FormProvider>
        <DeleteExtension
          onDelete={() => {
            confirmChange(
              "¿Estas seguro de eliminar esta extension?",
              "Al eliminar esta extension, el cambio sera permanente",
              () => {
                onDelete(extension?.idExtension);
                closeDrawer();
              }
            );
          }}
        />
      </div>
    </div>
  );
}

export default DrawerEditExtension;
