// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

//Custom components imports
import { BackorderRegistrationType } from "../../../features/backorders/types";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";
import CreateBackorderForm from "./CreateBackorderForm";

// Custom Hooks imports
import { useRegisterBackorderMutation } from "../../../features/backorders/useRegisterBackorderMutation";

//Third helpers imports
import { toast } from "react-toastify";

interface Props {
  visible: boolean;
  onClose: () => void;
}

function DrawerCreateBackorder({ visible, onClose }: Props) {
  const queryClient = useQueryClient();
  const methods = useForm<BackorderRegistrationType>({
    defaultValues: { name: "", idBackorder: undefined },
  });

  const registerWarehouseMutation = useRegisterBackorderMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Backorder creada`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Backorders"]);
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar crear backorder",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const closeDrawer = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Creando backorder...");
    registerWarehouseMutation.mutate({
      notificationID,
      data,
    });
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        visible ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="mr-4 text-xl bi bi-arrow-left"></i>
              </button>{" "}
              <b className="font-medium text-base">Crear Backorder</b>
              <button
                className="ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                type="submit"
              >
                Agregar
              </button>
            </div>
            <CreateBackorderForm />
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default DrawerCreateBackorder;
