//Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom types imports
import { SellerType } from "../../../features/sellers/types";
import { OperationCenterType } from "../../../features/operationCenters/types";

// Custom (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";

interface Props {
  operationCenters: UseQueryResult<OperationCenterType[], unknown>;
}

function DataSellerForm({ operationCenters }: Props) {
  const methods = useFormContext<SellerType>();

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
      <CustomLabel
        label="ID de Vendedor"
        id="idSeller"
        required={true}
        className="text-sm"
        name="idSeller"
      >
        <input
          placeholder="ID de Vendedor"
          className={`input-base ${
            methods.formState.errors?.idSeller ? "input-error" : ""
          } ${
            !methods.formState.errors?.idSeller && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("idSeller")}
        />
      </CustomLabel>

      <CustomLabel
        label="Cédula"
        id="idCard"
        required={true}
        className="text-sm"
        name="idCard"
      >
        <input
          type="number"
          placeholder="Cédula"
          className={`input-base ${
            methods.formState.errors?.idCard ? "input-error" : ""
          } ${
            !methods.formState.errors?.idCard && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("idCard")}
        />
      </CustomLabel>

      <CustomLabel
        label="Centro de Operación"
        id="idOperationCenter"
        required={false}
        className="text-sm"
        name="idOperationCenter"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled={operationCenters.isLoading || !operationCenters.data}
            className="input-base pr-[40px] overflow-hidden appearance-none"
            {...methods.register("idOperationCenter")}
          >
            <option value="">Todas</option>
            {operationCenters.data?.map((operationCenter) => (
              <option
                value={operationCenter.idOperationCenter}
                key={operationCenter._id}
              >
                {operationCenter.idOperationCenter} {operationCenter.name}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Nombre"
        id="name"
        required={true}
        className="text-sm"
        name="name"
      >
        <input
          placeholder="Nombre"
          className={`input-base ${
            methods.formState.errors?.name ? "input-error" : ""
          } ${
            !methods.formState.errors?.name && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("name")}
        />
      </CustomLabel>
    </div>
  );
}
export default DataSellerForm;
