// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { UserWithTokenType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  token: string;
}

export function useVerifyTokenMutation(
  onSuccessMutation?: (data: UserWithTokenType, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<string>, variables: paramsType) => void
) {
  async function verifyToken(params: paramsType): Promise<UserWithTokenType> {
    const response = await api.post<UserWithTokenType>("auth/verify", {
      token: params.token,
    });

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: verifyToken,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
