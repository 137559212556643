// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  entity:
    | "backorders"
    | "saleConditions"
    | "products"
    | "rraab"
    | "operationCenters"
    | "warehouses"
    | "clients"
    | "sellers";
  file: any;
}

export function useImportsMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string[]>,
    variables: paramsType
  ) => void
) {
  async function importData(params: paramsType): Promise<string> {
    const formData = new FormData();
    formData.append("", params.file);
    const response = await api.post<string>(
      `${params.entity}/import`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data; charset=utf-8",
        },
      }
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: importData,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
