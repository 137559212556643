// Types import
import {
  OrderType,
  OrderStatusColor,
  OrderStatusDescription,
} from "../../features/orders/types";

// Custom helpers imports
import { formatPrice, pad } from "../../helpers";

interface Props {
  order: OrderType;
  onSelect: (order: OrderType) => void;
}

function RowOrder({ order, onSelect }: Props) {
  return (
    <>
      <div
        className="grid md:hidden grid-cols-5"
        onClick={() => onSelect(order)}
      >
        <div className="flex flex-col px-2 py-3 col-span-3">
          <span className="text-sm font-semibold text-red-primary">
            {order.idOrder}
          </span>
          <p className="  font-semibold truncate">
            {order.client.name}
          </p>
          <p className="text-sm truncate text-[#929292]">
            {order.branch.description}
          </p>
          <div className="flex gap-x-1 my-1">
            <span
              className="text-xs text-white py-1 px-2 rounded"
              style={{ background: OrderStatusColor[order.status] }}
            >
              {OrderStatusDescription[order.status]}
            </span>
            <span className="text-xs text-[#414141] bg-[#ECECEC] py-1 px-2 rounded">
              {order.items.length} productos
            </span>
          </div>
          <p className="text-sm truncate text-[#929292]">
            {new Date(order.createdAt).toLocaleDateString("es-CO", {
              year: "numeric",
              month: "2-digit",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </div>
        <div className="flex flex-col px-2 py-3 col-span-2 items-end">
          <p className="text-lg truncate text-black font-semibold">
            {formatPrice(order.total)}
          </p>
          <p className="text-sm truncate text-[#929292]">
            {formatPrice(order.subtotal)}
          </p>
          <p className="text-sm truncate text-[#929292]">
            {formatPrice(order.iva)}
          </p>
          <button type="button" className="mt-auto ml-auto button-primary w-[100px] h-[32px] text-xs p-0 flex justify-center items-center">
            Ver Detalle
            <i className="bi bi-chevron-right !text-sm text-white ml-1"></i>
          </button>
        </div>
      </div>

      <div className="hidden md:grid divide-x border-t w-full h-[50px] grid-cols-[70px_70px_70px_3fr_100px_100px_2fr_2fr_2fr_40px]">
        <div className="px-2 flex items-center text-red-primary font-bold">
          <p className="text-sm truncate">{order.idOrder}</p>
        </div>
        <div className="px-2 flex items-center">
          <p className="text-sm truncate">{order.numDocto || "N/A"}</p>
        </div>
        <div className="px-2 flex items-center">
          <p className="text-sm truncate">
            {pad(order.operationCenter.idOperationCenter, 3)}
          </p>
        </div>
        <div className="px-2 flex justify-center items-start flex-col overflow-hidden">
          <p className="w-full text-sm truncate font-bold">
            {order.client.name}
          </p>
          <p className="w-full text-xs truncate">{order.branch.description}</p>
        </div>
        <div className="px-2 flex items-center overflow-hidden">
          <span
            className="font-bold text-sm truncate"
            style={{ color: OrderStatusColor[order.status] }}
          >
            {OrderStatusDescription[order.status]}
          </span>
        </div>
        <div className="px-2 flex items-center overflow-hidden">
          <p className="w-full text-sm truncate cursor-pointer">
            {new Date(order.createdAt).toLocaleDateString("es-CO", {
              year: "numeric",
              month: "2-digit",
              day: "numeric",
            })}
          </p>
        </div>
        <div className="px-2 flex items-center overflow-hidden">
          <p className="w-full text-sm truncate cursor-pointer">
            {formatPrice(order.iva)}
          </p>
        </div>
        <div className="px-2 flex items-center overflow-hidden">
          <p className="text-sm">{formatPrice(order.subtotal)}</p>
        </div>
        <div className="px-2 flex items-center overflow-hidden">
          <p className="w-full text-sm truncate cursor-pointer">
            {formatPrice(order.total)}
          </p>
        </div>
        <div
          className="px-2 flex items-center justify-center cursor-pointer overflow-hidden"
          onClick={() => onSelect(order)}
        >
          <i className="text-red-primary text-base bi bi-pencil-square"></i>
        </div>
      </div>
    </>
  );
}
export default RowOrder;
