// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { CustomerRegistrationType, CustomerType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: CustomerRegistrationType;
  notificationID: string | number;
}

export function useRegisterCustomerMutation(
  onSuccessMutation?: (data: CustomerType, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function registerCustomer(params: paramsType): Promise<CustomerType> {
    const response = await api.post<CustomerType>(
      "clients/register",
      params.data
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: registerCustomer,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Customers"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
