// React imports
import React, { useState } from "react";

// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import SellersList from "../../components/list/SellersList";
import DrawerCreateSeller from "../../components/forms/seller/DrawerCreateSeller";
import DropdownActions from "../../components/DropdownActions";
import SearchInput from "../../components/shared/fields/SearchInput";

// Custom queries imports
import { useSellers } from "../../features/sellers/useSellers";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom queries imports
import { useExportSellersMutation } from "../../features/sellers/useExportSellersMutation";

// Custom helpers imports
import { downloadReport } from "../../helpers";

// (react-toastify) imports
import { toast } from "react-toastify";

// Custom types imports
import { Action } from "../../components/DropdownActions/types";

function Sellers() {
  const searchRef = React.useRef<any>(null);
  const [search, setSearch] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [visibleDrawer, setVisibleDrawer] = useState<
    "create" | "edit" | undefined
  >(undefined);

  const sellers = useSellers({ search: searchFilter });
  const navigate = useNavigate();

  const exportSellersMutation = useExportSellersMutation(
    (data, variables) => {
      downloadReport(data, "Lista de Vendedores ");
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Exportación de vendedores exitosa`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Algo salio mal al intentar exportar vendedores`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setVisibleDrawer("create"),
    },
    {
      label: "Exportar",
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () => exportSellersMutation.mutate({ notificationID: "" }),
    },
  ];

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Vendedores"
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <div className="w-full px-4 py-3 flex justify-between items-center">
          <SearchInput
            searchRef={searchRef}
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            onStopTyping={(value) => setSearchFilter(value)}
          />
        </div>
        <SellersList
          onSelect={(seller) => navigate(`vendedor/${seller._id}`)}
          sellers={sellers}
        />

        <DrawerCreateSeller
          visible={visibleDrawer === "create"}
          onClose={() => {
            setVisibleDrawer(undefined);
          }}
        />
      </ContentWithToggle>
    </div>
  );
}

export default Sellers;
