// Custom components imports
import EditUserForm from "../../../components/forms/users/EditUserForm";
import Loading from "../../../components/shared/Loading";
import ErrorLoading from "../../../components/shared/ErrorLoading";

// Custom queries imports
import { useUser } from "../../../features/users/useUser";
import { useSellers } from "../../../features/sellers/useSellers";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditUser() {
  const params = useParams();

  const user = useUser(params.id);
  const sellers = useSellers();

  if (user.isLoading || sellers.isLoading) {
    return <Loading text="Cargando informacion del usuario..." />;
  }

  if (user.isError || !user.data || !sellers.data || sellers.isError) {
    return (
      <ErrorLoading text="¡Hubo un error al cargar informacion del usuario!" />
    );
  }

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <EditUserForm user={user.data} sellers={sellers.data} />
    </div>
  );
}

export default EditUser;
