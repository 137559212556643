// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import HeaderGridExtensions from "./HeaderGridExtensions";
import RowExtension from "./RowExtension";
import ExtensionCard from "../../cards/ExtensionCard";
import EmptyContent from "../../shared/EmptyContent";

//Custom types imports
import { ExtensionType } from "../../../features/extensions/types";

interface Props {
  extensions: ExtensionType[];
  onSelect: (extension: ExtensionType, index: number) => void;
}

function ListExtensions({ extensions, onSelect }: Props) {
  return (
    <ContentWithToggle title="Extensiones" isToogle={true}>
      <div className="border-t w-full px-4 py-3 flex justify-between items-center border-b">
        <span className="text-gray-600">Número de extensiones</span>
        <div className="w-[30%] text-end">
          <b>{extensions.length}</b>
        </div>
      </div>
      {extensions.length === 0 ? (
        <div className="w-full border-t">
          <div className="w-full">
            <EmptyContent text="No hay Extensiones" />
          </div>
        </div>
      ) : (
        <>
          <HeaderGridExtensions />
          <div className="w-full block md:hidden divide-y">
            {extensions.map((extension, index) => (
              <ExtensionCard
                key={extension._id}
                extension={extension}
                onSelect={(ext) => onSelect(ext, index)}
              />
            ))}
          </div>
          <div className="w-full hidden md:block">
            {extensions.map((extension, index) => (
              <RowExtension
                extension={extension}
                key={extension._id}
                onSelect={(extension) => onSelect(extension, index)}
              />
            ))}
          </div>
        </>
      )}
    </ContentWithToggle>
  );
}

export default ListExtensions;
