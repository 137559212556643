// Custom components imports
import DeleteBranch from "./DeleteBranch";
import EditBranchForm from "./EditBranchForm";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Custom types imports
import { BranchType } from "../../../features/branches/types";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  branch: BranchType;
  onDelete: () => void;
  onUpdate: (data: BranchType) => void;
}

function DrawerEditBranch({
  open,
  setOpen,
  branch,
  onDelete,
  onUpdate,
}: Props) {
  const methods = useForm<BranchType>({
    defaultValues: branch,
  });

  const closeDrawer = () => {
    methods.reset();
    setOpen(false);
  };

  const onSubmit = methods.handleSubmit((data) => {
    onUpdate(data);
    closeDrawer();
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[720px] h-full sm:rounded-bl-xl">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="mr-4 text-xl bi bi-arrow-left"></i>
              </button>{" "}
              <b className="font-medium text-lg truncate">{branch.description}</b>
              <button
                className="ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                type="submit"
              >
                Actualizar
              </button>
            </div>
            <EditBranchForm />
          </form>
        </FormProvider>
        <DeleteBranch
          onDelete={() => {
            confirmChange(
              "¿Estas seguro de eliminar este sucursal?",
              "Al eliminar esta sucursal, el cambio sera permanente",
              () => {
                onDelete();
                closeDrawer();
              }
            );
          }}
        />
      </div>
    </div>
  );
}

export default DrawerEditBranch;
