//Custom components imports
import CustomPasswordField from "../../shared/fields/CustomPasswordField";
import PasswordValidator from "../../PasswordValidator";

// Custom types imports
import { UpdatePasswordType } from "../../../features/users/types";

// Custom helpers imports
import {
  validateMinLength,
  validateContainsUppercase,
  validateContainsNumber,
  validateContainsLowercase,
  validatePasswordConfirmation,
} from "../../../features/auth/helpers";

// Custom (react-hook-form) imports
import { useFormContext } from "react-hook-form";

function UpdatePasswordForm() {
  const methods = useFormContext<UpdatePasswordType>();
  const password = methods.watch("password");

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
      <CustomPasswordField
        placeholder="Contraseña"
        id="password"
        required={true}
        label="Contraseña"
        name="password"
        classNameLabel="text-sm sm:col-span-2"
        validate={{
          minLength: (value: string) => validateMinLength(value, 8),
          hasNumber: (value: string) => validateContainsNumber(value),
          hasUppercase: (value: string) => validateContainsUppercase(value),
          hasLowercase: (value: string) => validateContainsLowercase(value),
        }}
      />

      <CustomPasswordField
        placeholder="Confirmar contraseña"
        id="confirmPassword"
        required={true}
        label="Confirmar contraseña"
        name="confirmPassword"
        classNameLabel="text-sm sm:col-span-2"
        validate={{
          isConfirmed: (value: string) =>
            validatePasswordConfirmation(value, password),
        }}
      />

      <div className="sm:col-span-4">
        <PasswordValidator name="user.password" inDrawer={true} />
      </div>
    </div>
  );
}
export default UpdatePasswordForm;
