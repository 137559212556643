// Custom types import
import { ItemType } from "../../features/items/types";

// Custom helpers import
import { formatPrice } from "../../helpers";

interface Props {
  item: ItemType;
  onSelect: (item: ItemType) => void;
}

function ItemCard({ item, onSelect }: Props) {
  return (
    <div className="grid md:hidden grid-cols-5" onClick={() => onSelect(item)}>
      <div className="flex flex-col px-2 py-3 col-span-3">
        <div className="flex flex-col">
          <p className="text-[rgb(234,0,42)] font-[700] mb-1   mr-2">
            {item?.extension.name}
          </p>
          <p className="font-[700] mb-1 truncate flex-1">
            {item?.extension.product.description}
          </p>
        </div>
        <p className="mb-1 truncate text-[#717171]">
          {formatPrice(item.price)} x {item.quantity}
        </p>
        <div className="flex flex-row items-center mb-1 gap-x1">
          <div className="px-3 py-[2px] mr-2 rounded-lg border border-[#3B82F6] bg-[#3B82F6]">
            <span className="text-sm text-white">
              {item.warehouse.idWarehouse}
            </span>
          </div>
          <div className="px-3 py-[2px] mr-2 rounded-lg border border-[#D1D1D1] bg-[#F8F8F8]">
            <span className="text-sm">
              {item.extension.product.unitPackaging}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-2 py-3 col-span-2 items-end">
        <p className="text-lg truncate text-black font-semibold">
          {formatPrice(item.total)}
        </p>
        <p className="text-sm truncate text-[#929292]">
          {formatPrice(item.subtotal)}
        </p>
        <p className="text-sm truncate text-[#929292]">
          {formatPrice(item.iva)}
        </p>
        <button type="button" className="mt-auto ml-auto button-primary w-[100px] h-[32px] text-xs p-0 flex justify-center items-center">
          Ver Detalle
          <i className="bi bi-chevron-right !text-sm text-white ml-1"></i>
        </button>
      </div>
    </div>
  );
}

export default ItemCard;
