// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { WarehouseRegistrationType, WarehouseType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: WarehouseRegistrationType;
  notificationID: string | number;
}

export function useRegisterWarehouseMutation(
  onSuccessMutation?: (data: WarehouseType, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function registerWarehouse(params: paramsType): Promise<WarehouseType> {
    const response = await api.post<WarehouseType>(
      "warehouses/register",
      params.data
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: registerWarehouse,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Warehouses"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
