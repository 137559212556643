// Custom components imports
import EditOrderForm from "../../../components/forms/order/EditOrderForm";
import Loading from "../../../components/shared/Loading";
import ErrorLoading from "../../../components/shared/ErrorLoading";

// Custom queries imports
import { useOrder } from "../../../features/orders/useOrder";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditOrder() {
  const params = useParams();

  const order = useOrder(params.idOrder);

  if (order.isLoading) {
    return <Loading text="Cargando pedidos..." />;
  }

  if (order.isError || !order.data) {
    return <ErrorLoading text="¡Hubo un error al cargar pedidos" />;
  }

  return <EditOrderForm order={order.data} />;
}

export default EditOrder;
