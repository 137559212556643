// React imports
import React, { useState, useEffect } from "react";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import DataProductForm from "./DataProductForm";
import ListExtensions from "./ListExtensions";
import ListRRAAB from "./ListRRAAB";
import DrawerCreateRAAB from "./DrawerCreateRAAB";
import DrawerEditRAAB from "./DrawerEditRAAB";
import DrawerEditExtension from "./DrawerEditExtension";
import DrawerCreateExtension from "./DrawerCreateExtension";
import DropdownActions from "../../DropdownActions";

// Custom queries imports
import { useRemoveProductMutation } from "../../../features/products/useRemoveProductMutation";
import { useUpdateProductMutation } from "../../../features/products/useUpdateProductMutation";
import { useRRAAB } from "../../../features/rraab/useRRAAB";

// Custom types imports
import { ProductType } from "../../../features/products/types";
import { OperationCenterType } from "../../../features/operationCenters/types";
import { RAABType } from "../../../features/rraab/types";
import { ExtensionType } from "../../../features/extensions/types";
import { Action } from "../../DropdownActions/types";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

interface Props {
  product: ProductType;
  operationCenters: OperationCenterType[];
}

function EditProductForm({ product, operationCenters }: Props) {
  const submitButtonRef = React.useRef<any>(null);
  const [extensions, setExtensions] = useState<ExtensionType[]>(
    product.extensions
  );

  const [openDrawerCreateRAAB, setOpenDrawerCreateRAAB] =
    useState<boolean>(false);

  const [openDrawerCreateExtension, setOpenDrawerCreateExtension] =
    useState<boolean>(false);

  const [openDrawerEditExtension, setOpenDrawerEditExtension] =
    useState<boolean>(false);

  const [openDrawerEditRAAB, setOpenDrawerEditRAAB] = useState<boolean>(false);

  const [selectedRAAB, setSelectedRAAB] = useState<RAABType | undefined>(
    undefined
  );

  const [selectedExtension, setSelectedExtension] = useState<
    ExtensionType | undefined
  >(undefined);

  const [selectedExtensionIndex, setSelectedExtensionIndex] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (openDrawerEditExtension === false) {
      setSelectedExtension(undefined);
    }
  }, [openDrawerEditExtension, setSelectedExtension]);

  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<ProductType>({ defaultValues: product });

  const {
    isLoading: rraabLoading,
    isError: rraabError,
    data: rraab,
    refetch: rraabRefetch,
  } = useRRAAB({ codProduct: product?.codProduct }, !!product?.codProduct);

  const operationCenterIDsWithRAAB = rraab?.map(
    (raab) => raab.idOperationCenter
  );
  const operationCentersWithoutRAAB = operationCenters.filter(
    (operationCenter) =>
      !operationCenterIDsWithRAAB?.includes(operationCenter.idOperationCenter)
  );

  const updateProductMutation = useUpdateProductMutation(
    (data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Actualización exitosa del producto #${data.codProduct}`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar el producto",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const removeProductMutation = useRemoveProductMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Producto eliminado`,
        closeOnClick: true,
        closeButton: true,
      });
      navigate(`/${currentUser?.user?._id}/productos`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar eliminar el producto",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmitProduct = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Actualizando producto...");
    updateProductMutation.mutate({
      data: { ...data, extensions },
      notificationID,
    });
  });

  const ACTIONS: Action[] = [
    {
      label: "Actualizar",
      icon: <i className="bi bi-arrow-repeat text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Agregar RAAB",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setOpenDrawerCreateRAAB(true),
      hidden: operationCenters?.length === rraab?.length,
    },
    {
      label: "Agregar extension",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setOpenDrawerCreateExtension(true),
    },
    {
      label: "Eliminar",
      icon: <i className="bi bi-trash3 text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de eliminar este cliente?",
          "Al eliminar este cliente, el cambio será permanente.",
          () => {
            let notificationID = toast.loading("Eliminando cliente...");
            removeProductMutation.mutate({
              _id: product._id,
              notificationID,
            });
          }
        );
      },
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser.user?._id}/productos`);
          }
        );
      },
    },
  ];

  return (
    <ContentWithToggle
      title={product.description}
      onBackButton={() => navigate(`/${currentUser?.user?._id}/productos`)}
      isToogle={false}
      actionButton={<DropdownActions actions={ACTIONS} />}
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmitProduct}>
          <DataProductForm />
          <ListRRAAB
            isLoading={rraabLoading}
            isError={rraabError}
            rraab={rraab}
            onSelect={(raab) => {
              setOpenDrawerEditRAAB(true);
              setSelectedRAAB(raab);
            }}
          />
          <ListExtensions
            onSelect={(branch, index) => {
              setOpenDrawerEditExtension(true);
              setSelectedExtensionIndex(index);
              setSelectedExtension(branch);
            }}
            extensions={extensions}
          />
          <input type="submit" className="hidden" ref={submitButtonRef} />
        </form>
      </FormProvider>

      {!!selectedExtension && (
        <DrawerEditExtension
          open={openDrawerEditExtension}
          setOpen={setOpenDrawerEditExtension}
          extension={selectedExtension}
          onDelete={() =>
            setExtensions((currentExtensions) =>
              currentExtensions.filter(
                (_extension, index) => index !== selectedExtensionIndex
              )
            )
          }
          onUpdate={(data) =>
            setExtensions((currentExtensions) =>
              currentExtensions.map((extension, index) =>
                index === selectedExtensionIndex ? data : extension
              )
            )
          }
        />
      )}

      {!!selectedRAAB && (
        <DrawerEditRAAB
          operationCenters={operationCenters}
          open={openDrawerEditRAAB}
          setOpen={setOpenDrawerEditRAAB}
          raab={selectedRAAB}
          onFinish={() => rraabRefetch()}
        />
      )}

      <DrawerCreateRAAB
        operationCenters={operationCentersWithoutRAAB}
        open={openDrawerCreateRAAB}
        setOpen={setOpenDrawerCreateRAAB}
        product={product}
        onFinish={() => rraabRefetch()}
      />

      <DrawerCreateExtension
        open={openDrawerCreateExtension}
        setOpen={setOpenDrawerCreateExtension}
        onAdd={(extension) =>
          setExtensions((currentExtension) => [...currentExtension, extension])
        }
      />
    </ContentWithToggle>
  );
}
export default EditProductForm;
