// Custom helpers imports
import { formatPrice } from "../../helpers";

interface Props {
  taxRate: number;
  price: number;
  iva: number;
  subtotal: number;
  total: number;
}

const DiscriminationOfTotals = ({
  taxRate,
  price,
  iva,
  subtotal,
  total,
}: Props) => {
  return (
    <div className="divide-y">
      <div className="py-2 flex justify-between">
        <span className="text-sm text-gray-500">Precio Unitario</span>
        <span className="text-sm text-gray-500">{formatPrice(price)}</span>
      </div>
      <div className="py-2 flex justify-between">
        <span className="text-sm text-gray-500">Tasa Impositiva</span>
        <span className="text-sm text-gray-500">{taxRate}%</span>
      </div>
      <div className="py-2 flex justify-between">
        <span className="text-sm text-gray-500">IVA</span>
        <span className="text-sm text-gray-500">{formatPrice(iva)}</span>
      </div>
      <div className="py-2 flex justify-between">
        <span className="text-sm text-gray-500">Subtotal</span>
        <span className="text-sm text-gray-500">{formatPrice(subtotal)}</span>
      </div>
      <div className="py-2 flex justify-between text-lg">
        <b className="text-sm">Total</b>
        <b className="text-sm">{formatPrice(total)}</b>
      </div>
    </div>
  );
};

export default DiscriminationOfTotals;
