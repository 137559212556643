interface Props {
  width?: number;
  height?: number;
  border?: number;
}

const Spinner = ({ width = 38, height = 38, border = 5 }: Props) => {
  return (
    <div
      style={{ width, height, borderWidth: border }}
      className={`animate-spin border-[#0000001A] border-solid border-l-[#0000004D] rounded-full`}
    ></div>
  );
};

export default Spinner;
