// Hooks (react-router-dom) imports
import { roleDescription } from "../../features/auth/helpers";

//Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";

// Hook (react-router-dom) imports

// Hooks & actions imports
import { useAppSelector } from "../../app/hook";

function RenderProfile() {
  const currentUser = useAppSelector((state) => state.auth);

  return (
    <div className="border-b w-full mx-auto bg-white overflow-hidden">
      <div className="text-sm w-full flex flex-col divide-y">
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Nombre completo</span>
          <b className="text-gray-600">{currentUser.user?.name}</b>
        </div>
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Correo electrónico</span>
          <b className="text-gray-600">{currentUser.user?.email}</b>
        </div>
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Cédula</span>
          <b className="text-gray-600">{currentUser.user?.idCard}</b>
        </div>
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Rol</span>
          <b className="text-gray-600">
            {currentUser.user?.role
              ? roleDescription(currentUser.user.role)
              : ""}
          </b>
        </div>
      </div>
    </div>
  );
}

function Profile() {
  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <ContentWithToggle title="Perfil">
        <RenderProfile />
      </ContentWithToggle>
    </div>
  );
}
export default Profile;
