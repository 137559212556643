// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { DepartamentType } from "./types";

export function useDepartaments() {
  async function getDepartaments(): Promise<DepartamentType[]> {
    await timeout(500);
    const response = await api.get<DepartamentType[]>("departaments/all");

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Departaments"],
    queryFn: () => getDepartaments(),
  });
}
