// Types import
import { ProductType } from "../../features/products/types";

// Custom helpers imports
import { formatPrice } from "../../helpers";

interface Props {
  product: ProductType;
  onSelect: (customer: ProductType) => void;
}

function RowProduct({ product, onSelect }: Props) {
  return (
    <div className="divide-x border-t w-full h-[40px] grid grid-cols-[1fr_5fr_2fr_60px_100px_2fr_2fr_40px]">
      <div className="px-2 flex items-center text-red-primary font-bold">
        <p className="text-sm">{product.codProduct}</p>
      </div>
      <div className="px-2 flex items-center justify-start overflow-hidden">
        <p className="text-sm truncate w-full break-all">
          {product.description}
        </p>
      </div>
      <div className="px-2 flex items-center">
        <span className="text-sm">{formatPrice(product.price)}</span>
      </div>
      <div className="px-2 flex items-center">
        <span className="text-sm">{product.taxRate}%</span>
      </div>
      <div className="px-2 flex items-center">
        <span className="text-sm">{product.extensions.length}</span>
      </div>
      <div className="px-2 flex items-center">
        <span className="text-sm">{product.unitPackaging}</span>
      </div>
      <div className="px-2 flex items-center">
        <span className="text-sm">{product.unitInventory}</span>
      </div>
      <div
        className="px-2 flex items-center justify-center cursor-pointer overflow-hidden"
        onClick={() => onSelect(product)}
      >
        <i className="text-red-primary text-base bi bi-pencil-square"></i>
      </div>
    </div>
  );
}
export default RowProduct;
