// React imports
import React, { useState, useEffect } from "react";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Custom helpers imports
import { getTotalsItems } from "../../../helpers";
import { confirmChange } from "../../../features/auth/helpers";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import DataOrderForm from "./DataOrderForm";
import ListItems from "./ListItems";
import DrawerAssignClient from "./DrawerAssignClient";
import DrawerEditItem from "./DrawerEditItem";
import DrawerCreateItem from "./DrawerCreateItem";
import DropdownActions from "../../DropdownActions";

// Custom queries imports
import { useRemoveOrderMutation } from "../../../features/orders/useRemoveOrderMutation";
import { useUpdateOrderMutation } from "../../../features/orders/useUpdateOrderMutation";
import { useExportOrderMutation } from "../../../features/orders/useExportOrderMutation";
import { useDeliverToSIESAMutation } from "../../../features/orders/useDeliverToSIESAMutation";

// Custom types imports
import { OrderType } from "../../../features/orders/types";
import { ItemType } from "../../../features/items/types";
import { CustomerType } from "../../../features/customers/types";
import { Action } from "../../DropdownActions/types";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom helpers imports
import { downloadReport } from "../../../helpers";

interface Props {
  order: OrderType;
}

function EditOrderForm({ order }: Props) {
  const submitButtonRef = React.useRef<any>(null);
  const [items, setItems] = useState<ItemType[]>(order.items);
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<OrderType>({
    defaultValues: {
      ...order,
      deliverDate: new Date(order.deliverDate).toISOString().split("T")[0],
    },
  });

  const [openDrawerCreateItem, setOpenDrawerCreateItem] =
    useState<boolean>(false);

  const [openDrawerAssignClient, setOpenDrawerAssignClient] =
    useState<boolean>(false);

  const [openDrawerEditItem, setOpenDrawerEditItem] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<ItemType | undefined>(
    undefined
  );

  const updateOrderMutation = useUpdateOrderMutation(
    (_data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Pedido actualizado`,
        closeOnClick: true,
        closeButton: true,
      });
      window.location.reload();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar el pedido",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const removeOrderMutation = useRemoveOrderMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Pedido eliminado`,
        closeOnClick: true,
        closeButton: true,
      });
      navigate(`/${currentUser?.user?._id}/pedidos`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar eliminar el pedido",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmitProduct = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Actualizando pedido...");
    updateOrderMutation.mutate({
      data: { ...data, items },
      notificationID,
    });
  });

  useEffect(() => {
    if (items.length === 0) {
      methods.watch("iva", 0);
      methods.watch("subtotal", 0);
      methods.watch("total", 0);
    } else {
      const { total, subtotal, iva } = getTotalsItems(items);
      methods.setValue("iva", iva);
      methods.setValue("subtotal", subtotal);
      methods.setValue("total", total);
    }
  }, [items, methods]);

  useEffect(() => {
    if (openDrawerEditItem === false) {
      setSelectedItem(undefined);
    }
  }, [openDrawerEditItem, setSelectedItem]);

  const onAdd = (client: CustomerType) => {
    const firstBranch = client.branches[0];
    methods.setValue("client", {
      _id: client._id,
      idClient: client.idClient,
      nit: client.nit,
      name: client.name,
    });
    methods.setValue("branch", firstBranch);
    methods.setValue("backorder", firstBranch.backorder);
    methods.setValue("saleCondition", firstBranch.saleCondition);
    methods.setValue("seller", firstBranch.seller);
  };

  const onUpdate = (data: ItemType) => {
    setItems((currentItems) => {
      const newItems = currentItems.map((item) => {
        if (
          item.warehouse._id === data.warehouse._id &&
          item.extension._id === data.extension._id
        ) {
          return data;
        }
        return item;
      });
      return newItems;
    });
  };

  const onDelete = () => {
    if (selectedItem) {
      setItems((currentItems) =>
        currentItems.filter(
          (item) =>
            !(
              item.extension._id === selectedItem.extension._id &&
              item.warehouse._id === selectedItem.warehouse._id
            )
        )
      );
    }
  };

  const exportOrderMutation = useExportOrderMutation(
    (data, variables) => {
      downloadReport(data, `Pedido #${order.idOrder}`);
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Exportación de pedido exitosa`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: `Algo salio mal al intentar exportar pedido`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const deliverToSIESAMutation = useDeliverToSIESAMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Entrega de pedido exitosa`,
        closeOnClick: true,
        closeButton: true,
      });
      window.location.reload();
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: `Algo salio mal al intentar entregar pedido a SIESA`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const ACTIONS: Action[] = [
    {
      label: "Actualizar",
      hidden: order.status !== "pending",
      icon: <i className="bi bi-arrow-repeat text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Entregar a SIESA",
      hidden: order.status !== "pending",
      icon: <i className="bi bi-send text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estas seguro de entregar este pedido?",
          "Al entregar este pedido, el cambio sera permanente",
          () => {
            const notificationID = toast.loading("Entregado pedido a SIESA...");
            deliverToSIESAMutation.mutate({
              notificationID,
              data: { ...methods.watch(), items },
            });
          }
        );
      },
    },
    {
      label: "Exportar",
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () => {
        const notificationID = toast.loading("Exportando pedido...");
        exportOrderMutation.mutate({ notificationID, id: order._id });
      },
    },
    {
      label: "Agregar Item",
      hidden: order.status !== "pending",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setOpenDrawerCreateItem(true),
    },
    {
      label: "Asignar Cliente",
      hidden: order.status !== "pending",
      icon: <i className="bi bi-person-check text-xl mr-2"></i>,
      onAction: () => setOpenDrawerAssignClient(true),
    },
    {
      label: "Eliminar",
      hidden: order.status !== "pending",
      icon: <i className="bi bi-trash3 text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estas seguro de eliminar este pedido?",
          "Al eliminar este pedido, el cambio sera permanente",
          () => {
            let notificationID = toast.loading("Eliminando pedido...");
            removeOrderMutation.mutate({
              _id: order._id,
              notificationID,
            });
          }
        );
      },
    },
    {
      label: "Cancelar",
      hidden: order.status !== "pending",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser.user?._id}/pedidos`);
          }
        );
      },
    },
  ];

  return (
    <div className="divide-y w-full sm:w-[90%] sm:max-w-[1300px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <FormProvider {...methods}>
        <form onSubmit={onSubmitProduct} className="relative divide-y">
          <ContentWithToggle
            title={`Editar Pedido #${order.idOrder}`}
            onBackButton={() => navigate(`/${currentUser?.user?._id}/pedidos`)}
            isToogle={false}
            actionButton={<DropdownActions actions={ACTIONS} />}
          >
            <DataOrderForm />
          </ContentWithToggle>
          <ListItems
            onSelect={(item) => {
              setOpenDrawerEditItem(true);
              setSelectedItem(item);
            }}
            items={items}
          />
          <input type="submit" className="hidden" ref={submitButtonRef} />
        </form>
      </FormProvider>

      {!!selectedItem && (
        <DrawerEditItem
          status={methods.watch("status")}
          open={openDrawerEditItem}
          setOpen={setOpenDrawerEditItem}
          item={selectedItem}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      )}
      <DrawerCreateItem
        order={methods.watch()}
        items={items}
        open={openDrawerCreateItem}
        setOpen={setOpenDrawerCreateItem}
        onAdd={(items) => {
          setItems(items);
        }}
      />
      <DrawerAssignClient
        open={openDrawerAssignClient}
        setOpen={setOpenDrawerAssignClient}
        onAdd={onAdd}
      />
    </div>
  );
}
export default EditOrderForm;
