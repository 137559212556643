// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import WarehouseCard from "../cards/WarehouseCard";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";

// Custom types imports
import { WarehouseType } from "../../features/warehouses/types";

interface Props {
  warehouses: UseQueryResult<WarehouseType[], unknown>;
  onSelect: (warehouse: WarehouseType) => void;
}

function WarehousesList({ warehouses, onSelect }: Props) {
  if (warehouses.isLoading) {
    return <Loading text="Cargando bodegas..." />;
  }

  if (warehouses.isError || !warehouses.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar bodegas" />
        </div>
      </div>
    );
  }

  if (warehouses.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay bodegas" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de bodegas</span>
        <div className="w-[30%] text-end">
          <b>{warehouses.data.length}</b>
        </div>
      </div>
      <div className="divide-y">
        {warehouses.data.map((warehouse) => (
          <WarehouseCard
            warehouse={warehouse}
            key={warehouse._id}
            onSelect={(warehouse) => onSelect(warehouse)}
          />
        ))}
      </div>
    </div>
  );
}

export default WarehousesList;
