// Types imports
import { ExtensionRegistrationType, ExtensionType } from "../extensions/types";

export interface FiltersProductType {
  search?: string;
}

export interface QueryPaginationProductType extends FiltersProductType {
  size?: string;
  page?: string;
}

export interface ProductRegistrationType {
  barcode: string;
  codProduct: string;
  description: string;
  taxRate: number;
  price: number;
  unitPackaging: string;
  unitInventory: string;
  extensions: ExtensionRegistrationType[];
}

export interface UpdateProductType {
  _id: string;
  barcode: string;
  codProduct: string;
  description: string;
  taxRate: number;
  price: number;
  unitPackaging: string;
  unitInventory: string;
  extensions: ExtensionRegistrationType[];
}

export interface ProductType {
  _id: string;
  barcode: string;
  codProduct: string;
  description: string;
  taxRate: number;
  price: number;
  unitPackaging: string;
  unitInventory: string;
  extensions: ExtensionType[];
}

export interface ProductSubDocumentType
  extends Omit<ProductType, "extensions"> {
  _id: string;
}

export interface ProductPagination {
  items: ProductType[];
  totalItems: number;
  currentPage: number;
}

export const SIZE = 15;
