import Spinner from "./Spinner";
interface Props {
  text: string;
}

function Loading({ text }: Props) {
  return (
    <div className="w-full flex justify-center items-center flex-col h-[150px]">
      <Spinner />
      <span className="mt-2">{text}</span>
    </div>
  );
}

export default Loading;
