// Custom components imports
import EditCustomerForm from "../../../components/forms/customers/EditCustomerForm";
import Loading from "../../../components/shared/Loading";
import ErrorLoading from "../../../components/shared/ErrorLoading";

// Custom queries imports
import { useCustomer } from "../../../features/customers/useCustomer";
import { useCustomerIsRemovable } from "../../../features/customers/useCustomerIsRemovable";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditCustomer() {
  const params = useParams();

  const customer = useCustomer(params.idClient);
  const {
    data: customerIsRemovable,
    isLoading: isRemovableLoading,
    isError: isRemovableError,
    isFetching: customerIsRemovableFetching,
    refetch: customerIsRemovableRefetch,
  } = useCustomerIsRemovable(params.idClient);

  if (customer.isLoading || isRemovableLoading) {
    return <Loading text="Cargando informacion del cliente..." />;
  }

  if (customer.isError || !customer.data || isRemovableError) {
    return (
      <ErrorLoading text="¡Hubo un error al cargar informacion del cliente!" />
    );
  }

  return (
    <div className="divide-y w-[95%] sm:max-w-[1800px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <EditCustomerForm
        customer={customer.data}
        customerIsRemovable={customerIsRemovable}
        isFetching={customerIsRemovableFetching}
        refetch={() => customerIsRemovableRefetch()}
      />
    </div>
  );
}

export default EditCustomer;
