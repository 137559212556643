// Components (react-router-dom) imports
import { Routes, Route } from "react-router-dom";

// Pages imports
import Login from "../../pages/Login";
import Profile from "../../pages/Profile";
import Orders from "../../pages/Orders";
import EditOrder from "../../pages/Orders/Edit";
import CreateOrder from "../../pages/Orders/Create";
import Products from "../../pages/Products";
import EditProduct from "../../pages/Products/Edit";
import CreateProduct from "../../pages/Products/Create";
import Sellers from "../../pages/Sellers";
import EditSeller from "../../pages/Sellers/Edit";
import Backorders from "../../pages/Backorders";
import OperationsCenter from "../../pages/OperationCenters";
import SaleConditions from "../../pages/SaleConditions";
import Customers from "../../pages/Customers";
import EditCustomer from "../../pages/Customers/Edit";
import CreateCustomer from "../../pages/Customers/Create";
import Warehouses from "../../pages/Warehouses";
import Users from "../../pages/Users";
import CreateUser from "../../pages/Users/Create";
import EditUser from "../../pages/Users/Edit";
import UpdatePassword from "../../pages/Users/UpdatePassword";
import SwitchRoute from "./SwitchRoute";

// Pages imports
import NotFound from "../../pages/NotFound";

// Types Routes imports
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

// Custom components imports
import App from "../../App";

function RootNavigation() {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<SwitchRoute />} />
        <Route
          path="/ingresar"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/:idUser/pedidos"
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/pedidos/pedido/:idOrder"
          element={
            <PrivateRoute>
              <EditOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/pedidos/agregar"
          element={
            <PrivateRoute>
              <CreateOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/productos"
          element={
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/productos/producto/:idProduct"
          element={
            <PrivateRoute>
              <EditProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/productos/agregar"
          element={
            <PrivateRoute>
              <CreateProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/usuarios"
          element={
            <PrivateRoute roles={[2]}>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/usuarios/agregar"
          element={
            <PrivateRoute roles={[2]}>
              <CreateUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/usuarios/usuario/:id/general"
          element={
            <PrivateRoute roles={[2]}>
              <EditUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/usuarios/usuario/:id/updatePassword"
          element={
            <PrivateRoute roles={[2]}>
              <UpdatePassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/clientes/cliente/:idClient"
          element={
            <PrivateRoute>
              <EditCustomer />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/clientes/agregar"
          element={
            <PrivateRoute>
              <CreateCustomer />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/clientes"
          element={
            <PrivateRoute>
              <Customers />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/vendedores"
          element={
            <PrivateRoute>
              <Sellers />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/vendedores/vendedor/:idSeller"
          element={
            <PrivateRoute>
              <EditSeller />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/bodegas"
          element={
            <PrivateRoute>
              <Warehouses />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/backorders"
          element={
            <PrivateRoute>
              <Backorders />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/centros"
          element={
            <PrivateRoute>
              <OperationsCenter />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/condiciones"
          element={
            <PrivateRoute>
              <SaleConditions />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/perfil"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default RootNavigation;
