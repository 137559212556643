// React imports
import { useEffect } from "react";

//Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";
import CustomEmailField from "../../shared/fields/CustomEmailField";

// Custom types imports
import { UserRegistrationType } from "../../../features/users/types";

// Custom helpers imports
import { roleDescription } from "../../../features/auth/helpers";

// Custom (react-hook-form) imports
import { useFormContext } from "react-hook-form";
import { SellerType } from "../../../features/sellers/types";

interface Props {
  sellers: SellerType[];
}

function DataUserForm({ sellers }: Props) {
  const methods = useFormContext<UserRegistrationType>();
  const allCustomers = methods.watch("allCustomers");
  const role = methods.watch("role");
  const seller = methods.watch("seller");

  useEffect(() => {
    if (seller !== null && seller !== "") {
      const selectedSeller = sellers.find((s) => s._id === seller);
      if (selectedSeller) {
        methods.setValue("name", selectedSeller.name);
        methods.setValue("idCard", selectedSeller.idCard);
      }
    }
  }, [seller, methods, sellers]);

  useEffect(() => {
    if (seller !== null && role !== 0) {
      methods.setValue("name", "");
      methods.setValue("idCard", "");
      methods.setValue("seller", null);
    } else if (seller === null && role === 0) {
      methods.setValue("name", "");
      methods.setValue("idCard", "");
    }
  }, [role, methods, seller]);

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
      <CustomLabel
        label="Rol de usuario"
        id="role"
        required={true}
        className="text-sm sm:col-span-2"
        name="role"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.role ? "input-error" : ""
            } ${
              !methods.formState.errors.role && methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("role", {
              required: "Este campo es requerido",
              valueAsNumber: true,
            })}
          >
            <option value="">Seleccionar rol del usuario</option>
            {[0, 1].map((role, index) => (
              <option value={role} key={index}>
                {roleDescription(role)}
              </option>
            ))}
          </select>
          <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>
      {role === 0 && (
        <CustomLabel
          label="Vendedor"
          id="seller"
          required={true}
          className="text-sm sm:col-span-2"
          name="seller"
        >
          <div className="w-full flex items-center relative">
            <select
              disabled={!sellers}
              className={`input-base pr-[40px] overflow-hidden appearance-none ${
                methods.formState.errors.seller ? "input-error" : ""
              } ${
                !methods.formState.errors.seller &&
                methods.formState.isSubmitted
                  ? "input-valid"
                  : ""
              } `}
              {...methods.register("seller", {
                required: "Este campo es requerido",
              })}
            >
              <option value="">Seleccionar vendedor</option>
              {sellers.map((seller) => (
                <option value={seller._id} key={seller._id}>
                  {seller.idSeller} {seller.name}
                </option>
              ))}
            </select>
            <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
          </div>
        </CustomLabel>
      )}

      <CustomLabel
        label="Nombre"
        id="name"
        required={true}
        className="text-sm sm:col-span-2"
        name="name"
      >
        <input
          disabled={role === 0}
          placeholder="Nombre"
          className={`input-base ${
            methods.formState.errors.name ? "input-error" : ""
          } ${
            !methods.formState.errors.name && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("name", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Cédula"
        id="idCard"
        required={true}
        className="text-sm sm:col-span-2"
        name="idCard"
      >
        <input
          placeholder="Cédula"
          disabled={role === 0}
          className={`input-base ${
            methods.formState.errors.idCard ? "input-error" : ""
          } ${
            !methods.formState.errors.idCard && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("idCard", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomEmailField
        placeholder="nombre@correo.com"
        id="email"
        required={true}
        label="Correo electrónico"
        name="email"
        classNameLabel="text-sm sm:col-span-2"
      />
      {role === 0 && (
        <div
          className="sm:col-span-4 py-3 border-t flex items-center cursor-pointer"
          onClick={() => methods.setValue("allCustomers", !allCustomers)}
        >
          <span>Acceso a todos los clientes</span>
          <div className="flex flex-1 justify-end items-center">
            {allCustomers ? (
              <i className="bi bi-check-circle-fill text-[20px] text-[#EA002A]"></i>
            ) : (
              <i className="bi bi-circle text-[20px] text-[#D0D0D0]"></i>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default DataUserForm;
