// React imports
import React from "react";

// Custom components
import ContentWithToggle from "../../../components/shared/ContentWithToggle";
import UpdatePasswordForm from "../../../components/forms/users/UpdatePasswordForm";
import DropdownActions from "../../../components/DropdownActions";
import Tabs from "../../../components/shared/Tabs";

// Hooks Redux imports
import { useAppSelector } from "../../../app/hook";

// Custom types imports
import { UpdatePasswordType } from "../../../features/users/types";
import { Action } from "../../../components/DropdownActions/types";
import { Tab } from "../../../components/shared/Tabs";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Third components & helpers imports
import { toast } from "react-toastify";

// Hooks & components (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

// Custom Hooks imports
import { useUpdatePasswordMutation } from "../../../features/users/useUpdatePasswordMutation";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

function UpdatePassword() {
  const submitButtonRef = React.useRef<any>(null);
  const params = useParams();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.user);
  const methods = useForm<UpdatePasswordType>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const tabs: Tab[] = [
    {
      label: "General",
      url: `/${params.idUser}/usuarios/usuario/${params.id}/general`,
    },
    {
      label: "Actualizar Contraseña",
      url: `/${params.idUser}/usuarios/usuario/${params.id}/updatePassword`,
    },
  ];

  const updatePasswordMutation = useUpdatePasswordMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Contraseña actualizada`,
        closeOnClick: true,
        closeButton: true,
      });
      navigate(`/${currentUser?._id}/usuarios/usuario/${params.id}/general`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar la contraseña",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Creando usuario...");
    updatePasswordMutation.mutate({
      _id: params.id || "",
      notificationID,
      data,
    });
  });

  const ACTIONS: Action[] = [
    {
      label: "Actualizar contraseña",
      icon: <i className="bi bi-arrow-repeat text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () =>
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser?._id}/usuarios`);
          }
        ),
    },
  ];

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Actualizar Contraseña"
        onBackButton={() => navigate(`/${currentUser?._id}/usuarios`)}
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Tabs tabs={tabs} />
            <UpdatePasswordForm />
            <input type="submit" className="hidden" ref={submitButtonRef} />
          </form>
        </FormProvider>
      </ContentWithToggle>
    </div>
  );
}
export default UpdatePassword;
