// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

// Custom components imports
import EditWarehouseForm from "./EditBackorderForm";
import DeleteBackorder from "./DeleteBackorder";
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom queries imports
import { useUpdateBackorderMutation } from "../../../features/backorders/useUpdateBackorderMutation";
import { useRemoveBackorderMutation } from "../../../features/backorders/useRemoveBackorderMutation";
import { useBackorderIsRemovable } from "../../../features/backorders/useBackorderIsRemovable";

//Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Custom types imports
import { BackorderType } from "../../../features/backorders/types";

interface Props {
  visible: boolean;
  onClose: () => void;
  backorder: BackorderType;
}

function DrawerEditBackorder({ visible, onClose, backorder }: Props) {
  const queryClient = useQueryClient();

  const methods = useForm<BackorderType>({
    defaultValues: backorder,
  });

  const closeDrawer = () => {
    methods.reset();
    onClose();
  };

  const removeBackorderMutation = useRemoveBackorderMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Backorder eliminada`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Backorders"]);
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar eliminar backorder",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const updateBackorderMutation = useUpdateBackorderMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Backorder actualizado`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Backorders"]);
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar backorder",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Actualizando backorder...");

    updateBackorderMutation.mutate({
      data: data,
      notificationID,
    });
  });

  const {
    data: backorderIsRemovable,
    isLoading: isRemovableLoading,
    isError: isRemovableError,
    isFetching: backorderIsRemovableFetching,
    refetch: backorderIsRemovableRefetch,
  } = useBackorderIsRemovable(backorder?._id);

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        visible ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        {isRemovableLoading ? (
          <Loading text="Cargando..." />
        ) : isRemovableError ? (
          <div className="w-full border-t">
            <div className="w-full">
              <ErrorLoading text="Hubo un error" />
            </div>
          </div>
        ) : (
          <>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
                  <button type="button" onClick={() => closeDrawer()}>
                    <i className="mr-4 text-xl bi bi-arrow-left"></i>
                  </button>{" "}
                  <b className="font-medium sm:text-base truncate">
                    {backorder.name}
                  </b>
                  <button
                    className={`${
                      methods.formState.isDirty
                        ? ""
                        : "opacity-50 pointer-events-none"
                    } ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm`}
                    type="submit"
                  >
                    Actualizar
                  </button>
                </div>
                <EditWarehouseForm />
              </form>
            </FormProvider>
            <DeleteBackorder
              backorderIsRemovable={backorderIsRemovable}
              refetch={() => backorderIsRemovableRefetch()}
              isFetching={backorderIsRemovableFetching}
              onDelete={() => {
                confirmChange(
                  "¿Estas seguro de eliminar este backorder?",
                  "Al eliminar este backorder, el cambio sera permanente",
                  () => {
                    let notificationID = toast.loading(
                      "Eliminando backorder..."
                    );
                    removeBackorderMutation.mutate({
                      _id: backorder._id,
                      notificationID,
                    });
                  }
                );
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default DrawerEditBackorder;
