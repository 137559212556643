// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { OperationCenterIsRemovableType } from "./types";

export function useOperationCenterIsRemovable(_id: string) {
  async function getOperationCenterIsRemovable(
    _id?: string
  ): Promise<OperationCenterIsRemovableType> {
    await timeout(500);
    const response = await api.get<OperationCenterIsRemovableType>(
      `operationCenters/operationCenter/isRemovable/${_id}`
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["OperetionCenterIsRemovable", _id],
    queryFn: () => getOperationCenterIsRemovable(_id),
    refetchOnWindowFocus: false,
    enabled: !!_id,
  });
}
