// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { RAABRegistrationType, RAABType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: RAABRegistrationType;
  notificationID: string | number;
}

export function useRegisterRAABMutation(
  onSuccessMutation?: (data: RAABType, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  async function registerRAAB(params: paramsType): Promise<RAABType> {
    const response = await api.post<RAABType>("rraab/register", params.data);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: registerRAAB,
    onSuccess: (data, variables) => {
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
