// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";
import { FiltersClientType } from "./types";

interface paramsType {
  params: FiltersClientType;
  notificationID: string | number;
}

export function useExportCustomersMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  async function exportCustomers({ params }: paramsType): Promise<any> {
    const response = await api.get<any>("clients/export", params);

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: exportCustomers,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
