// React imports
import { useRef, useState } from "react";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";
import Loading from "../../shared/Loading";

// Custom helpers imports
import {
  formatBytes,
  validateFile,
  getFilesFromDataTransfer,
} from "../../../helpers";

// Thrid helpers imports
import { toast } from "react-toastify";

interface Props {
  isLoading: boolean;
  isDone: boolean;
  title: string;
  visible: boolean;
  errors?: string[];
  onHideAllErrors?: () => void;
  onHideError?: (index: number) => void;
  onClose: () => void;
  onImport: (data: { file: any }) => void;
}

function DrawerImporter({
  isLoading,
  isDone,
  title,
  visible,
  errors,
  onHideAllErrors,
  onHideError,
  onClose,
  onImport,
}: Props) {
  const [activeArea, setActiveArea] = useState<boolean>(false);

  const methods = useForm<{ files: any | undefined }>({
    defaultValues: {
      files: undefined,
    },
  });

  const closeDrawer = () => {
    methods.reset();
    onClose();
  };

  const dropFile = (e: React.DragEvent<HTMLDivElement>) => {
    const files = getFilesFromDataTransfer(e);
    const file = files[0];
    if (files.length > 1) {
      return toast.error("Arrastra un solo archivo.");
    }
    validateFile(file, () => {
      methods.setValue("files", [file]);
    });
  };

  const inputFileRef = useRef<any>(null);
  const { ref, ...rest } = methods.register("files", {
    required: true,
    onChange: (event) => {
      const file = event.target.files[0];
      validateFile(
        file,
        () => {
          methods.setValue("files", [file]);
        },
        () => {
          methods.setValue("files", undefined);
        }
      );
    },
  });

  const files = methods.watch("files");

  const onSubmit = methods.handleSubmit((data) => {
    onImport({ file: data.files[0] });
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        visible ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[620px] h-full sm:rounded-bl-xl">
        {isLoading ? (
          <Loading text="Procesando archivo, esto puede tardar algunos minutos..." />
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
                <button type="button" onClick={() => closeDrawer()}>
                  <i className="mr-4 text-xl bi bi-arrow-left"></i>
                </button>{" "}
                <b className="font-medium text-lg truncate mr-2">{title}</b>
                {!isDone && (
                  <button
                    disabled={!files?.length}
                    onClick={() => {
                      onImport({ file: files[0] });
                    }}
                    type="button"
                    className="disabled:opacity-50 disabled:pointer-events-none ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                  >
                    Importar
                  </button>
                )}
              </div>
              {isDone ? (
                <div className="p-4 flex justify-center items-center flex-col">
                  <i className="text-6xl bi bi-check-circle-fill text-green-500"></i>
                  <h3 className="text-xl mt-4 font-bold">
                    ¡Importación finalizada con exito!
                  </h3>
                </div>
              ) : (
                <>
                  <div className="p-5">
                    <div
                      onDragEnter={() => {
                        setActiveArea(true);
                      }}
                      onDragLeave={() => {
                        setActiveArea(false);
                      }}
                      onDrop={(e) => {
                        if (e.preventDefault) {
                          e.preventDefault();
                        }
                        dropFile(e);
                        setActiveArea(false);
                      }}
                      onDragOver={(e) => {
                        if (e.preventDefault) {
                          e.preventDefault();
                        }
                        return false;
                      }}
                      className={`${
                        activeArea
                          ? "border-red-primary bg-red-100"
                          : "border-gray-400 bg-gray-100"
                      } rounded-md p-4 flex flex-col items-center justify-center border-dashed border-[2px] w-full min-h-[300px]`}
                    >
                      <i
                        className={`${
                          activeArea ? "text-red-primary" : "text-gray-600"
                        } pointer-events-none  text-[60px] bi bi-cloud-arrow-up-fill`}
                      ></i>
                      <p
                        className={`${
                          activeArea ? "text-red-primary" : "text-gray-600"
                        } pointer-events-none select-none font-bold`}
                      >
                        Arrastre y suelte el archivo CSV aquí.
                      </p>
                      <div
                        className={`${
                          activeArea && "opacity-0"
                        } pointer-events-none flex justify-center items-center my-5  w-full`}
                      >
                        <hr className="pointer-events-none w-[100px] h-[2px] bg-gray-400" />
                        <p className="pointer-events-none select-none font-bold text-gray-600 px-5">
                          O
                        </p>
                        <hr className="pointer-events-none w-[100px] h-[2px] bg-gray-400" />
                      </div>

                      <button
                        onClick={() => {
                          inputFileRef.current.click();
                        }}
                        type="button"
                        className={`${
                          activeArea && "pointer-events-none opacity-0"
                        } select-none button-primary px-5 bg-gray-200 text-gray-600 hover:bg-gray-300`}
                      >
                        {!files?.length
                          ? "Explorar archivos"
                          : "Cambiar archivo"}
                      </button>

                      <p
                        className={`${
                          activeArea ? "text-red-primary" : "text-gray-600"
                        } pointer-events-none select-none mt-5 px-5`}
                      >
                        Máximo tamaño de archivo: 5 MB.
                      </p>
                    </div>
                  </div>
                  {!!files?.length && (
                    <div className="p-5">
                      <div className="w-full px-2 py-2 border flex items-center rounded-md">
                        <p className="truncate flex-1">{files[0].name}</p>
                        <p className="text-gray-500 w-auto mx-2">
                          {!!files[0] && formatBytes(files[0].size)}
                        </p>
                        <button
                          type="button"
                          className="flex justify-center items-center ml-auto bg-gray-200 rounded-full w-[25px] h-[25px] transition-all hover:bg-red-primary hover:text-white"
                          onClick={() => {
                            methods.setValue("files", undefined);
                            onHideAllErrors?.();
                          }}
                        >
                          <i className="text-[12px] bi bi-x-lg"></i>
                        </button>
                      </div>
                    </div>
                  )}

                  {!!errors && !!errors.length && (
                    <div className="p-5 border-t">
                      <div className="flex justify-between items-center mb-2">
                        <h4 className="text-lg text-gray-600 font-bold">
                          Errores
                        </h4>
                        <div className="flex gap-x-2">
                          <div className="text-sm px-2 min-w-[25px] h-[25px] bg-red-primary flex justify-center items-center text-white font-bold rounded-full">
                            {errors?.length}
                          </div>
                          {onHideAllErrors && (
                            <button
                              onClick={onHideAllErrors}
                              className="underline hover:font-semibold"
                              type="button"
                            >
                              Limpiar los errores
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-y-2">
                        {errors.map((error, index) => (
                          <div
                            key={index}
                            className="flex text-gray-800 py-2 px-2 rounded-lg bg-red-50"
                          >
                            <i className="text-red-500 bi bi-exclamation-triangle-fill mr-2"></i>
                            <p className="flex-1">{error}</p>
                            {!!onHideError && (
                              <button
                                type="button"
                                className="flex justify-center items-center ml-auto bg-red-100 rounded-full w-[25px] h-[25px] transition-all hover:bg-red-primary hover:text-white"
                                onClick={() => onHideError(index)}
                              >
                                <i className="text-[12px] bi bi-x-lg"></i>
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <input
                    {...rest}
                    className="hidden"
                    type="file"
                    name="files"
                    accept=".csv"
                    ref={(e) => {
                      ref(e);
                      inputFileRef.current = e;
                    }}
                  />
                </>
              )}
            </form>
          </FormProvider>
        )}
      </div>
    </div>
  );
}

export default DrawerImporter;
