// Custom components imports
import EditProductForm from "../../../components/forms/products/EditProductForm";
import Loading from "../../../components/shared/Loading";
import ErrorLoading from "../../../components/shared/ErrorLoading";

// Custom queries imports
import { useProduct } from "../../../features/products/useProduct";
import { useOperationCenters } from "../../../features/operationCenters/useOperationCenters";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditProduct() {
  const params = useParams();

  const product = useProduct(params.idProduct);

  const {
    isLoading: operationCentersLoading,
    isError: operationCentersError,
    data: operationCenters,
  } = useOperationCenters();

  if (product.isLoading || operationCentersLoading) {
    return <Loading text="Cargando producto..." />;
  }

  if (
    product.isError ||
    !product.data ||
    operationCentersError ||
    !operationCenters
  ) {
    return <ErrorLoading text="¡Hubo un error al cargar producto" />;
  }

  return (
    <div className="divide-y w-full sm:max-w-[689px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <EditProductForm
        product={product.data}
        operationCenters={operationCenters}
      />
    </div>
  );
}

export default EditProduct;
