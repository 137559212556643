// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

// Custom components imports
import EditOperationCenterForm from "./EditOperationCenterForm";
import DeleteOperationCenter from "./DeleteOperationCenter";
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom queries imports
import { useUpdateOperationCenterMutation } from "../../../features/operationCenters/useUpdateOperationCenterMutation";
import { useRemoveOperationCenterMutation } from "../../../features/operationCenters/useRemoveOperationCenterMutation";
import { useOperationCenterIsRemovable } from "../../../features/operationCenters/useOperationCenterIsRemovable";

//Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Custom types imports
import { OperationCenterType } from "../../../features/operationCenters/types";

interface Props {
  visible: boolean;
  onClose: () => void;
  operationCenter: OperationCenterType;
}

function DrawerEditOperationCenter({
  visible,
  onClose,
  operationCenter,
}: Props) {
  const queryClient = useQueryClient();

  const methods = useForm<OperationCenterType>({
    defaultValues: operationCenter,
  });

  const closeDrawer = () => {
    methods.reset();
    onClose();
  };

  const removeOperationCenterMutation = useRemoveOperationCenterMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Centro de Operación eliminado`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["OperationCenters"]);
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar eliminar el centro de operación",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const updateOperationCenterMutation = useUpdateOperationCenterMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Centro de Operación actualizada`,
        closeOnClick: true,
        closeButton: true,
      });
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar bodega",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const {
    data: operationCenterIsRemovable,
    isLoading: isRemovableLoading,
    isError: isRemovableError,
    isFetching: operationCenterIsRemovableFetching,
    refetch: operationCenterIsRemovableRefetch,
  } = useOperationCenterIsRemovable(operationCenter?._id);

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Actualizando centros de operación...");

    updateOperationCenterMutation.mutate({
      data: data,
      notificationID,
    });
  });

  return (
    <div
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        visible ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[420px] h-full sm:rounded-bl-xl">
        {isRemovableLoading ? (
          <Loading text="Cargando..." />
        ) : isRemovableError ? (
          <div className="w-full border-t">
            <div className="w-full">
              <ErrorLoading text="Hubo un error" />
            </div>
          </div>
        ) : (
          <>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
                  <button type="button" onClick={() => closeDrawer()}>
                    <i className="mr-4 text-xl bi bi-arrow-left"></i>
                  </button>{" "}
                  <b className="font-medium text-base">{operationCenter.name}</b>
                  {operationCenterIsRemovable.isRemovable && (
                    <button
                      className={`${
                        methods.formState.isDirty
                          ? ""
                          : "opacity-50 pointer-events-none"
                      } ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm`}
                      type="submit"
                    >
                      Actualizar
                    </button>
                  )}
                </div>
                <EditOperationCenterForm
                  isRemovable={operationCenterIsRemovable.isRemovable}
                />
              </form>
            </FormProvider>
            <DeleteOperationCenter
              operationCenterIsRemovable={operationCenterIsRemovable}
              isFetching={operationCenterIsRemovableFetching}
              refetch={() => operationCenterIsRemovableRefetch()}
              onDelete={() => {
                confirmChange(
                  "¿Estas seguro de eliminar este centro de operación?",
                  "Al eliminar este centro de operación, el cambio sera permanente",
                  () => {
                    let notificationID = toast.loading(
                      "Eliminando centro de operación..."
                    );
                    removeOperationCenterMutation.mutate({
                      _id: operationCenter._id,
                      notificationID,
                    });
                  }
                );
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default DrawerEditOperationCenter;
