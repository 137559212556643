// Types imports
import { RAABType } from "../../../features/rraab/types";

interface Props {
  raab: RAABType;
  onSelect: (raab: RAABType) => void;
}

function RowRAAB({ raab, onSelect }: Props) {
  return (
    <div className="divide-x border-t w-full h-[50px] grid grid-cols-[2fr_1fr_2fr_2fr_1fr]">
      <div className="px-2 flex items-center overflow-hidden">
        <p className="w-full text-sm truncate">{raab.codProduct}</p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <p className="w-full text-sm truncate">{raab.idOperationCenter}</p>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="w-full text-sm truncate">{raab.unitLoadWarehouse}</span>
      </div>
      <div className="px-2 flex items-center overflow-hidden">
        <span className="w-full text-sm truncate">{raab.inBatchLoadWarehouse}</span>
      </div>
      <div
        className="px-2 flex items-center justify-center cursor-pointer"
        onClick={() => onSelect(raab)}
      >
        <i className="text-red-primary text-lg bi bi-pencil-square"></i>
      </div>
    </div>
  );
}
export default RowRAAB;
