// React imports
import React from "react";

// Custom components imports
import Loading from "../../shared/Loading";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import DataSellerForm from "./DataSellerForm";
import DropdownActions from "../../DropdownActions";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Hooks (@tanstack/react-query) imports
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom queries imports
import { useUpdateSellerMutation } from "../../../features/sellers/useUpdateSellerMutation";
import { useRemoveSellerMutation } from "../../../features/sellers/useRemoveSellerMutation";

// Custom types imports
import {
  SellerType,
  SellerIsRemovableType,
} from "../../../features/sellers/types";
import { OperationCenterType } from "../../../features/operationCenters/types";
import { Action } from "../../DropdownActions/types";

interface Props {
  seller: SellerType;
  sellerIsRemovable: SellerIsRemovableType;
  refetch: () => void;
  isFetching: boolean;
  operationCenters: UseQueryResult<OperationCenterType[], unknown>;
}

function EditSellerForm({
  seller,
  sellerIsRemovable,
  refetch,
  isFetching,
  operationCenters,
}: Props) {
  const submitButtonRef = React.useRef<any>(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<SellerType>({ defaultValues: seller });

  const removeSellerMutation = useRemoveSellerMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Vendedor eliminado`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Sellers"]);
      navigate(`/${currentUser?.user?._id}/vendedores`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar eliminar el vendedor",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const updateSellerMutation = useUpdateSellerMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Vendedor actualizado`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar el vendedor",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Actualizando vendedor...");

    updateSellerMutation.mutate({
      data: data,
      notificationID,
    });
  });

  const ACTIONS: Action[] = [
    {
      label: "Actualizar",
      icon: <i className="bi bi-arrow-repeat text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Eliminar",
      hidden: !sellerIsRemovable.isRemovable,
      icon: <i className="bi bi-trash3 text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estas seguro de eliminar este vendedor?",
          "Al eliminar este vendedor, el cambio sera permanente",
          () => {
            let notificationID = toast.loading("Eliminando vendedor...");
            removeSellerMutation.mutate({
              _id: seller._id,
              notificationID,
            });
          }
        );
      },
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser.user?._id}/vendedores`);
          }
        );
      },
    },
  ];

  return (
    <ContentWithToggle
      title={seller.name}
      onBackButton={() => navigate(`/${currentUser?.user?._id}/vendedores`)}
      isToogle={false}
      actionButton={<DropdownActions actions={ACTIONS} />}
    >
      {!sellerIsRemovable.isRemovable && (
        <div className="w-full flex flex-wrap sm:flex-nowrap sm:justify-center bg-yellow-500  py-4 px-4">
          {isFetching ? (
            <Loading text="Revalidando..." />
          ) : (
            <>
              <i className="text-xl bi bi-exclamation-circle-fill mr-4"></i>
              <div className="flex-1">
                <h3 className="font-bold">Este cliente no se puede eliminar</h3>

                {sellerIsRemovable?.user && (
                  <div className="mt-2">
                    <p>
                      Vendedor asignado al usuario:{" "}
                      <a
                        className="text-blue-800 font-normal underline hover:font-semibold"
                        href={`/${currentUser.user?._id}/usuarios/usuario/${sellerIsRemovable?.user?._id}/general`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {sellerIsRemovable?.user?.name}
                      </a>
                    </p>
                  </div>
                )}
                {sellerIsRemovable?.clients &&
                  sellerIsRemovable?.clients.length > 0 && (
                    <div className="mt-2">
                      <p>
                        Vendedor asignado a sucursales de los siguientes
                        clientes:{" "}
                      </p>
                      <ul className="pl-8 list-decimal">
                        {sellerIsRemovable.clients.map((client, index) => (
                          <li key={index}>
                            <a
                              className="text-blue-800 underline hover:font-semibold"
                              href={`/${currentUser.user?._id}/clientes/cliente/${client._id}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Cliente #{client.idClient} ({client.name})
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                {sellerIsRemovable?.orders &&
                  sellerIsRemovable?.orders.length > 0 && (
                    <div className="mt-2">
                      <p>Vendedor asignado a pedidos en revisión: </p>
                      <ul className="pl-8 list-decimal">
                        {sellerIsRemovable?.orders.map((order, index) => (
                          <li key={index}>
                            <a
                              className="text-blue-800 underline hover:font-semibold"
                              href={`/${currentUser.user?._id}/pedidos/pedido/${order._id}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Pedido #{order.idOrder}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                <button
                  type="button"
                  onClick={refetch}
                  tabIndex={-1}
                  className="mt-2 bg-white text-black rounded-md px-5 py-1 font-bold text-sm"
                >
                  Reintentar validación
                </button>
              </div>
            </>
          )}
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <DataSellerForm operationCenters={operationCenters} />
          <input type="submit" className="hidden" ref={submitButtonRef} />
        </form>
      </FormProvider>
    </ContentWithToggle>
  );
}

export default EditSellerForm;
