function HeaderGridProducts() {
  return (
    <div className="hidden md:grid bg-gray-100 text-gray-700 divide-x border-t w-full h-[40px] grid-cols-[1fr_5fr_2fr_60px_100px_2fr_2fr_40px]">
    <div className="px-2 flex items-center">
      <b>Codigo</b>
    </div>
    <div className="px-2 flex items-center">
      <b className="text-sm">Descripción</b>
    </div>
    <div className="px-2 flex items-center">
      <b className="text-sm">Precio</b>
    </div>
    <div className="px-2 flex items-center">
      <b className="text-sm">Tasa</b>
    </div>
    <div className="px-2 flex items-center">
      <b className="text-sm">Extensiones</b>
    </div>
    <div className="px-2 flex items-center">
      <b className="text-sm">Und Empaque</b>
    </div>
    <div className="px-2 flex items-center">
      <b className="text-sm">Und Inventario</b>
    </div>
    <div className="px-2 flex items-center"></div>
  </div>
  );
}
export default HeaderGridProducts;
