// React imports
import { useState } from "react";

// Hooks (react-router-dom") imports
import { useNavigate } from "react-router-dom";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import SearchInput from "../../components/shared/fields/SearchInput";
import InfiniteGridProducts from "../../components/infinite_grids/InfiniteGridProducts";
import DrawerImporter from "../../components/forms/importer/DrawerImporter";
import DropdownActions from "../../components/DropdownActions";

// Custom queries imports;
import { useProductsInfinite } from "../../features/products/useProductsInfinite";
import { useImportsMutation } from "../../features/common/useImportsMutation";
import { useExportProductsMutation } from "../../features/products/useExportProductsMutation";
import { useExportRRAABMutation } from "../../features/rraab/useExportRRAABMutation";

// Custom helpers imports
import { downloadReport } from "../../helpers";

// Custom types import
import { Action } from "../../components/DropdownActions/types";

function Products() {
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const [isDone, setIsDone] = useState<boolean>(false);

  const [visibleDrawer, setVisibleDrawer] = useState<
    "create" | "edit" | "import_products" | "import_rraab" | undefined
  >(undefined);

  const [searchFilter, setSearchFilter] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const products = useProductsInfinite({ search: searchFilter });
  const navigate = useNavigate();

  const importExtensionsMutation = useImportsMutation(
    (_data) => {
      setErrors(undefined);
      setIsDone(true);
      products.refetch();
    },
    (error) => {
      setErrors(error.data);
      setIsDone(false);
    }
  );

  const exportProductsMutation = useExportProductsMutation(
    (data, variables) => {
      downloadReport(data, "Lista de Productos ");
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Exportación de productos exitosa`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Algo salio mal al intentar exportar productos`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const exportRRAABMutation = useExportRRAABMutation(
    (data, variables) => {
      downloadReport(data, "Lista de RRAAB ");
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Exportación de RRAAB exitosa`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Algo salio mal al intentar exportar RRAAB`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => navigate("agregar"),
    },
    {
      label: "Importar productos",
      icon: <i className="bi bi-upload text-lg mr-2"></i>,
      onAction: () => setVisibleDrawer("import_products"),
    },
    {
      label: "Importar RAAB",
      icon: <i className="bi bi-upload text-lg mr-2"></i>,
      onAction: () => setVisibleDrawer("import_rraab"),
    },
    {
      label: "Exportar productos",
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () => exportProductsMutation.mutate({ notificationID: "" }),
    },
    {
      label: "Exportar RAAB",
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () => exportRRAABMutation.mutate({ notificationID: "" }),
    },
  ];

  return (
    <div className="w-[95%] sm:max-w-[1800px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden divide-y">
      <ContentWithToggle title="Filtros" isToogle={false}>
        <div className="w-full px-4 py-3 flex justify-between items-center">
          <SearchInput
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            onStopTyping={(value) => setSearchFilter(value)}
          />
        </div>
      </ContentWithToggle>
      <ContentWithToggle
        title="Productos"
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <InfiniteGridProducts products={products} />

        <DrawerImporter
          isLoading={importExtensionsMutation.isLoading}
          isDone={isDone}
          title={
            visibleDrawer === "import_products"
              ? "Importar Productos"
              : "Importar RRAAB"
          }
          visible={
            visibleDrawer === "import_products" ||
            visibleDrawer === "import_rraab"
          }
          errors={errors}
          onHideError={(index) =>
            setErrors((state) => state?.filter((_error, i) => i !== index))
          }
          onHideAllErrors={() => setErrors([])}
          onClose={() => {
            setVisibleDrawer(undefined);
            setErrors(undefined);
            setIsDone(false);
            setErrors([]);
          }}
          onImport={(data) => {
            setErrors(undefined);
            setIsDone(false);
            importExtensionsMutation.mutate({
              entity:
                visibleDrawer === "import_products" ? "products" : "rraab",
              file: data.file,
            });
          }}
        />
      </ContentWithToggle>
    </div>
  );
}

export default Products;
