// React imports
import { Dispatch, SetStateAction } from "react";

// Logos imports
import logotipo from "../assets/logotipo.png";

import { useAppSelector } from "../app/hook";

interface Prop {
  isOpenMenu: boolean;
  setIsOpenMenu: Dispatch<SetStateAction<boolean>>;
}

function Logo({ isOpenMenu, setIsOpenMenu }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);
  return (
    <div className="flex items-center w-full h-full">
      {!!currentUser.isAuthenticated && (
        <button
          type="button"
          className="xl:hidden mr-3"
          onClick={() => setIsOpenMenu((preventState) => !preventState)}
        >
          <i
            className={`text-black text-[25px] bi ${
              isOpenMenu ? "bi-x-lg" : "bi-list"
            }`}
          ></i>
        </button>
      )}
      <div className="mx-auto sm:mx-0">
        <img
          className="block w-auto h-[25px] sm:h-[30px] xl:h-[38px]"
          src={logotipo}
          alt="Imagotipo de Pagos Multired"
        />
      </div>
    </div>
  );
}
export default Logo;
