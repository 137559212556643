// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import SellerCard from "../cards/SellerCard";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";

// Custom types imports
import { SellerType } from "../../features/sellers/types";

// Custom helpers imports
import { formatNumber } from "../../helpers";

interface Props {
  sellers: UseQueryResult<SellerType[], unknown>;
  onSelect: (seller: SellerType) => void;
}

function SellersList({ sellers, onSelect }: Props) {
  if (sellers.isLoading) {
    return <Loading text="Cargando vendedores..." />;
  }

  if (sellers.isError || !sellers.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar vendedores" />
        </div>
      </div>
    );
  }

  if (sellers.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay vendedores" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de vendedores</span>
        <div className="w-[30%] text-end">
          <b>{formatNumber(sellers.data.length)}</b>
        </div>
      </div>
      <div className="divide-y">
        {sellers.data.map((seller) => (
          <SellerCard
            seller={seller}
            key={seller._id}
            onSelect={(seller) => onSelect(seller)}
          />
        ))}
      </div>
    </div>
  );
}

export default SellersList;
