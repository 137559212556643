// React imports
import { useState } from "react";

// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import SearchInput from "../../components/shared/fields/SearchInput";
import InfiniteGridOrders from "../../components/infinite_grids/InfiniteGridOrders";
import DropdownActions from "../../components/DropdownActions";
import CustomLabel from "../../components/shared/fields/CustomLabel";
import { useNavigate } from "react-router-dom";

// Custom helpers imports
import { downloadReport } from "../../helpers";

// (react-toastify) imports
import { toast } from "react-toastify";

// Custom queries imports;
import { useOrdersInfinite } from "../../features/orders/useOrdersInfinite";
import { useOperationCenters } from "../../features/operationCenters/useOperationCenters";
import { useExportOrdersMutation } from "../../features/orders/useExportOrdersMutation";

// Custom hooks imports
import { useAppSelector } from "../../app/hook";

// Custom types import
import { Action } from "../../components/DropdownActions/types";

function Orders() {
  const currentUser = useAppSelector((state) => state.auth.user);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [idOperationCenter, setIdOperationCenter] = useState<string>("");
  const operationCenters = useOperationCenters();
  const orders = useOrdersInfinite({
    search: searchFilter,
    status,
    idOperationCenter,
  });
  const data = orders.data?.pages?.flatMap((page) => page.items) || [];
  const navigate = useNavigate();

  const exportOrdersMutation = useExportOrdersMutation(
    (data, variables) => {
      downloadReport(data, "Lista de Pedidos ");
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Exportación de pedidos exitosa`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Algo salio mal al intentar exportar pedidos`,
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const ACTIONS: Action[] = [
    {
      label: "Crear",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => navigate(`/${currentUser?._id}/pedidos/agregar`),
    },
    {
      label: "Exportar",
      hidden: !data.length,
      icon: <i className="bi bi-download text-lg mr-2"></i>,
      onAction: () =>
        exportOrdersMutation.mutate({
          params: { search, status, idOperationCenter },
          notificationID: "",
        }),
    },
  ];

  return (
    <div className="w-[95%] sm:max-w-[1800px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Filtros"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="gap-4 w-full p-4 grid grid-cols-2 sm:grid-cols-4 justify-between items-end border-b">
          <SearchInput
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            className="col-span-2 sm:col-span-2"
            onStopTyping={(value) => setSearchFilter(value)}
          />
          <CustomLabel
            label="Estado"
            id="status"
            required={false}
            className="text-sm w-full col-span-2 sm:col-span-1 !mb-0"
          >
            <div className="w-full flex items-center relative">
              <select
                className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
                value={status}
                onChange={(event) => setStatus(event.target.value)}
              >
                {[
                  { label: "Todos", value: "" },
                  { label: "En Elaboración", value: "draft" },
                  { label: "En Revisión", value: "pending" },
                  { label: "Entregado", value: "inSIESA" },
                ].map((status, index) => (
                  <option value={status.value} key={index}>
                    {status.label}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>
          <CustomLabel
            label="Centro de Operación"
            id="status"
            required={false}
            className="text-sm w-full col-span-2 sm:col-span-1 !mb-0"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled={operationCenters.isLoading || !operationCenters.data}
                className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
                value={idOperationCenter}
                onChange={(event) => setIdOperationCenter(event.target.value)}
              >
                <option value="">Todos</option>
                {operationCenters.data?.map((co, index) => (
                  <option value={co.idOperationCenter} key={co._id}>
                    {co.idOperationCenter} {co.name}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary   absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>
        </div>
      </ContentWithToggle>
      <ContentWithToggle
        title="Pedidos"
        isToogle={false}
        actionButton={<DropdownActions actions={ACTIONS} />}
      >
        <InfiniteGridOrders orders={orders} />
      </ContentWithToggle>
    </div>
  );
}

export default Orders;
