// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { BranchType } from "./types";

export function useBranches(idClient?: string) {
  async function getBranches(): Promise<BranchType[]> {
    await timeout(500);
    const response = await api.get<BranchType[]>(
      `branches/all/${idClient}`
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Branches", idClient],
    queryFn: () => getBranches(),
    enabled: !!idClient,
  });
}
