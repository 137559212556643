import { SaleConditionIsRemovableType } from "../../../features/saleConditions/types";
import { useAppSelector } from "../../../app/hook";

import Loading from "../../shared/Loading";

interface Props {
  onDelete: () => void;
  disabled: boolean;
  saleConditionIsRemovable: SaleConditionIsRemovableType;
  refetch: () => void;
  isFetching: boolean;
}

function DeleteSaleCondition({
  onDelete,
  disabled,
  saleConditionIsRemovable,
  refetch,
  isFetching,
}: Props) {
  const currentUser = useAppSelector((state) => state.auth);

  if (saleConditionIsRemovable.isRemovable) {
    return (
      <div className="w-full flex flex-wrap sm:flex-nowrap sm:justify-center bg-red-primary text-white py-4 px-4">
        <i className="text-lg bi bi-exclamation-circle-fill mr-4"></i>
        <span className="w-[calc(100%-134px)]">
          Puedes eliminar esta condición de venta de forma permanente.
        </span>
        <button
          disabled={disabled}
          type="button"
          onClick={() => onDelete()}
          tabIndex={-1}
          className="disabled:opacity-70 disabled:pointer-events-none mt-2 bg-white text-black rounded-md px-5 py-1 sm:ml-4 sm:mt-0 font-bold"
        >
          Eliminar
        </button>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-wrap sm:flex-nowrap sm:justify-center bg-yellow-500  py-4 px-4">
      {isFetching ? (
        <Loading text="Revalidando..." />
      ) : (
        <>
          <i className="text-xl bi bi-exclamation-circle-fill mr-4"></i>
          <div className="flex-1">
            <h3 className="font-bold">
              Esta Condición de venta no se puede eliminar
            </h3>

            {saleConditionIsRemovable?.clients &&
              saleConditionIsRemovable?.clients.length > 0 && (
                <div className="mt-2">
                  <p>
                    Condición de venta asignado a sucursales de los siguientes
                    clientes:{" "}
                  </p>
                  <ul className="pl-8 list-decimal">
                    {saleConditionIsRemovable.clients.map((client, index) => (
                      <li key={index}>
                        <a
                          className="text-blue-800 underline hover:font-semibold"
                          href={`/${currentUser.user?._id}/clientes/cliente/${client._id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Cliente #{client.idClient} ({client.name})
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            {saleConditionIsRemovable?.orders &&
              saleConditionIsRemovable?.orders.length > 0 && (
                <div className="mt-2">
                  <p>Backorder asignado a pedidos en revisión: </p>
                  <ul className="pl-8 list-decimal">
                    {saleConditionIsRemovable?.orders.map((order, index) => (
                      <li key={index}>
                        <a
                          className="text-blue-800 underline hover:font-semibold"
                          href={`/${currentUser.user?._id}/pedidos/pedido/${order._id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Pedido #{order.idOrder}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            <button
              type="button"
              onClick={refetch}
              tabIndex={-1}
              className="mt-2 bg-white text-black rounded-md px-5 py-1 font-bold text-sm"
            >
              Reintentar validación
            </button>
          </div>
        </>
      )}
    </div>
  );
}
export default DeleteSaleCondition;
