// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { SellerType } from "./types";

export function useSeller(_id?: string) {
  async function getSeller(_id?: string): Promise<SellerType> {
    await timeout(500);
    const response = await api.get<SellerType>(`sellers/seller/${_id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Seller", _id],
    queryFn: () => getSeller(_id),
    enabled: !!_id,
  });
}
