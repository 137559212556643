// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { SellerType } from "./types";

interface ParamsType {
  search?: string;
}

export function useSellers(params?: ParamsType) {
  async function getSellers(): Promise<SellerType[]> {
    await timeout(500);
    const response = await api.get<SellerType[]>("sellers/all", { ...params });

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Sellers", params],
    queryFn: () => getSellers(),
  });
}
