// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { BackorderType } from "./types";

interface ParamsType{
  search?: string
}

export function useBackorders(params?: ParamsType) {
  async function getBackorders(): Promise<BackorderType[]> {
    await timeout(500);
    const response = await api.get<BackorderType[]>("backorders/all", {...params});

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Backorders", params],
    queryFn: () => getBackorders(),
  });
}
