// React imports
import { Dispatch, SetStateAction, MouseEvent } from "react";

// Custom componets imports
import LinksCorporateEmployee from "./shared/navigations/links/LinksCorporateEmployee";
import LinksCorporateAdmin from "./shared/navigations/links/LinksCorporateAdmin";
import CoverUsername from "./shared/navigations/links/CoverUsername";

// Hook & Components Redux imports
import { useAppSelector } from "../app/hook";

// Custom types imports
import { ROLES } from "../features/users/types";

interface Prop {
  isOpenMenu: boolean;
  setIsOpenMenu: Dispatch<SetStateAction<boolean>>;
}

function Menu({ isOpenMenu, setIsOpenMenu }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);

  const closeMenuClickOuside = (event: MouseEvent<HTMLElement>) => {
    let idElement = event.currentTarget.id;
    if (idElement === "containerMenu") setIsOpenMenu(false);
  };

  return (
    <div
      onClick={closeMenuClickOuside}
      id="containerMenu"
      className={`flex flex-col xl:hidden transition-all w-full h-screen bg-black/30 fixed z-30 top-0 ${
        isOpenMenu ? "left-0" : "-left-full"
      }`}
    >
      <CoverUsername setIsOpenMenu={setIsOpenMenu} />
      <div
        className={`overflow-y-scroll divide-y ${
          !currentUser.isAuthenticated ? "pt-[70px]" : "pt-0"
        } bg-white w-full max-w-[380px] h-full`}
      >

        {currentUser.user &&
          [ROLES.CorporateEmployee].includes(currentUser.user.role) && (
            <LinksCorporateEmployee
              setIsOpenMenu={setIsOpenMenu}
              inMenu={true}
            />
          )}

        {currentUser.user &&
          [ROLES.CorporateAdmin].includes(currentUser.user.role) && (
            <LinksCorporateAdmin setIsOpenMenu={setIsOpenMenu} inMenu={true} />
          )}
      </div>
    </div>
  );
}

export default Menu;
