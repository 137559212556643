// React imports
import { Fragment } from "react";

// Custom Components imports
import { Menu, Transition } from "@headlessui/react";

// Custom types import
import { Action } from "./types";

interface Props {
  label?: string;
  actions: Action[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function DropdownActions({
  label = "Opciones",
  actions,
}: Props) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-50">
          {label}
          <i className="bi bi-three-dots-vertical text-black"></i>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <>
            {actions.map(
              (action, index) =>
                !action.hidden && (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={action.onAction}
                        className={classNames(
                          active ? "bg-red-50 text-gray-900" : "text-gray-700",
                          "flex items-center w-full px-4 py-2 text-sm text-left"
                        )}
                      >
                        {action.icon}
                        {action.label}
                      </button>
                    )}
                  </Menu.Item>
                )
            )}
          </>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
