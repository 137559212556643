// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import UserCard from "../cards/UserCard";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

// Custom types imports
import { UserType } from "../../features/users/types";

interface Props {
  users: UseQueryResult<UserType[], unknown>;
}

function UsersList({ users }: Props) {
  const navigate = useNavigate();

  if (users.isLoading) {
    return <Loading text="Cargando usuarios..." />;
  }

  if (users.isError || !users.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar usuarios" />
        </div>
      </div>
    );
  }

  if (users.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay usuarios" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de usuarios</span>
        <div className="w-[30%] text-end">
          <b>{users.data.length}</b>
        </div>
      </div>
      <div className="divide-y">
        {users.data.map((user) => (
          <UserCard
            user={user}
            key={user._id}
            onSelect={() => navigate(`usuario/${user._id}/general`)}
          />
        ))}
      </div>
    </div>
  );
}

export default UsersList;
