// React imports
import React, { useState } from "react";

// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import SearchInput from "../../components/shared/fields/SearchInput";
import UsersList from "../../components/list/UsersList";

// Hooks & components (react-router-dom") imports
import { Link } from "react-router-dom";

// Custom queries imports
import { useUsers } from "../../features/users/useUsers";

function Users() {
  const searchRef = React.useRef<any>(null);
  const [search, setSearch] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<string>("");

  const users = useUsers({ search: searchFilter });
  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Usuarios"
        isToogle={false}
        actionButton={
          <div>
            <Link
              to="agregar"
              className="button-primary inline-block w-[90px] sm:w-[108px] text-sm"
            >
              Agregar
            </Link>
          </div>
        }
      >
        <div className="w-full px-4 py-3 flex justify-between items-center">
          <SearchInput
            searchRef={searchRef}
            value={search}
            setValue={setSearch}
            placeholder="Buscar"
            onStopTyping={(value) => setSearchFilter(value)}
          />
        </div>
        <UsersList users={users} />
      </ContentWithToggle>
    </div>
  );
}

export default Users;
