// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { OperationCenterRegistrationType, OperationCenterType } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: OperationCenterRegistrationType;
  notificationID: string | number;
}

export function useRegisterOperationCenterMutation(
  onSuccessMutation?: (
    data: OperationCenterType,
    variables: paramsType
  ) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function registerOperationCenter(
    params: paramsType
  ): Promise<OperationCenterType> {
    const response = await api.post<OperationCenterType>(
      "operationCenters/register",
      params.data
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: registerOperationCenter,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["OperationCenters"]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
