interface Props {
  className?: string;
  value: string;
  options: { id: string; value: string; label: string }[];
  onSelect: (value: string) => void;
}

export default function Toogle({ value, options, onSelect, className }: Props) {
  return (
    <div className={`bg-red-primary text-white rounded-md flex p-1 ${className}`}>
      {options.map((option) => (
        <button
          key={option.id}
          type="button"
          className={`${
            value === option.value && "text-black bg-white border-b-2 border-r-2 border-gray-300"
          } rounded-md flex-1 py-[2px] cursor-pointer`}
          onClick={() => onSelect(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
