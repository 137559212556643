function HeaderGridItem() {
    return (
      <div className="hidden md:grid bg-gray-100 text-gray-700 divide-x border-t w-full h-[40px] grid-cols-[3fr_1fr_100px_2fr_2fr_2fr_2fr_50px]">
      <div className="px-2 flex items-center">
        <b className="text-sm">Producto y extension</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">BD</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Cantidad</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Precio</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">IVA</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Subtotal</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Total</b>
      </div>
      <div className="px-2 flex items-center"></div>
    </div>
    );
  }
  export default HeaderGridItem;
  