// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom helpers imports
import { timeout } from "../../helpers";

// Custom types imports
import { ProductType } from "./types";

export function useProducts() {
  async function getProducts(): Promise<ProductType[]> {
    await timeout(500);
    const response = await api.get<ProductType[]>("products/all");

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Products"],
    queryFn: () => getProducts(),
  });
}
