interface Props {
  onDelete: () => void;
}

function DeleteItem({ onDelete }: Props) {
  return (
    <div className="w-full text-sm flex flex-wrap sm:flex-nowrap sm:justify-center items-center bg-red-primary text-white py-2 px-4">
      <i className="text-lg bi bi-exclamation-circle-fill mr-4"></i>
      <span className="w-[calc(100%-134px)]">
        Puedes eliminar este item de forma permanente
      </span>
      <button
        type="button"
        onClick={() => onDelete()}
        tabIndex={-1}
        className="mt-2 bg-white text-black rounded-md px-5 py-1 sm:ml-4 sm:mt-0 font-bold"
      >
        Eliminar
      </button>
    </div>
  );
}
export default DeleteItem;
