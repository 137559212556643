function HeaderGridExtensions() {
  return (
    <div className="hidden md:grid bg-gray-100 text-gray-700 divide-x border-t w-full h-[40px] grid-cols-[1fr_3fr_3fr_1fr]">
      <div className="px-2 flex items-center">#</div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Extension</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">Codigo de barra</b>
      </div>
      <div className="px-2 flex items-center"></div>
    </div>
  );
}
export default HeaderGridExtensions;
