// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import OperationCenterCard from "../cards/OperationCenterCard";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";

// Custom types imports
import { OperationCenterType } from "../../features/operationCenters/types";

interface Props {
  operationCenters: UseQueryResult<OperationCenterType[], unknown>;
  onSelect: (operationCenter: OperationCenterType) => void;
}

function OperationCentersList({ operationCenters, onSelect }: Props) {
  if (operationCenters.isLoading) {
    return <Loading text="Cargando centros de operación..." />;
  }

  if (operationCenters.isError || !operationCenters.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar centros de operación" />
        </div>
      </div>
    );
  }

  if (operationCenters.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay centros de operación" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de centros de operación</span>
        <div className="w-[30%] text-end">
          <b>{operationCenters.data.length}</b>
        </div>
      </div>
      <div className="divide-y">
        {operationCenters.data.map((operationCenter) => (
          <OperationCenterCard
            operationCenter={operationCenter}
            key={operationCenter._id}
            onSelect={(operationCenter) => onSelect(operationCenter)}
          />
        ))}
      </div>
    </div>
  );
}

export default OperationCentersList;
