// React imports
import React, { useState, useEffect } from "react";

// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Custom components imports
import Loading from "../../shared/Loading";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import DataCustomerForm from "./DataCustomerForm";
import ListBanches from "./ListBanches";
import DrawerEditBranch from "./DrawerEditBranch";
import DrawerCreateBranch from "./DrawerCreateBranch";
import DropdownActions from "../../DropdownActions";

// Custom queries imports
import { useUpdateCustomerMutation } from "../../../features/customers/useUpdateCustomerMutation";
import { useRemoveCustomerMutation } from "../../../features/customers/useRemoveCustomerMutation";

// Custom types imports
import { CustomerType } from "../../../features/customers/types";
import { Action } from "../../DropdownActions/types";
import { BranchType, BranchUpdateType } from "../../../features/branches/types";
import { CustomerIsRemovableType } from "../../../features/customers/types";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

interface Props {
  customer: CustomerType;
  customerIsRemovable: CustomerIsRemovableType;
  refetch: () => void;
  isFetching: boolean;
}

function EditCustomerForm({
  customer,
  customerIsRemovable,
  refetch,
  isFetching,
}: Props) {
  const submitButtonRef = React.useRef<any>(null);
  const [branches, setBranches] = useState<BranchType[]>(customer.branches);
  const [openDrawerCreateBranch, setOpenDrawerCreateBranch] =
    useState<boolean>(false);

  const [openDrawerEditBranch, setOpenDrawerEditBranch] =
    useState<boolean>(false);

  const [selectedBranch, setSelectedBranch] = useState<BranchType | undefined>(
    undefined
  );
  const [selectedBranchIndex, setSelectedBranchIndex] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (openDrawerEditBranch === false) {
      setSelectedBranch(undefined);
    }
  }, [openDrawerEditBranch, setSelectedBranch]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<CustomerType>({ defaultValues: customer });

  const updateCustomerMutation = useUpdateCustomerMutation(
    (_data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Cliente actualizado`,
        closeOnClick: true,
        closeButton: true,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar actualizar el cliente",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const removeCustomerMutation = useRemoveCustomerMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Cliente eliminado`,
        closeOnClick: true,
        closeButton: true,
      });
      queryClient.invalidateQueries(["Clients"]);
      navigate(`/${currentUser?.user?._id}/clientes`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: !!error.data
          ? error.data
          : "Algo salio mal al intentar eliminar el cliente",
        closeOnClick: true,
        closeButton: true,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    if (branches.length === 0) {
      return toast.error("La lista de sucursales no puede estar vacía.");
    }
    const notificationID = toast.loading("Actualizando cliente...");
    const branchesWithoutSubDocuments: BranchUpdateType[] = branches.map(
      (branch) => ({
        ...branch,
        backorder: branch.backorder._id,
        saleCondition: branch.saleCondition._id,
        seller: branch.seller._id,
      })
    );
    updateCustomerMutation.mutate({
      data: { ...data, branches: branchesWithoutSubDocuments },
      notificationID,
    });
  });

  const ACTIONS: Action[] = [
    {
      label: "Actualizar",
      icon: <i className="bi bi-arrow-repeat text-lg mr-2"></i>,
      onAction: () => submitButtonRef.current?.click(),
    },
    {
      label: "Agregar Sucursal",
      icon: <i className="bi bi-plus-circle text-lg mr-2"></i>,
      onAction: () => setOpenDrawerCreateBranch(true),
    },
    {
      label: "Eliminar",
      hidden: !customerIsRemovable.isRemovable,
      icon: <i className="bi bi-trash text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de eliminar este cliente?",
          "Al eliminar este cliente, el cambio será permanente.",
          () => {
            let notificationID = toast.loading("Eliminando cliente...");
            removeCustomerMutation.mutate({
              _id: customer._id,
              notificationID,
            });
          }
        );
      },
    },
    {
      label: "Cancelar",
      icon: <i className="bi bi-ban text-lg mr-2"></i>,
      onAction: () => {
        confirmChange(
          "¿Estás seguro de cancelar estos cambios?",
          "Al cancelar, todos los cambios serán ignorados.",
          () => {
            navigate(`/${currentUser.user?._id}/clientes`);
          }
        );
      },
    },
  ];

  return (
    <ContentWithToggle
      title={customer.name}
      onBackButton={() => navigate(`/${currentUser?.user?._id}/clientes`)}
      isToogle={false}
      actionButton={<DropdownActions actions={ACTIONS} />}
    >
      {!customerIsRemovable.isRemovable && (
        <div className="w-full flex flex-wrap sm:flex-nowrap sm:justify-center bg-yellow-500  py-4 px-4">
          {isFetching ? (
            <Loading text="Revalidando..." />
          ) : (
            <>
              <i className="text-xl bi bi-exclamation-circle-fill mr-4"></i>
              <div className="flex-1">
                <h3 className="font-bold">Este cliente no se puede eliminar</h3>

                {customerIsRemovable?.orders &&
                  customerIsRemovable?.orders.length > 0 && (
                    <div className="mt-2">
                      <p>Cliente asignado a pedidos en revisión: </p>
                      <ul className="pl-8 list-decimal">
                        {customerIsRemovable?.orders.map((order, index) => (
                          <li key={index}>
                            <a
                              className="text-blue-800 underline hover:font-semibold"
                              href={`/${currentUser.user?._id}/pedidos/pedido/${order._id}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Pedido #{order.idOrder}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                <button
                  type="button"
                  onClick={refetch}
                  tabIndex={-1}
                  className="mt-2 bg-white text-black rounded-md px-5 py-1 font-bold text-sm"
                >
                  Reintentar validación
                </button>
              </div>
            </>
          )}
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <DataCustomerForm />
          <ListBanches
            branches={branches}
            onSelect={(branch, index) => {
              setOpenDrawerEditBranch(true);
              setSelectedBranchIndex(index);
              setSelectedBranch(branch);
            }}
          />

          <input type="submit" className="hidden" ref={submitButtonRef} />
        </form>
      </FormProvider>
      {!!selectedBranch && (
        <DrawerEditBranch
          open={openDrawerEditBranch}
          setOpen={setOpenDrawerEditBranch}
          branch={selectedBranch}
          onDelete={() =>
            setBranches((currentBranches) =>
              currentBranches.filter(
                (_branch, index) => index !== selectedBranchIndex
              )
            )
          }
          onUpdate={(data) =>
            setBranches((currentBranches) =>
              currentBranches.map((branch, index) =>
                index === selectedBranchIndex ? data : branch
              )
            )
          }
        />
      )}
      <DrawerCreateBranch
        open={openDrawerCreateBranch}
        setOpen={setOpenDrawerCreateBranch}
        onAdd={(branch) =>
          setBranches((currentBranches) => [...currentBranches, branch])
        }
      />
    </ContentWithToggle>
  );
}
export default EditCustomerForm;
