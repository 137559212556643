import { CustomerType } from "../../features/customers/types";

interface Props {
  customer: CustomerType;
  isSelected: boolean;
  onSelect: () => void;
}

function AssignableCustomerCard({ customer, isSelected, onSelect }: Props) {
  return (
    <div
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-4"
      onClick={() => onSelect()}
    >
      <div className="col-span-3 flex flex-col items-start">
        <span className="text-xs text-white bg-blue-500 py-1 px-2 rounded truncate">
          {customer.branches.length} Sucursales
        </span>
        <p className="text-lg truncate w-full">{customer.name}</p>
        <p className="text-gray-500">
          NIT: {Intl.NumberFormat("es-CO").format(Number(customer.nit))}
        </p>
      </div>

      <div className="col-span-1 font-medium underline flex items-center justify-end">
        {isSelected ? (
          <i className="text-red-primary text-xl bi bi-check-circle-fill"></i>
        ) : (
          <i className="text-xl bi bi-circle text-gray-500"></i>
        )}
      </div>
    </div>
  );
}

export default AssignableCustomerCard;
