// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  notificationID: string | number;
}

export function useExportOperationsCentersMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  async function exportWarehouse(): Promise<any> {
    const response = await api.get<any>("operationCenters/export");

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: exportWarehouse,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
