function HeaderGridOrders() {
  return (
    <div className="hidden md:grid bg-gray-100 text-gray-700 divide-x border-t w-full h-[40px] grid-cols-[70px_70px_70px_3fr_100px_100px_2fr_2fr_2fr_40px]">
      <div className="px-2 flex items-center">
        <b>ID</b>
      </div>
      <div className="px-2 flex items-center">
        <b>ID ERP</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">CO</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">CLIENTE</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">ESTADO</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">EMISIÓN</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">IVA</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">SUBTOTAL</b>
      </div>
      <div className="px-2 flex items-center">
        <b className="text-sm">TOTAL</b>
      </div>
      <div className="px-2 flex items-center"></div>
    </div>
  );
}
export default HeaderGridOrders;
