import { useState, useEffect } from "react";

interface Props {
  placeholder: string;
  disable?: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  classNameInput?: string;
  className?: string;
  searchRef?: any;
  onStopTyping?: (value: string) => void;
}

function SearchInput({
  searchRef,
  className,
  placeholder,
  disable,
  value,
  setValue,
  onStopTyping,
}: Props) {
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onStopTyping?.(value);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [value, onStopTyping]);

  return (
    <div className={`relative w-full max-w-[600px] flex items-center ${className}`}>
      <div
        className={`${
          disable && "opacity-50"
        } flex items-center justify-center w-[30px] h-[30px] text-black bg-transparent absolute z-10 left-0`}
      >
        {isFocus ? (
          <button
            type="button"
            className="text-gray-500 hover:cursor-pointer hover:text-black"
          >
            <i className="bi-chevron-left text-lg"></i>
          </button>
        ) : (
          <button type="button" className="text-gray-500 hover:cursor-pointer">
            <i className="bi bi-search text-base"></i>
          </button>
        )}
      </div>
      <input
        ref={searchRef}
        disabled={disable}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        autoComplete="off"
        autoCorrect="off"
        type="text"
        className="input-base pl-[40px] font-regular"
        placeholder={placeholder}
      />
      {value && (
        <button
          type="button"
          onClick={() => setValue("")}
          className="ml-2 flex justify-center items-center w-[30px] h-[30px] bg-gray-100 rounded-full text-gray-500 hover:cursor-pointer hover:text-black hover:bg-red-50"
        >
          <i className="bi bi-x-lg text-base"></i>
        </button>
      )}
    </div>
  );
}

export default SearchInput;
