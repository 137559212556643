// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";
import { FiltersOrderType } from "./types";

interface paramsType {
  params: FiltersOrderType;
  notificationID: string | number;
}

export function useExportOrdersMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (
    err: ApiErrorResponse<string>,
    variables: paramsType
  ) => void
) {
  async function exportOrders(data: paramsType): Promise<any> {
    const response = await api.get<any>("orders/export", {
      ...(data.params.search && {
        search: data.params.search,
      }),
      ...(data.params.idOperationCenter && {
        idOperationCenter: data.params.idOperationCenter,
      }),
    });

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: exportOrders,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
