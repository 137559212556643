// React imports
import { useState } from "react";

// Custom types imports
import { ProductType } from "../../features/products/types";
import { SimplifiedItems } from "../../features/items/types";
import { WarehouseType } from "../../features/warehouses/types";
import { ItemRegistrationType } from "../../features/items/types";
import { ItemType } from "../../features/items/types";
import { RAABType } from "../../features/rraab/types";

// Custom helpers imports
import {
  getQuantities,
  getTotals,
  fromSimplifiedItemsToItems,
} from "../../helpers";

// Custom components imports
import EmptyCapture from "./EmptyCapture";
import { ManageProductQuantitiesWithExtensions } from "./ManageProductQuantitiesWithExtensions";
import ManageProductQuantitiesWithoutExtensions from "./ManageProductQuantitiesWithoutExtensions";
import AutomaticDistributionQuantitiesWithExtensions from "./AutomaticDistributionQuantitiesWithExtensions";
import AutomaticDistributionQuantitiesWithoutExtensions from "./AutomaticDistributionQuantitiesWithoutExtensions";
import CustomLabel from "../shared/fields/CustomLabel";
import DiscriminationOfTotals from "../shared/DiscriminationOfTotals";
import Toogle from "../Toogle";

interface Props {
  items: ItemType[];
  loadItems: (data: ItemRegistrationType[]) => void;
  rraab: RAABType[] | undefined;
  product?: ProductType;
  simplifiedItems: SimplifiedItems[];
  warehouses: WarehouseType[];
  onReset: () => void;
  setSimplifiedItems: React.Dispatch<React.SetStateAction<SimplifiedItems[]>>;
}

export default function RenderCapture({
  items,
  loadItems,
  rraab,
  product,
  simplifiedItems,
  warehouses,
  onReset,
  setSimplifiedItems,
}: Props) {
  const [distributionMode, setDistributionMode] = useState<
    "automatic" | "custom"
  >(rraab?.length ? "automatic" : "custom");

  const optionsToogle: { id: string; value: string; label: string }[] = [
    { label: "Automática", value: "automatic", id: "automatic" },
    { label: "Personalizado", value: "custom", id: "custom" },
  ];

  const quantities = getQuantities(simplifiedItems);
  const numberOfExtensions = product?.extensions?.length || 0;

  const { total, subtotal, iva } = getTotals(
    product?.price,
    quantities,
    product?.taxRate
  );

  const onAdd = () => {
    if (!product) return;

    const itemsNotMatchWithProduct = items.filter(
      (item) => item.extension.product._id !== product?._id
    );

    const newItems = fromSimplifiedItemsToItems(
      product,
      simplifiedItems,
      warehouses
    );

    loadItems([...itemsNotMatchWithProduct, ...newItems]);
    onReset?.();
  };

  if (!product) {
    return <EmptyCapture />;
  }

  return (
    <div className="w-full px-4 py-3 grid grid-cols-2 gap-x-4 gap-y-2">
      <CustomLabel
        label="Codigo"
        id="codProduct"
        required={false}
        className="text-sm"
      >
        <input
          disabled
          placeholder="Codigo"
          className="input-base"
          value={product.codProduct}
        />
      </CustomLabel>

      <CustomLabel
        label="Unidad de empaque"
        id="unitPackaging"
        required={false}
        className="text-sm"
      >
        <input
          disabled
          placeholder="Unidad de empaque"
          className="input-base"
          value={product.unitPackaging}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombre"
        id="name"
        required={false}
        className="text-sm col-span-2"
      >
        <input
          disabled
          placeholder="Nombre"
          className="input-base"
          value={product.description}
        />
      </CustomLabel>

      <CustomLabel
        label="Und de Empaque"
        id="unitPackaging"
        required={false}
        className="text-sm"
      >
        <input
          disabled
          placeholder="Unidad de Empaque"
          className="input-base"
          value={product.unitPackaging}
        />
      </CustomLabel>

      <CustomLabel
        label="Und de Inventario"
        id="unitInventory"
        required={false}
        className="text-sm"
      >
        <input
          disabled
          placeholder="Unidad de Venta"
          className="input-base"
          required={false}
          value={product.unitInventory}
        />
      </CustomLabel>

      <div className="col-span-2 w-full flex flex-wrap items-center my-2">
        <span className="text-base font-bold">Distribución</span>
        {!!rraab?.length && (
          <Toogle
            className="ml-auto w-[220px]"
            value={distributionMode}
            onSelect={(value) => setDistributionMode(value as any)}
            options={optionsToogle}
          />
        )}
      </div>

      {distributionMode === "custom" && numberOfExtensions === 1 ? (
        <ManageProductQuantitiesWithoutExtensions
          product={product}
          warehouses={warehouses}
          simplifiedItems={simplifiedItems}
          setSimplifiedItems={setSimplifiedItems}
        />
      ) : distributionMode === "custom" && numberOfExtensions > 1 ? (
        <ManageProductQuantitiesWithExtensions
          product={product}
          warehouses={warehouses}
          simplifiedItems={simplifiedItems}
          setSimplifiedItems={setSimplifiedItems}
        />
      ) : rraab?.length &&
        distributionMode === "automatic" &&
        numberOfExtensions === 1 ? (
        <AutomaticDistributionQuantitiesWithoutExtensions
          simplifiedItems={simplifiedItems}
          setSimplifiedItems={setSimplifiedItems}
          raab={rraab[0]}
          product={product}
        />
      ) : rraab?.length ? (
        <AutomaticDistributionQuantitiesWithExtensions
          simplifiedItems={simplifiedItems}
          setSimplifiedItems={setSimplifiedItems}
          raab={rraab[0]}
          product={product}
        />
      ) : null}

      <div className="col-span-2">
        <DiscriminationOfTotals
          taxRate={product.taxRate}
          price={product.price}
          iva={iva}
          subtotal={subtotal}
          total={total}
        />
      </div>
      <div className="col-span-2 mt-2">
        <button
          disabled={simplifiedItems.length === 0}
          className="ml-auto button-primary inline-block w-full text-sm h-[40px]"
          type="button"
          onClick={() => {
            onAdd();
          }}
        >
          Agregar al Detalle
        </button>
      </div>
    </div>
  );
}
