// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom types imports
import { BackorderType } from "../../../features/backorders/types";

// Hooks & Components (react-hook-form) imports
import { useFormContext } from "react-hook-form";

function EditWarehouseForm() {
  const methods = useFormContext<BackorderType>();

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 gap-x-4 gap-y-2">
      <CustomLabel
        label="ID de Backorder"
        id="idBackorder"
        required={true}
        className="text-sm"
        name="idBackorder"
      >
        <input
          type="number"
          placeholder="ID de Bodega"
          className={`input-base ${
            methods.formState.errors?.idBackorder ? "input-error" : ""
          } ${
            !methods.formState.errors?.idBackorder &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("idBackorder", { valueAsNumber: true })}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombre"
        id="name"
        required={true}
        className="text-sm"
        name="name"
      >
        <input
          placeholder="Nombre"
          className={`input-base ${
            methods.formState.errors?.name ? "input-error" : ""
          } ${
            !methods.formState.errors?.name && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("name")}
        />
      </CustomLabel>
    </div>
  );
}

export default EditWarehouseForm;
