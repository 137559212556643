// Custom components imports
import CustomLabel from "../shared/fields/CustomLabel";

// Custom types imports
import { ProductType } from "../../features/products/types";
import { SimplifiedItems } from "../../features/items/types";
import { RAABType } from "../../features/rraab/types";

// Custom helpers imports
import { getQuantities, setQuantityAutomaticDistribution } from "../../helpers";

interface Props {
  product: ProductType;
  simplifiedItems: SimplifiedItems[];
  setSimplifiedItems: React.Dispatch<React.SetStateAction<SimplifiedItems[]>>;
  raab: RAABType;
}

export default function AutomaticDistributionQuantitiesWithoutExtensions({
  simplifiedItems,
  product,
  setSimplifiedItems,
  raab,
}: Props) {
  const quantities = getQuantities(simplifiedItems);
  return (
    <div className="w-full col-span-2 grid gap-y-1">
      <CustomLabel
        label="Cantidades"
        id="quantity"
        required={false}
        className="text-sm"
      >
        <input
          type="number"
          placeholder="Ingresar cantidades"
          className="input-base text-black"
          onChange={(e) => {
            setQuantityAutomaticDistribution(
              setSimplifiedItems,
              Number(e.target.value),
              product,
              raab,
              product.extensions[0]?._id || ""
            );
          }}
          value={quantities}
        />
      </CustomLabel>
    </div>
  );
}
