// React import
import { useState } from "react";

// Custom components import
import { SummaryOfExtensionQuantitiesInAllWarehouses } from "../SummaryOfExtensionQuantitiesInAllWarehouses";
import WarehousePills from "../WarehousePills";
import QuantitiesOfExtensionsInSpecificWarehouse from "../QuantitiesOfExtensionsInSpecificWarehouse";

// Custom types imports
import { WarehouseType } from "../../features/warehouses/types";
import { SimplifiedItems } from "../../features/items/types";
import { ProductType } from "../../features/products/types";

interface Props {
  product: ProductType;
  warehouses: WarehouseType[];
  simplifiedItems: SimplifiedItems[];
  setSimplifiedItems: React.Dispatch<React.SetStateAction<SimplifiedItems[]>>;
}

export function ManageProductQuantitiesWithExtensions({
  product,
  warehouses,
  simplifiedItems,
  setSimplifiedItems,
}: Props) {
  const [idWarehouse, setIdWarehouse] = useState<string>("");

  return (
    <div className="w-full col-span-2 grid gap-y-1">
      <WarehousePills
        warehouses={warehouses}
        idWarehouse={idWarehouse}
        setIdWarehouse={setIdWarehouse}
      />
      <div className="w-full bg-white">
        {idWarehouse === "" ? (
          <SummaryOfExtensionQuantitiesInAllWarehouses
            product={product}
            simplifiedItems={simplifiedItems}
          />
        ) : (
          <QuantitiesOfExtensionsInSpecificWarehouse
            product={product}
            simplifiedItems={simplifiedItems}
            setSimplifiedItems={setSimplifiedItems}
            idWarehouse={idWarehouse}
          />
        )}
      </div>
    </div>
  );
}
