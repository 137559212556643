import { BackorderType } from "../../features/backorders/types";

interface Props {
  backorder: BackorderType;
  onSelect: (backorder: BackorderType) => void;
}

function BackorderCard({ backorder, onSelect }: Props) {
  return (
    <div
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-4"
      onClick={() => onSelect(backorder)}
    >
      <div className="col-span-3 flex flex-col">
        <p className="truncate">
          <b className="text-red-primary">{backorder.idBackorder}</b>{" "}
          {backorder.name}
        </p>
      </div>
      <div className="flex flex-1 items-end flex-col justify-center">
        <i className="bi bi-chevron-right text-[#EA002A] text-lg"></i>
      </div>
    </div>
  );
}

export default BackorderCard;
