// React imports
import { ReactElement } from "react";

// State Redux imports
import { useAppSelector } from "../../app/hook";

interface Prop {
  children: ReactElement;
}

function PublicRoute({ children }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);

  if (currentUser.isAuthenticated) {
    window.location.replace(`/${currentUser.user?._id}/pedidos`);
    return null;
  }
  return children;
}

export default PublicRoute;
