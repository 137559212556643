// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import CustomerCard from "../cards/CustomerCard";
import EndMessageInfiniteList from "../shared/EndMessageInfiniteList";
import LoaderInfiniteList from "../shared/LoaderInfiniteList";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Components (react-infinite-scroll-component) imports
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks (react-router-dom) imports
import { UseInfiniteQueryResult } from "@tanstack/react-query";

// Custom types imports
import { CustomerPagination } from "../../features/customers/types";

interface Props {
  customers: UseInfiniteQueryResult<CustomerPagination, unknown>;
}

function InfiniteListCustomers({ customers }: Props) {
  const navigate = useNavigate();
  const data = customers.data?.pages?.flatMap((page) => page.items) || [];
  if (customers.isLoading) {
    return <Loading text="Cargando clientes..." />;
  }

  if (customers.isError || !customers.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar clientes" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay clientes" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de clientes</span>
        <div className="w-[30%] text-end">
          <b>{customers.data.pages[0].totalItems}</b>
        </div>
      </div>
      <InfiniteScroll
        dataLength={data.length || 0}
        next={() => customers.fetchNextPage()}
        hasMore={!!customers.hasNextPage}
        loader={<LoaderInfiniteList />}
        endMessage={<EndMessageInfiniteList message="Carga completada" />}
      >
        <div className="divide-y">
          {data.map((customer, index) => (
            <CustomerCard
              customer={customer}
              key={customer._id}
              onSelect={() => navigate(`cliente/${customer._id}`)}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteListCustomers;
